import {
    Card,
    CardHeader,
    Switch,
    Body1,
    Link,
    Subtitle2,
} from '@fluentui/react-components';
import {
    ChevronUp20Regular,
    ChevronDown20Regular,
} from '@fluentui/react-icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSettingMenuStyles } from 'settings/hooks/settingsStylesHook';
import FeatureCardContextProvider from 'settings/contexts/settingsFeatureCardContext';
import BadgeMessageBar from 'global/components/BadgeMessageBar';

/*
The settings feature card is in charge of activating features in different parts of the APP

Different UI states or configurations

Text + toggle 

Text + toggle + children

Text + toggle + expand + children

So the card always has a toggle button that controls the activation of the feature, along with text explaining it
The card can optionally have child elements below it, while also optionally hiding those child elements with an expand able label

Question:  How should the toggle influence the children?
The state of the toggle should be communicated to the child components, so they can render activated or not. 
I can pass down the toggle state using context -> react.Children to be deprecated so cant use react.CloneElement to pass props to children

Questions: How should the children influence the toggle?
The toggle to activate the settings may depend on the success or configuration of the child items
This can also be achieved by using react context ->  will build out a provider for the states.

Question: How will the toggle know what state to be in?
The activation state of the settings will be in the redux store. Since this is a controlled component, the 
component needs to specify a selector function to extract the state from the store.

Question: What events need to fire on the toggle change event?
To update the setting in the store, we will have to dispatch an action. 
Since the setting is a boolean -> the dispatch can be a toggle action requiring no payload. 

If we are storing the state of the toggle in some context to pass down, we will also hae to update the context. 

The toggled state may be something that is instant published, and so the dev may want to run custom code when the toggle changes
to help with this, allow the user to supply a custom toggle function, which takes in the new value as its first argument. 


This leads to the list of props
- title -> string
- body  -> optional string
- learnMoreLink -> optional string(URL)
- expand -> optional boolean
- selector -> redux selector function
- action -> redux action (no payload)
- onChange -> optional custom change event handler, with structure (event,newValue) => {}
*/

const SettingsFeatureCard = ({
    title,
    body,
    learnMoreLink,
    expand,
    selector,
    action,
    onChange,
    children,
    msgSelector,
}) => {
    const [expanded, setExpanded] = useState(false);
    const { t } = useTranslation();
    const setting = useSelector(selector);
    const dispatch = useDispatch();
    const styles = useSettingMenuStyles();
    const [mouseDown, setMouseDown] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const onChangeHandler = (e, data) => {
        //Dispatch change to the slice
        dispatch(action());
        //Call custom onChange handler
        onChange && onChange(e, data.checked);
    };

    const onClickHandler = (e) => {
        expand
            ? setExpanded(!expanded)
            : !disabled && onChangeHandler(e, { checked: !setting });
    };

    const extraSwitchProps = expand
        ? stopClickPropagation
        : { onClick: stopClickPropagation.onClick };

    return (
        <Card
            size="large"
            onClick={onClickHandler}
            onMouseDown={(e) => {
                (expand || !disabled) && setMouseDown(true);
            }}
            onMouseUp={(e) => {
                (expand || !disabled) && setMouseDown(false);
            }}
            className={
                mouseDown
                    ? styles.itemCardActiveReplace
                    : styles.itemCardActiveDefault
            }
        >
            <CardHeader
                header={<Subtitle2>{title}</Subtitle2>}
                action={
                    <div className={styles.toggleExpandFlex}>
                        <Switch
                            {...extraSwitchProps}
                            checked={setting.value}
                            onChange={onChangeHandler}
                            disabled={disabled}
                            className={styles.toggleNoMargin}
                        />
                        {expand &&
                            (expanded ? (
                                <ChevronUp20Regular />
                            ) : (
                                <ChevronDown20Regular />
                            ))}
                    </div>
                }
            />
            {body && (
                <Body1>
                    {body}{' '}
                    {learnMoreLink && (
                        <Link {...stopClickPropagation} href={learnMoreLink}>
                            {t('common.learnMore')}
                        </Link>
                    )}
                </Body1>
            )}
            {(!expand || expanded) && children && (
                <div {...stopClickPropagation}>
                    <FeatureCardContextProvider
                        toggled={setting}
                        setDisabled={setDisabled}
                    >
                        {children}
                    </FeatureCardContextProvider>
                </div>
            )}
            {(!expand || (expand && !expanded)) && (
                <BadgeMessageBar cardFooter={true} selector={msgSelector} />
            )}
        </Card>
    );
};

const stopClickPropagation = {
    onClick: (e) => {
        e.stopPropagation();
    },
    onMouseDown: (e) => e.stopPropagation(),
    onMouseUp: (e) => e.stopPropagation(),
};

export default SettingsFeatureCard;
