// TaskPaneBody.js
import React from 'react';
import { Image } from '@fluentui/react-components';
import { useEmptyStateStyles } from 'home/hooks/emptyStateStylesHook';

/*
The EmptyStateImage component simply manages the content and styling of the Brando image in empty states.
________________________________________________________________________________________________________________

Props: 
None
________________________________________________________________________________________________________________
*/

const EmptyStateImage = () => {
    const classes = useEmptyStateStyles();
    return (
        <Image
            src="../../assets/Brand Light@2x.png"
            className={classes.tutorialImage}
        />
    );
};

export default EmptyStateImage;
