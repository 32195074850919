import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enUS from './Languages/en-US.json';
import ar from './Languages/ar.json';
import bg from './Languages/bg.json';
import de from './Languages/de.json';
import zh from './Languages/zh.json';

//This is a very basic set up of the i18Next library
// it is a little overkill for the MVP but needed for future versions
// The specific configuration will almost certainly change down the line
// but this should set the initial language to "en-US"
// and provide the copy from he JSON file to components
i18n.use(initReactI18next).init({
    //overrides language detection
    lng: 'en-US',

    //Language used when translation isn't found in the current language
    fallbackLng: 'en-US',

    //Out puts useful log statements
    debug: true,

    //links the language files to codes
    resources: {
        //American english
        'en-US': { translation: enUS },
        ar: { translation: ar },
        zh: { translation: zh },
        de: { translation: de },
        bg: { translation: bg },
    },
});

//Also to note, i18next supports multiple namespaces for the app,
// which allows dynamic loading of the text for certain sections of the app
// it also helps logically break down the app if we want to.
