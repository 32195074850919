import {
    Body1,
    Card,
    ToggleButton,
    Button,
    Menu,
    MenuPopover,
    MenuList,
    MenuItem,
    MenuTrigger,
    MenuDivider,
    mergeClasses,
} from '@fluentui/react-components';
import {
    MoreHorizontal20Regular,
    Pin20Filled,
    Pin20Regular,
} from '@fluentui/react-icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSettingMenuStyles } from 'settings/hooks/settingsStylesHook';
import { toggleWorkspacePinned } from 'reduxStore/slices/teamSettingsSlice';
import { useSavedNavigate } from 'global/hooks/historySaveStatesHook';
import WorkspaceIcon from './WorkspaceIcon';

/*
What does this card need to do?

It needs to display the name and Icon of the workspace. 
It should also hold a reference to the workspace id

When clicked on, it should navigate to the related page for the workspace:
    - Main Workspaces Page "/workspaces" -> workspace home page "/workspace/:workspaceId"
    - Admin WorkspacesPage "/settings/admin/workspaces" -> workspace settings "/settings/admin/workspaces/:workspaceId"
Both navigations are the SAME RELATIVE navigation "./:workspaceID"

When in the settings menu, the card takes on extra features
    -> hover state revealing the pin icon, which updates the setting for the workspace. 
    -> ... menu with options to duplicate, delete, open in SharePoint etc. 
The card must also have a pinned state.
*/

/*
This card is used to display a choice of workspaces
It can be found on the landing page as well as in the admin settings menu. 

It acts as a navigation aid, going to the workspace ID location, but also has a more menu and a pinned state(admin only)
________________________________________________________________________________________________________________

Props:

workspaceId -> the friendly id of the workspace, used to access data in redux
admin ->  bool used to indicate how the component should render
preview -> object {name:string, iconURL: string, pinned:bool }
    controlled props to override the name and icon url, used in the workspace preview when editing/creating
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const WorkspaceCard = ({ workspaceId, admin = false, preview }) => {
    const { t } = useTranslation();
    const navigate = useSavedNavigate();
    const workspace = useSelector(
        (state) => state.teamSettings.entities.workspaces.byId[workspaceId]
    );
    //Control hover state for the pin icon
    const [mouseOver, setMouseOver] = useState(false);
    //Control the flow of the click event handler
    const [mouseDown, setMouseDown] = useState(false);
    const styles = useSettingMenuStyles();

    // get the Workspace properties from either redux or controlled input
    const workspaceName = preview?.name || workspace?.name?.value || '';
    const workspaceUrl = preview?.iconURL || workspace?.iconURL?.value || '';
    const workspacePinned =
        preview?.pinned || workspace?.pinned?.value || false;

    // Only add props when not in preview -> props trigger the hover/click states of the card
    const cardProps = preview
        ? {}
        : {
              onMouseDown: () => setMouseDown(true),
              onMouseUp: () => setMouseDown(false),
              onMouseEnter: () => setMouseOver(true),
              onMouseLeave: () => setMouseOver(false),
              onClick: () =>
                  navigate(`./${workspace.id}`, { relative: 'path' }),
              className: mouseDown
                  ? styles.itemCardActiveReplace
                  : styles.itemCardActiveDefault,
          };

    return (
        <Card {...cardProps} size="large">
            <div
                className={mergeClasses(
                    styles.rowFlexCenter,
                    styles.justifyItems
                )}
            >
                <div
                    className={mergeClasses(
                        styles.rowFlexCenter,
                        styles.workspaceIconName
                    )}
                >
                    <WorkspaceIcon
                        iconURL={workspaceUrl}
                        name={workspaceName}
                    />
                    <Body1>{workspaceName}</Body1>
                </div>
                <div
                    {...stopClickPropagation}
                    className={mergeClasses(
                        styles.rowFlexCenter,
                        styles.workspacePinMenu
                    )}
                >
                    {(workspacePinned || (mouseOver && admin)) && (
                        <WorkspaceCardPin
                            workspaceId={workspace.id}
                            pinned={workspacePinned}
                            allowClick={admin}
                        />
                    )}
                    {admin && <WorkspaceCardMenu workspaceId={workspace.id} />}
                </div>
            </div>
        </Card>
    );
};

const WorkspaceCardMenu = ({ workspaceId }) => {
    const navigate = useSavedNavigate();
    const { t } = useTranslation();
    return (
        <Menu closeOnScroll>
            <MenuTrigger>
                <Button
                    size="small"
                    appearance="subtle"
                    icon={<MoreHorizontal20Regular />}
                />
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    <MenuItem
                        onClick={() =>
                            navigate(`./${workspaceId}`, { relative: 'path' })
                        }
                    >
                        {t('common.settings')}
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem>{t('buttons.duplicate')}</MenuItem>
                    <MenuItem>{t('buttons.delete')}</MenuItem>
                    <MenuDivider />
                    <MenuItem>{t('buttons.copyLink')}</MenuItem>
                    <MenuItem>{t('buttons.openSP')}</MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};

const WorkspaceCardPin = ({ workspaceId, pinned, allowClick }) => {
    const dispatch = useDispatch();
    return (
        <ToggleButton
            size="small"
            appearance="transparent"
            checked={pinned}
            onClick={() => {
                if (allowClick)
                    dispatch(toggleWorkspacePinned({ id: workspaceId }));
            }}
            icon={pinned ? <Pin20Filled /> : <Pin20Regular />}
        />
    );
};

const stopClickPropagation = {
    onClick: (e) => {
        e.stopPropagation();
    },
    onMouseDown: (e) => e.stopPropagation(),
    onMouseUp: (e) => e.stopPropagation(),
};

export default WorkspaceCard;
