import React from 'react';
import {
    settingCacheConfig,
    useSettingsCache,
} from 'settings/hooks/reduxSettingsHooks';
import ItemList, {
    horizontalSpacingSizes,
    verticalSpacingSizes,
} from 'global/components/ItemList';
import HomeListItem from './HomeListItem';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLibrariesByWorkspaceId } from 'reduxStore/slices/teamSettingsSlice';
import { useNavigateBreadcrumbs } from 'assetNav/hooks/breadcrumbNavigateHook';

/*
Description 
renders a list of libraries for the workspace home page
________________________________________________________________________________________________________________

Props:

workspaceId -> id used to fetch the associated libraries
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const HomeLibraryList = ({ workspaceId }) => {
    const libraries = useSelector(selectLibrariesByWorkspaceId(workspaceId));
    return (
        <ItemList
            rowGapSize={verticalSpacingSizes.xSmall}
            paddingSize={horizontalSpacingSizes.none}
        >
            {libraries &&
                libraries.map((library) => {
                    return (
                        <LibraryItem key={library.id} library={library}/>
                    );
                })}
        </ItemList>
    );
};

const LibraryItem = ({library}) => {
    const navigate = useNavigateBreadcrumbs(library.name.value, library.id);
    return (
        <HomeListItem
            key={library.id}
            iconType={library.type.value}
            text={library.name.value}
            onClick={() => navigate(`./${library.id}`)}
        />
    );
};

export default HomeLibraryList;
