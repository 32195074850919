import { makeStyles, tokens, shorthands } from '@fluentui/react-components';

/*
Description 
________________________________________________________________________________________________________________

Components Styled Here

Component Name -> Style Name -> Description
________________________________________________________________________________________________________________
*/

const useTutorialStyles = makeStyles({
    tutorialContentDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '85vh',
        rowGap: '20px',
    },
    tutorialText: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '12px',
        textAlign: 'center',
        width: '240px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tutorialImage: {
        '@media screen and (min-height:593px)': {
            height: '200px',
            width: '200px',
        },
        '@media screen and (max-height:592px)': {
            height: '100px',
            width: '100px',
        },
        transitionProperty: 'width height',
        transitionDuration: '300ms',
    },
    progressBarContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    progressBarDiv: {
        width: '90%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    progressLabel: {
        textAlign: 'center',
    },
    progressEaseAnimation: {
        '& > div': {
            transitionDuration: '1s',
            transitionTimingFunction: 'ease-in-out',
        },
    },
});

export { useTutorialStyles };
