/*
Parses the body returned from a fetch request based on either a configured type
or based on the mime type returned from the request
________________________________________________________________________________________________________________
*/

import { newErrMsg } from 'utils/messages/messages';

export const parseFetchBody = async (res) => {
    //Parse response returned from fetch api based on content type
    let resData = res.data;
    let body;
    const contentType = resData.headers.get('Content-Type');
    const forcedType = res.payload?.contentType;
    //Check configured parse type
    switch (forcedType) {
        case 'json':
            try {
                body = await resData.json();
            } catch (e) {
                //failed to parse to JSON throw error
                throw {
                    ...res,
                    error: newErrMsg(
                        'Parse Error',
                        'Failed to parse response body into json'
                    ),
                };
            }
            break;
        case 'text':
            try {
                body = await resData.text();
            } catch (e) {
                //failed to parse to text
                throw {
                    ...res,
                    error: newErrMsg(
                        'Parse Error',
                        'Failed to parse response body into text'
                    ),
                };
            }
            break;
        case 'blob':
            try {
                body = await resData.blob();
            } catch (e) {
                //failed to parse to blob
                throw {
                    ...res,
                    error: newErrMsg(
                        'Parse Error',
                        'Failed to parse response body into blob'
                    ),
                };
            }
            break;
    }

    if (body) {
        return { ...res, data: body, status: resData.status };
    }

    //guess parse type based on content type
    if (contentType && contentType.includes('application/json')) {
        try {
            body = await resData.json();
        } catch (e) {
            //failed to parse to JSON throw error
            throw {
                ...res,
                error: newErrMsg(
                    'Parse Error',
                    'Failed to parse response body into json'
                ),
            };
        }
    }

    //Convert to text as default
    if (!body) {
        try {
            body = await resData.text();
        } catch (e) {
            //failed to parse to text
            throw {
                ...res,
                error: newErrMsg(
                    'Parse Error',
                    'Failed to parse response body into text'
                ),
            };
        }
    }

    return { ...res, data: body, status: resData.status };
};
