import {
    createSlice,
    original,
    current,
    createSelector,
    createAsyncThunk,
} from '@reduxjs/toolkit';
import { getNewSettingState } from '../settingTrackingMiddleware';
import bcServerRequests from 'requests/endpoints/bc-server-endpoints';
import cacheValidation from 'utils/validation/cacheValidation';
import graphRequests from 'requests/endpoints/graph-endpoints';

/* 
Team settings are specific to an Team, apply for all relevant users in the team,
and are editable by BrandAdmins.
The structure is mostly flat, with some normalised structure for deeper settings.


There are many parts to the team settings
Because of how redux is set up, there is no direct access between slices.
If I were to split the sections of the team settings into different slices,
then linking library to workspaces, or adding templates etc would become difficult.

Below solves this by having them all in one slice, opting for more complicated reducer logic.

*/

/////  Default Slice Structure  //////

//general object to store settings. Can be useful for validation and error communication.
export const newSetting = (type, defaultValue) => {
    return {
        type: type,
        value: defaultValue,
        msgs: [],
    };
};

/*
Default Team Structure
*/

const defaultSettings = () => {
    return {
        //Global settings - productivity tools, it admins etc.
        //DB like objects to store relational settings
        entities: {
            //Collections of objects
            teams: {
                lastRequested: null,
                currTeamId: null,
                byId: {},
                allIds: [],
            },
            workspaces: {
                lastRequested: null,
                byId: {},
                allIds: [],
            },
            libraries: {
                lastRequested: {},
                byId: {},
                allIds: [],
            },
            templates: {
                byId: {},
                allIds: [],
            },

            //Many to Many junction 'tables' (needed?)
            workLib: {
                byId: {},
                allIds: [],
            },
        },
    };
};

/*
List of all Team settings
*/
const defaultTeam = () => {
    return {
        id: 0,
        name: newSetting('string', ''),
        iconURL: newSetting('iconURL', ''),
    };
};

/*list of all workspace settings
-> Name
-> Icon Url
-> Profile
-> Pinned
*/
const defaultWorkspace = () => {
    return {
        //Generic settings
        id: 0,
        name: newSetting('string', ''),
        iconURL: newSetting('iconURL', ''),
        pinned: newSetting('bool', false),
        location: {
            origin: newSetting('damType', ''),
            siteId: 0,
        },
        msgs: [],
    };
};

/*
list of all library details for v0.1
-> Name
-> Location
-> Type
*/
const defaultLibrary = () => {
    return {
        //All Library settings
        id: 0,
        type: newSetting('assetType', ''),
        name: newSetting('string', ''),
        location: {
            origin: newSetting('damType', ''),
            webUrl: newSetting('string', ''),
            siteId: 0,
            driveId: 0,
        },

        //Hard coded for v0.1 for now
        visibleFiles: {
            ppt: newSetting('bool', true),
            img: newSetting('bool', true),
            svg: newSetting('bool', true),
            vid: newSetting('bool', false),
            aud: newSetting('bool', false),
            txt: newSetting('bool', false),
        },

        //Image library settings - hard coded for v0.1 for now
        imageOpt: {
            dimension: newSetting('dimension', '1920x1920'),
        },
    };
};

/*
List of all template settings
-> Name
-> Share Point location
-> Agenda config
-> Confidentiality feature config
-> Migrate content area config
*/
const defaultTemplate = () => {
    return {
        id: 0,
        workspaceId: 0,
        name: newSetting('string', ''),
        location: {
            origin: newSetting('damType', ''),
            siteId: 0,
            driveId: 0,
            itemId: 0,
        },
    };
};

// default structure for the many to many links?
const defaultWorkLib = () => {
    return {
        id: 0,
        workspaceId: 0,
        libraryId: 0,
    };
};

////////////////////////////////////
///////   Request Thunks     ///////
////////////////////////////////////

export const teamsThunk = createAsyncThunk(
    'teams/getAllMinimal',
    async (arg, thunkAPI) => {
        try {
            //Check if the cache is valid
            if (
                cacheValidation.allTeams.validator(
                    thunkAPI.getState().teamSettings.entities.teams
                )
            ) {
                return 'cacheValid';
            }
            const res = await bcServerRequests.endpoints.getUserTeams();

            return res.data;
        } catch (e) {
            //Reject request errors
            console.log(e);
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const workspacesThunk = createAsyncThunk(
    'workspaces/getAllMinimal',
    async (arg, thunkAPI) => {
        try {
            //reset cache if team id is new
            const currTeamId =
                thunkAPI.getState().teamSettings.entities.teams.currTeamId;
            if (arg.teamId !== currTeamId) {
                thunkAPI.dispatch(resetTeamSettings(arg.teamId));
            }

            //Check if the cache is valid
            if (
                cacheValidation.allWorkspaces.validator(
                    thunkAPI.getState().teamSettings.entities.workspaces
                )
            ) {
                return 'cacheValid';
            }
            const res = await bcServerRequests.endpoints.getWorkspaces({
                teamId: arg.teamId,
            });

            return res.data;
        } catch (e) {
            //Reject request errors
            console.log(e);
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const librariesThunk = createAsyncThunk(
    'libraries/getAllMinimal',
    async (arg, thunkAPI) => {
        let res;
        try {
            //arg should be the workspaceId - convert to site ID
            const workspace = selectWorkspaceById(arg.workspaceId)(
                thunkAPI.getState()
            );

            if (
                cacheValidation
                    .allLibraries(arg.workspaceId)
                    .validator(
                        thunkAPI.getState().teamSettings.entities.libraries
                    )
            ) {
                return 'cacheValid';
            }

            res = await graphRequests.endpoints.getLibrariesBySiteId({
                siteId: workspace.location.siteId,
            });

            //Request has succeeded
            return { workspaceId: arg.workspaceId, libraries: res.data };
        } catch (e) {
            //Reject request errors
            return thunkAPI.rejectWithValue(e);
        }
    }
);

///// Slice Configuration //////
//Create a slice to store the team settings
export const teamSettings = createSlice({
    name: 'teamSettings',
    initialState: defaultSettings(),

    // Typically, reducers should return new states, not mutated ones. But in the create
    // slice function, redux uses immer which handles the mutations.
    reducers: {
        //Reset Team Settings
        resetTeamSettings: (state, action) => {
            const settings = defaultSettings();
            settings.entities.teams = { ...state.entities.teams };
            settings.entities.teams.currTeamId = action.payload;
            return settings;
        },
        //Change settings
        resetTrackedTeamSetting: (state, action) => {
            const { path, change } = action.payload;
            return getNewSettingState(state, path, change.init);
        },
        toggleWorkspacePinned: (state, action) => {
            const { id: workSpaceId } = action.payload;

            if (!state.entities.workspaces.byId[workSpaceId]) return state;

            state.entities.workspaces.byId[workSpaceId].pinned.value =
                !state.entities.workspaces.byId[workSpaceId].pinned.value;
        },
        clearTeamSettingsCache: () => {
            return defaultSettings();
        },
    },
    //Extra reducers used to hook into redux thunk success/failure
    extraReducers: (builder) => {
        builder
            /*
            Teams Request
            */
            .addCase(teamsThunk.fulfilled, (state, action) => {
                if (action.payload === 'cacheValid') return;
                const teams = action.payload;

                teams.map((team) => {
                    const newTeam = defaultTeam();
                    newTeam.id = team.fid;
                    newTeam.name = team.display_name;
                    newTeam.iconURL = team.iconURL || '';
                    state.entities.teams.byId[team.fid] = newTeam;
                    if (state.entities.teams.allIds.indexOf(team.fid) === -1) {
                        state.entities.teams.allIds.push(team.fid);
                    }
                });
                state.entities.teams.lastRequested = Date.now();
            })

            /*
            Workspaces Request
            */
            .addCase(workspacesThunk.fulfilled, (state, action) => {
                if (action.payload === 'cacheValid') return;
                //assume for now action.payload is in the correct form
                const workspaces = action.payload;

                for (let i = 0; i < workspaces.length; i++) {
                    const workspace = workspaces[i];

                    //If the workspace doesn't exist, add new workspace
                    if (!state.entities.workspaces.byId[workspace.fid]) {
                        const newWorkspace = defaultWorkspace();
                        newWorkspace.id = workspace.fid;
                        newWorkspace.name.value = workspace.name;
                        newWorkspace.iconURL.value = workspace.iconURL;
                        newWorkspace.location = {
                            origin: newSetting(
                                'damType',
                                workspace.location?.origin || 'sharepoint'
                            ),
                            siteId: workspace.sharepointSiteID,
                        };

                        state.entities.workspaces.byId[workspace.fid] =
                            newWorkspace;
                        // The server sends an ordered list of workspaces ->  allIds stores the ORDER of the list
                        state.entities.workspaces.allIds.push(workspace.fid);
                    }
                }
                state.entities.workspaces.lastRequested = Date.now();
            })
            /*
            Get All Libraries Request for workspace request
            */
            .addCase(librariesThunk.fulfilled, (state, action) => {
                if (action.payload === 'cacheValid') return;
                const { libraries, workspaceId } = action.payload;

                for (let i = 0; i < libraries.length; i++) {
                    const library = libraries[i];

                    let libCache;
                    if (state.entities.libraries.byId[library.friendly_id]) {
                        libCache =
                            state.entities.libraries.byId[library.friendly_id];
                    } else {
                        libCache = defaultLibrary();
                    }

                    libCache.name.value = library.name;
                    libCache.id = library.friendly_id;
                    libCache.type.value = library.type;
                    libCache.location = {
                        origin: newSetting('damType', library.location.origin),
                        siteId: library.location.siteId,
                        driveId: library.location.driveId,
                        webUrl: library.location.webUrl,
                    };

                    state.entities.libraries.byId[libCache.id] = libCache;
                    if (
                        state.entities.libraries.allIds.indexOf(libCache.id) ===
                        -1
                    ) {
                        state.entities.libraries.allIds.push(libCache.id);
                    }

                    const workLibLink = defaultWorkLib();
                    workLibLink.id = library.friendly_id + workspaceId;
                    workLibLink.libraryId = library.friendly_id;
                    workLibLink.workspaceId = workspaceId;
                    state.entities.workLib.byId[
                        library.friendly_id + workspaceId
                    ] = workLibLink;
                }
                state.entities.libraries.lastRequested[workspaceId] =
                    Date.now();
            });
    },
});

export const {
    resetTrackedTeamSetting,
    toggleWorkspacePinned,
    resetTeamSettings,
    clearTeamSettingsCache,
} = teamSettings.actions;
export default teamSettings.reducer;

/////////   Selectors   ///////////

// Libraries By Workspace id
/*
To select libraries by Id, I need to filter the stored libraries by the workLib maps

when using useSelector in a component, redux will check the returned value of the selector with every action using the === operator. 
if operator returns false, then the component is rerendered.

The issue with filtering, is that it will return a NEW array every time you use it. So, if used in a selector like below, it will always
return an array that will fail the === test, triggering a rerender with every action. 

To avoid this, we can layer our selectors and create a memoised version using createSelector.
*/
export const selectTeams = (state) => state.teamSettings.entities.teams;
export const selectWorkspaces = (state) =>
    state.teamSettings.entities.workspaces;
export const selectLibraries = (state) => state.teamSettings.entities.libraries;
export const selectWorkLibMap = (state) =>
    state.teamSettings.entities.workLib.byId;
export const selectWorkspaceById = (workspaceId) => (state) =>
    state.teamSettings.entities.workspaces.byId[workspaceId];
export const selectLibraryById = (libraryId) => (state) =>
    state.teamSettings.entities.libraries.byId[libraryId];
export const selectLibraryByDriveId = (driveId) => (state) => {
    const allLibraries = Object.values(
        state.teamSettings.entities.libraries.byId
    );
    return allLibraries.find((library) => library.location.driveId === driveId);
};

export const selectLibrariesByWorkspaceId = (id) =>
    createSelector(
        [selectLibraries, selectWorkLibMap],
        (libraries, workLibMap) => {
            return Object.values(workLibMap)
                .filter((link) => link.workspaceId === id)
                .map((link) => libraries.byId[link.libraryId]);
        }
    );

export const selectWorkspaceIds = (state) => {
    return state.teamSettings.entities.workspaces.allIds;
};
