import { useNavigate, useLocation } from 'react-router-dom';

/*
The below hook wraps the react-router useNavigate hook to provide saved history locations. 
It returns a function, with the same arguments as useNavigate, which will behave like navigate
with the added option "savedNavState". If the option is detected, and there exists a save state with that name,
it will navigate back to that location in the history stack.

The locations are saved and indexed by there name, provided to the hook argument. 

Locations are saved as a user navigates AWAY from them, using this hook's return. The saved locations are
never actively removed from the history state, but over written when needed. 
________________________________________________________________________________________________________________
*/

export const useSavedNavigate = (savedStateName = '') => {
    const location = useLocation();
    const navigate = useNavigate();

    const savedNavStates = location.state?.savedNavStates || {};

    return (to, options = {}) => {
        try {
            //Clone options
            const newOptions = { ...options };

            //Get the state option - only compatible with object states
            //best practice anyway so many process can alter and change the state independently?
            newOptions.state =
                newOptions.state &&
                Object.getPrototypeOf(newOptions.state) === Object.prototype
                    ? { ...newOptions.state }
                    : {};

            //Save the current nav states to the new nav state in options
            newOptions.state.savedNavStates = savedNavStates;

            //Check if it should navigate back to a saved state
            if (
                newOptions.savedNavState &&
                savedNavStates[newOptions.savedNavState] &&
                history.state.idx
            ) {
                return navigate(
                    savedNavStates[newOptions.savedNavState] -
                        history.state.idx,
                    newOptions
                );
            }

            if (savedStateName) {
                newOptions.state.savedNavStates[savedStateName] =
                    history.state.idx;
            }

            return navigate(to, newOptions);
        } catch {
            return navigate(to, options);
        }
    };
};
