import {
    Button,
    Menu,
    MenuDivider,
    MenuGroup,
    MenuGroupHeader,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@fluentui/react-components';
import { MoreHorizontal20Regular } from '@fluentui/react-icons';
import { useNavigateBreadcrumbs } from 'assetNav/hooks/breadcrumbNavigateHook';
import ColourIcon from 'global/components/ColourIcon';
import StopClickPropagation, {
    eventStopperTypes,
} from 'global/components/StopClickPropagation';
import { useToasterDispatch } from 'global/hooks/toastDispatchHook';
import { insertTypes } from 'officeAPI/insertingAssets';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import graphRequests from 'requests/endpoints/graph-endpoints';
import { bcColorMapper } from 'utils/colors/bcColorMapping';

/*
Description 
The '...' menu button that appears on all the asset cards - the more menu shown depends on the asset type and the rendering context.
e.g. a presentation is rendered as a slides object in a slide library but as a template in the new file page.
________________________________________________________________________________________________________________

Props:

asset -> a single asset object (see requests/uniform-asset-data)
disabled -> indicated if menu is non-interactive
btnClassName -> css class name for the menu trigger button
isMenuOpen -> callback function (bool) => {} that accepts the open state of the ctx menu
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const AssetMoreMenu = ({
    asset,
    insertAsset,
    disabled,
    btnClassName,
    isMenuOpen = false,
}) => {
    const [open, setOpen] = useState(false);
    return (
        <StopClickPropagation
            className={btnClassName}
            eventsType={eventStopperTypes.allClickEvents}
        >
            <Menu
                closeOnScroll
                open={open}
                onOpenChange={(e, data) => setOpen(data.open)}
            >
                <MenuTrigger>
                    <Button
                        className={btnClassName}
                        appearance="subtle"
                        size="small"
                        icon={<MoreHorizontal20Regular />}
                        disabled={disabled}
                        onContextMenu={(e) => {
                            e.preventDefault();
                            setOpen(true);
                        }}
                    />
                </MenuTrigger>

                <AssetMenuPopover
                    asset={asset}
                    insertAsset={insertAsset}
                    isMenuOpen={isMenuOpen}
                />
            </Menu>
        </StopClickPropagation>
    );
};

export const AssetMenuPopover = ({
    asset,
    insertAsset,
    isMenuOpen = false,
}) => {
    let AssetMenu;
    switch (asset.type) {
        case 'folder':
            AssetMenu = FolderMenu;
            break;
        case 'image/jpeg':

        case 'image/tiff':
        case 'image/png':
            AssetMenu = ImageMenu;
            break;
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            AssetMenu = SlideMenu;
            break;
        case 'application/vnd.openxmlformats-officedocument.presentationml.template':
        case 'template':
            AssetMenu = TemplateMenu;
            break;
        case 'image/svg+xml':
            AssetMenu = IconMenu;
            break;
        default:
            AssetMenu = FileMenu;
    }

    useEffect(() => {
        isMenuOpen && isMenuOpen(true);
        return () => isMenuOpen && isMenuOpen(false);
    }, []);

    return (
        <MenuPopover>
            <MenuList>
                <AssetMenu asset={asset} insertAsset={insertAsset} />
                <MenuDivider />
                <GeneralMenu asset={asset} />
            </MenuList>
        </MenuPopover>
    );
};

const GeneralMenu = ({ asset }) => {
    const { t } = useTranslation();
    const dispatchToast = useToasterDispatch();

    let openText =
        asset.type ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
            ? t('buttons.openOnline')
            : t('buttons.openSP');

    const getWebURL = useCallback(async () => {
        let webURL;
        switch (asset.type) {
            case 'image/jpeg':
            case 'image/tiff':
            case 'image/png':
            case 'image/gif':
            case 'image/svg+xml':
                const res = await graphRequests.endpoints.driveItemShareLink(
                    asset
                );
                webURL = res.data;
                break;
            default:
                webURL = asset.webURL;
        }
        return webURL;
    }, [asset]);

    return (
        <>
            <MenuItem
                onClick={() => {
                    if (typeof ClipboardItem && navigator.clipboard.write) {
                        // NOTE: Safari locks down the clipboard API to only work when triggered
                        //   by a direct user interaction.
                        //   Found this on https://wolfgangrittner.dev/how-to-use-clipboard-api-in-firefox/
                        const text = new ClipboardItem({
                            'text/plain': getWebURL()
                                .then((text) => {
                                    /* Send success message */
                                    dispatchToast('Link copied', '', 'success');
                                    return new Blob([text], {
                                        type: 'text/plain',
                                    });
                                })
                                .catch((err) => {
                                    dispatchToast(
                                        'Failed to copy link',
                                        '',
                                        'error'
                                    );
                                }),
                        });

                        navigator.clipboard.write([text]);
                    } else {
                        // NOTE: Firefox doesn't support the above, but does handle the regular method
                        getWebURL().then((text) => {
                            navigator.clipboard.writeText(text);
                        });
                        dispatchToast('Link copied', '', 'success').catch(
                            (e) => {
                                dispatchToast(
                                    'Failed to copy link',
                                    '',
                                    'error'
                                );
                            }
                        );
                    }
                }}
            >
                {t('buttons.copyLink')}
            </MenuItem>
            <MenuItem
                onClick={() => {
                    getWebURL()
                        .then((res) => {
                            window.open(res, '_blank')?.focus();
                            dispatchToast('Link opened', '', 'success');
                        })
                        .catch((e) =>
                            dispatchToast('Failed to open link', '', 'error')
                        );
                }}
            >
                {openText}
            </MenuItem>
        </>
    );
};

const FolderMenu = ({ asset }) => {
    const { t } = useTranslation();
    const { teamId, workspaceId, libraryId } = useParams();
    const navigate = useNavigateBreadcrumbs(asset.name, libraryId, asset.id);

    return (
        <>
            <MenuItem
                onClick={() => {
                    navigate(
                        `/teams/${teamId}/${workspaceId}/${libraryId}/${asset.id}`
                    );
                }}
            >
                {t('buttons.open')}
            </MenuItem>
        </>
    );
};

const FileMenu = ({ asset, insertAsset }) => {
    const { t } = useTranslation();
    return (
        <MenuItem onClick={() => insertAsset(asset)}>
            {t('buttons.insert')}
        </MenuItem>
    );
};

//Temporary Icon menu, only to be shown on icons in the BrightCarbon Icon Library
const IconMenu = ({ asset, insertAsset }) => {
    //If not BC Icon drive, return standard file menu
    if (
        asset.driveId !==
        'b!OTS627NxUUG_2aPE7UICkcyjxajDy9hFpY05svxzTLPDVNcLzh9ZQ5o2Or7HnuTu'
    ) {
        return <FileMenu asset={asset} insertAsset={insertAsset} />;
    }

    return (
        <MenuGroup>
            <MenuGroupHeader>Insert</MenuGroupHeader>
            <BCIconMenuItem
                asset={asset}
                insertAsset={insertAsset}
                mapName={'toPink'}
            >
                Fuchsia
            </BCIconMenuItem>
            <BCIconMenuItem
                asset={asset}
                insertAsset={insertAsset}
                mapName={'toGrey'}
            >
                Grey
            </BCIconMenuItem>
            <BCIconMenuItem
                asset={asset}
                insertAsset={insertAsset}
                mapName={'toTeal'}
            >
                Teal
            </BCIconMenuItem>
            <BCIconMenuItem
                asset={asset}
                insertAsset={insertAsset}
                mapName={'toGold'}
            >
                Gold
            </BCIconMenuItem>
        </MenuGroup>
    );
};

const BCIconMenuItem = ({ asset, insertAsset, children, mapName }) => {
    const mapper = new bcColorMapper();
    return (
        <MenuItem
            icon={<ColourIcon colour={mapper.colourMapToMainHex(mapName)} />}
            onClick={() => insertAsset(asset, null, mapName)}
        >
            {children}
        </MenuItem>
    );
};

const ImageMenu = ({ asset, insertAsset }) => {
    const { t } = useTranslation();
    return (
        <>
            <MenuItem
                onClick={() => insertAsset(asset, insertTypes.image.optimised)}
            >
                {t('buttons.insertOpt')}
            </MenuItem>
            <MenuItem
                onClick={() => insertAsset(asset, insertTypes.image.original)}
            >
                {t('buttons.insertOg')}
            </MenuItem>
        </>
    );
};

const TemplateMenu = ({ asset, insertAsset }) => {
    const { t } = useTranslation();
    return (
        <MenuItem
            onClick={() =>
                insertAsset(asset, insertTypes.presentation.asPresentation)
            }
        >
            {t('buttons.open')}
        </MenuItem>
    );
};

const SlideMenu = ({ asset, insertAsset }) => {
    const { t } = useTranslation();
    return (
        <>
            <MenuItem
                onClick={() =>
                    insertAsset(asset, insertTypes.presentation.asSlides)
                }
            >
                {t('buttons.insertSld')}
            </MenuItem>
            <MenuItem
                onClick={() =>
                    insertAsset(asset, insertTypes.presentation.asPresentation)
                }
            >
                {t('buttons.openCopy')}
            </MenuItem>
        </>
    );
};

export default AssetMoreMenu;
