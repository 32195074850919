import { configureStore } from '@reduxjs/toolkit';
import userSettingsReducer from './slices/userSettingsSlice';
import teamSettingsReducer from './slices/teamSettingsSlice';
import changeLogReducer from './slices/changeLogSlice';
import { SettingsTracking } from './settingTrackingMiddleware';

const store = configureStore({
    reducer: {
        userSettings: userSettingsReducer,
        teamSettings: teamSettingsReducer,
        changeLog: changeLogReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(SettingsTracking),
});

export default store;
