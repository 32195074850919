/* eslint-disable @typescript-eslint/no-unused-vars */
// LanguageSwitches.js
/*global console*/
/* eslint no-console: "off" */
import React from 'react';
import { Switch } from '@fluentui/react-components';
import { useSelector } from 'react-redux';
import { useTextThemes } from '../hooks/globalStylesHook';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';
import { setLanguage } from '../../../redux/slices/userSettingsSlice';

/*
Description 
________________________________________________________________________________________________________________

Props:

Name -> Description
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const LanguageSwitches = ({ languages, labels }) => {
    const lang = useSelector((state) => state.userSettings.lang.value);
    const rtl = useTextThemes(lang); // Pass the language to useTextThemes
    const dispatch = useDispatch();

    const switchLanguage = (languageCode) => {
        dispatch(setLanguage(languageCode)); // Dispatch the setLanguage action with the language code
        i18n.changeLanguage(languageCode);
        i18n.resolvedLanguage;
        console.log(i18n.resolvedLanguage);
        console.log(i18n.dir);
    };

    const isRTL = (language) => language === 'ar';

    return (
        <div>
            {languages.map((language, index) => (
                <div
                    key={language}
                    className={isRTL(language) ? rtl.rtlText : ''}
                >
                    <Switch
                        label={labels[index]}
                        inlineLabel={true}
                        checked={lang === language}
                        onChange={() => switchLanguage(language)}
                    />
                </div>
            ))}
        </div>
    );
};

export default LanguageSwitches;
