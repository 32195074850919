import { msgTypes } from '../messages/messages';

export default {
    allTeams: {
        type: msgTypes.err,
        //validates the workspaces entity: cache = teamSettings.entities.teams
        validator: (cache) => {
            //Simple check to see if cache has any previous settings
            if (!cache || cache.lastRequested === null) return false;
            return true;
        },
    },

    //Validate if user has cached all available workspaces
    allWorkspaces: {
        type: msgTypes.err,
        //validates the workspaces entity: cache = teamSettings.entities.workspaces
        validator: (cache) => {
            //Simple check to see if cache has any previous settings
            if (!cache || cache.lastRequested === null) return false;
            return true;
        },
    },

    allLibraries: (workspaceId) => ({
        type: msgTypes.err,
        //validates the set of libraries entities for a given workspace ID: cache = teamSettings.entities.libraries
        validator: (cache) => {
            //Simple check to see if we have previously requested all
            if (!cache || cache.lastRequested[workspaceId] === undefined)
                return false;
            return true;
        },
    }),

    //Check if workspace details for a particular workspace have been requested
    workspaceDetails: {
        type: msgTypes.err,
        //validates a single workspace entity: cache = teamSettings.entities.workspaces.byId(id)
        validator: (cache) => {
            if (!cache) return false;
            if (cache?.features?.migrateFeature.value === null) return false;

            return true;
        },
    },

    userPreferences: {
        type: msgTypes.err,
        //validates the entire user preferences: cache = userSettings
        validator: (cache) => {
            return cache.cacheValid.value;
        },
    },
};
