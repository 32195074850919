import React from 'react';
import { Subtitle2 } from '@fluentui/react-components';
import { useEmptyStateStyles } from 'home/hooks/emptyStateStylesHook';

/*
The EmptyStateTitle component manages the content and styling of the title text of an empty state.
________________________________________________________________________________________________________________

Props: 
Title -> The title of the current empty state. 
________________________________________________________________________________________________________________
*/

const EmptyStateTitle = ({ title }) => {
    const styles = useEmptyStateStyles();

    // return nothing if there is no title and body
    if (!title) return;

    return (
        <div className={styles.emptyStateContentDiv}>
            <Subtitle2>{title}</Subtitle2>
        </div>
    );
};

export default EmptyStateTitle;
