// ModalComponent.jsx

import React from 'react';
import {
    Dialog,
    DialogSurface,
    DialogTitle,
    DialogContent,
    DialogBody,
    Button,
    Subtitle2,
} from '@fluentui/react-components';
import {
    useButtonsStyles,
    useModalWindowStyles,
} from 'global/hooks/globalStylesHook';

/*
Description 
Global dialog pop up window
________________________________________________________________________________________________________________

Props:

isOpen -> toggle if the window is open or not
onPrimary -> action for primary button click
onSecondary -> action for secondary button click
modalTitle -> title for the specific modal window
modalText -> text for the specific modal window
primaryButtonText -> text for the primary button
secondaryButtonText -> text for the secondary button 
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const Alert = ({
    isOpen,
    onPrimary,
    onSecondary,
    modalTitle,
    modalText,
    primaryButtonText,
    secondaryButtonText, // Keep this prop to check if the secondary button is needed
}) => {
    const classes = useModalWindowStyles();
    const buttonStyles = useButtonsStyles();

    return (
        <Dialog open={isOpen} modalType="alert">
            <DialogSurface className={classes.modalWindow}>
                <DialogBody>
                    <DialogTitle>
                        <Subtitle2>{modalTitle}</Subtitle2>
                    </DialogTitle>
                    <DialogContent>{modalText}</DialogContent>
                    <div className={classes.buttonContainer}>
                        <Button
                            appearance="primary"
                            className={buttonStyles.singleLineText}
                            onClick={onPrimary}
                        >
                            {primaryButtonText}
                        </Button>
                        {secondaryButtonText && (
                            <Button
                                onClick={onSecondary}
                                className={buttonStyles.singleLineText}
                            >
                                {secondaryButtonText}
                            </Button>
                        )}
                    </div>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export default Alert;
