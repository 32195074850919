import { requestLayer } from 'requests/request-class';
import { driveItemsResponseParser } from 'requests/parsers/asset-response-parser.js';
import {
    getAllDrives,
    getDriveChildren,
    getDriveItemDownloadURL,
    getDriveItemShareURL,
    getDriveItemThumbnail,
    getRootDriveChildren,
    getUserData,
    searchDrive,
    getSiteTemplatesLink,
} from 'requests/helpers/msgraph-helper';
import { getFolderContents } from 'requests/helpers/msgraph-helper';
import { prepareSSOGraphToken } from 'requests/preppers/prepare-sso-token';
import { newErrMsg } from 'utils/messages/messages';
import { parseFetchGraphErrors } from 'requests/parsers/graph-error-parser';
import { libraryItemsResponseParser } from 'requests/parsers/library-response-parser';
import { prepareThrottleHandler } from 'requests/preppers/prepare-throttling-backoff';
import { parseFetchBody } from 'requests/parsers/fetch-api-body-parser';

/*
Exports an instance/configuration of the request layer that handles 
requests made to the graph API
________________________________________________________________________________________________________________
*/

const graphRequests = new requestLayer();
graphRequests.configure({
    /*
    User Data
    */
    getUserInfo: {
        request: async (req) => {
            try {
                const dataRes = await getUserData(req.graphToken);
                const res = { ...req, status: 200, data: dataRes }; //HERE
                return res;
            } catch (e) {
                throw {
                    ...req,
                    error: newErrMsg('Failed to get user info', e),
                    status: 404,
                };
            }
        },
        prepare: [prepareThrottleHandler(), prepareSSOGraphToken()],
        errParser: parseFetchGraphErrors,
    },

    /*
    Get all Sharepoint drives
    */
    getLibrariesBySiteId: {
        request: async (req) => {
            let dataRes;

            if (req.payload) {
                dataRes = await getAllDrives(
                    req.graphToken,
                    req.payload.siteId
                );
            }
            const res = { ...req, status: 200, data: dataRes };
            return res;
        },
        errParser: parseFetchGraphErrors,
        resParser: libraryItemsResponseParser,
        prepare: [
            prepareThrottleHandler(),
            prepareSSOGraphToken(['Sites.Read.All']),
        ],
    },
    /*
    Get Root SharePoint drive
    */
    getTemplatesBySiteId: {
        request: async (req) => {
            const result = await getRootDriveChildren(
                req.graphToken,
                req.payload.siteId
            );
            return { ...req, status: 200, data: result };
        },
        errParser: parseFetchGraphErrors,
        resParser: driveItemsResponseParser,
        prepare: [
            prepareThrottleHandler(),
            prepareSSOGraphToken(['Sites.Read.All']),
        ],
    },

    /*
    SharePoint Drives
    */
    getGraphItems: {
        /*
        Payload Structure:
        {
            folderId: ...,
            driveId: ...,
            siteId:...,
        }
        */
        request: async (req) => {
            let dataRes;

            if (!req.payload.folderId && req.payload.driveId) {
                dataRes = await getDriveChildren(
                    req.graphToken,
                    req.payload.driveId
                );
            } else if (req.payload.folderId && req.payload.driveId) {
                dataRes = await getFolderContents(
                    req.graphToken,
                    req.payload.folderId,
                    req.payload.driveId
                );
            } else {
                dataRes = await getFolderContents(
                    req.graphToken,
                    req.payload.folderId
                );
            }
            const res = { ...req, status: 200, data: dataRes }; //HERE
            return res;
        },
        errParser: parseFetchGraphErrors,
        resParser: driveItemsResponseParser,
        prepare: [
            prepareThrottleHandler(),
            prepareSSOGraphToken(['Sites.Read.All']),
        ],
    },

    searchGraphItems: {
        request: async (req) => {
            const dataRes = await searchDrive(
                req.graphToken,
                req.payload.driveId,
                req.payload.searchText
            );
            return {
                ...req,
                data: dataRes,
                status: 200,
            };
        },
        errParser: parseFetchGraphErrors,
        resParser: driveItemsResponseParser,
        prepare: [
            prepareThrottleHandler(),
            prepareSSOGraphToken(['Sites.Read.All']),
        ],
    },

    driveItemDownloadURL: {
        request: async (req) => {
            try {
                const downloadURL = await getDriveItemDownloadURL(
                    req.graphToken,
                    req.payload.driveId,
                    req.payload.itemId
                );
                return { ...req, status: 200, data: downloadURL };
            } catch (e) {
                throw {
                    ...req,
                    status: 404,
                    error: newErrMsg('Download URL not found'),
                };
            }
        },
        errParser: parseFetchGraphErrors,
        prepare: [
            prepareThrottleHandler(),
            prepareSSOGraphToken(['Sites.Read.All']),
        ],
    },
    driveItemThumbnailURL: {
        request: async (req) => {
            try {
                const thumbnailURL = await getDriveItemThumbnail(
                    req.graphToken,
                    req.payload.driveId,
                    req.payload.id
                );
                return { ...req, status: 200, data: thumbnailURL };
            } catch (e) {
                throw {
                    ...req,
                    status: 404,
                    error: newErrMsg('Download URL not found'),
                };
            }
        },
        errParser: parseFetchGraphErrors,
        prepare: [
            prepareThrottleHandler(),
            prepareSSOGraphToken(['Sites.Read.All']),
        ],
    },

    downloadDriveItem: {
        request: async (req) => {
            let response;
            try {
                response = await fetch(req.payload.url, {
                    signal: req.signal || undefined,
                });
            } catch (e) {
                //Network error: send to parser to convert to err res token
                throw e;
            }

            if (response.ok) {
                //send response to the parser
                return { ...req, data: response, status: response.status };
            } else {
                //send response to error parser to convert to err res token
                throw response;
            }
        },
        errParser: parseFetchGraphErrors,
        resParser: parseFetchBody,
        prepare: [prepareThrottleHandler()],
    },
    driveItemShareLink: {
        request: async (req) => {
            const linkURL = await getDriveItemShareURL(
                req.graphToken,
                req.payload.driveId,
                req.payload.id
            );
            return { ...req, status: 200, data: linkURL };
        },
        errParser: parseFetchGraphErrors,
        prepare: [
            prepareThrottleHandler(),
            prepareSSOGraphToken(['Files.ReadWrite']),
        ],
    },
    driveTemplatesShareLink: {
        request: async (req) => {
            const linkURL = await getSiteTemplatesLink(
                req.graphToken,
                req.payload.siteId
            );
            return { ...req, status: 200, data: linkURL };
        },
        errParser: parseFetchGraphErrors,
        prepare: [
            prepareThrottleHandler(),
            prepareSSOGraphToken(['Sites.Read.All']),
        ],
    },
});

export default graphRequests;

export const { getGraphItems, getUserInfo } = graphRequests.endpoints;
