import { Button } from '@fluentui/react-components';
import { Settings20Regular } from '@fluentui/react-icons';
import React from 'react';
import LinkSaved from 'global/components/LinkSaved';
import { useFooterState } from 'headerFooter/hooks/headerFooterStateHook';
import { useHeaderFooterStyles } from 'headerFooter/hooks/headerFooterStylesHook';

/*
Renders the app settings button for the footer.
________________________________________________________________________________________________________________

Props:

settingsState -> object {visible:bool, enabled:bool} used to indicate how the button renders
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const FooterSettingsButton = ({ settingsState }) => {
    const styles = useHeaderFooterStyles();

    if (!settingsState.visible) return;

    return (
        <LinkSaved to="/settings" saveState={'settings'}>
            <Button
                disabled={!settingsState.enabled}
                appearance="subtle"
                className={styles.navButton}
                size="medium"
                icon={<Settings20Regular />}
            />
        </LinkSaved>
    );
};

export default FooterSettingsButton;
