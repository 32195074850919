import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft20Regular } from '@fluentui/react-icons';
import { Button } from '@fluentui/react-components';
import { useHeaderFooterStyles } from 'headerFooter/hooks/headerFooterStylesHook';

/*
Renders the back arrow used for navigating backwards though the history stack
________________________________________________________________________________________________________________

Props:

backNavState -> object {visible:bool, enabled:bool} used to indicate how the button renders
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const HeaderBackNavButton = ({ backNavState }) => {
    const navigate = useNavigate();
    const styles = useHeaderFooterStyles();

    //return nothing if not visible
    if (!backNavState.visible) return;

    //disable back button if current page is the first in the history stack
    const btnEnabled = backNavState.enabled && history.state.idx !== 1;

    return (
        <Button
            disabled={!btnEnabled}
            appearance="subtle"
            className={styles.navButton}
            icon={<ArrowLeft20Regular />}
            size="medium"
            onClick={() => navigate(-1)}
        />
    );
};

export default HeaderBackNavButton;
