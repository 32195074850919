import { Body1, Label } from '@fluentui/react-components';
import {
    Document20Regular,
    Folder20Regular,
    Icons20Regular,
    Image20Regular,
    PreviewLink20Regular,
    ProjectionScreen20Regular,
} from '@fluentui/react-icons';
import React from 'react';
import { useHomeListItemStyles } from 'home/hooks/homeStylesHook';

/*
Description 

Used to display navigation buttons in the home page including:
- Libraries
- New presentation
________________________________________________________________________________________________________________

Props: 

iconType -> used to select what icon to display
text -> rendered in the label portion of the list item
onClick -> callback function for the click event
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const HomeListItem = ({ iconType = 'general', text = '', onClick }) => {
    const styles = useHomeListItemStyles();

    return (
        <div className={styles.homeListItem} onClick={onClick}>
            <ListItemIcon type={iconType} />
            <Label>
                <Body1>{text}</Body1>
            </Label>
        </div>
    );
};

//Simple component to render the correct asset icon
const ListItemIcon = ({ type = 'general' }) => {
    const styles = useHomeListItemStyles();
    switch (type) {
        case 'presentation':
            return <PreviewLink20Regular className={styles.homeListItemIcon} />;
        case 'slide':
            return (
                <ProjectionScreen20Regular
                    className={styles.homeListItemIcon}
                />
            );
        case 'icon':
            return <Icons20Regular className={styles.homeListItemIcon} />;
        case 'image':
            return <Image20Regular className={styles.homeListItemIcon} />;
        case 'newFile':
            return <Document20Regular className={styles.homeListItemIcon} />;
        default:
            return <Folder20Regular className={styles.homeListItemIcon} />;
    }
};

export default HomeListItem;
