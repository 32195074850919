import React, { useMemo, useEffect } from 'react';
import {
    createLightTheme,
    createDarkTheme,
    FluentProvider,
    useThemeClassName,
    themeToTokensObject,
} from '@fluentui/react-components';
import { useBackgroundStyle } from 'global/hooks/globalStylesHook';
import { useSelector } from 'react-redux';

/*
This file contains the context and provider for the global fluent UI stylings
It first generates the light and dark mode themes, which get provided depending on 
the users personal preference (redux userSettings)
Apply to body also adds in the styling to the HTML body component, as this is not done by default any more.
________________________________________________________________________________________________________________
*/

//Create Custom Theming

const customBrandRamp = {
    10: '#060204',
    20: '#24101C',
    30: '#3F1630',
    40: '#551941',
    50: '#6C1C51',
    60: '#841D63',
    70: '#9D1D75',
    80: '#B61B88',
    90: '#C33595',
    100: '#CC50A0',
    110: '#D367AB',
    120: '#DB7CB5',
    130: '#E291C1',
    140: '#E8A5CC',
    150: '#EEB9D7',
    160: '#F4CDE2',
};

//use brand ramp to generate light and dark themes.
// The themes contain all stylings from border radius to font to colours etc
const BCLightTheme = createLightTheme(customBrandRamp);
const BCDarkTheme = createDarkTheme(customBrandRamp);

//Adding in custom tokens to enable cleaner code:
//NOTE - components using the custom code cannot be shared between projects unless the tokens are updated similarly.

BCLightTheme.colorBackgroundHoverAsset = BCLightTheme.colorNeutralBackground2;
BCDarkTheme.colorBackgroundHoverAsset =
    BCDarkTheme.colorNeutralBackground2Hover;

//Custom tokens generated contains all tokens - not tree shakeable (?) according to docs
export const customTokens = themeToTokensObject(BCLightTheme);

//The hyperlink colour for dark mode may need to be changed for accessibility reasons (not enough contrast)
// I think it would be changed here, after theme creation, before serving it to the theme provider.

//Fluent UI V9 doesn't actively apply theming to the body component.
//This component has no visible role (Returns null -> no rendered elements)
// Instead, it uses the useThemeClassName hook to run code every time the theme changes.
// It gets the theme css classnames, adds them to the body, and also overrides the background colour
// with the colorNeutralBackground2 colour token.
const ApplyToBody = () => {
    const classes = useThemeClassName();
    const style = useBackgroundStyle();

    useEffect(() => {
        const classList = classes.split(' ');
        document.body.classList.add(
            ...classList,
            ...style.bodyStyling.split(' ').slice(1)
        );

        return () => document.body.classList.remove(...classList);
    }, [classes]);

    return null;
};

const BCThemeProvider = ({ children }) => {
    const darkMode = useSelector((state) => state.userSettings.darkMode);
    const lang = useSelector((state) => state.userSettings.lang);
    const systemDarkMode = useSelector(
        (state) => state.userSettings.systemDarkMode
    );
    const style = useBackgroundStyle();

    const theme = useMemo(() => {
        if (systemDarkMode.value || darkMode.value == null) {
            return window.matchMedia('(prefers-color-scheme: dark)').matches
                ? BCDarkTheme
                : BCLightTheme;
        } else {
            return darkMode.value ? BCDarkTheme : BCLightTheme;
        }
    }, [darkMode.value, systemDarkMode.value]);

    return (
        <FluentProvider
            className={style.globalBackgroundColour}
            theme={theme}
            dir={lang.value === 'ar' ? 'rtl' : 'ltr'}
        >
            <ApplyToBody />
            {children}
        </FluentProvider>
    );
};

export { BCThemeProvider };
