import React, { useEffect } from 'react';
import {
    Body1,
    Link,
    Subtitle1,
    mergeClasses,
} from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { useSettingMenuStyles } from 'settings/hooks/settingsStylesHook';
import { useHeaderVisibility } from 'headerFooter/hooks/visibilityHook';
import { useHeaderState } from 'headerFooter/hooks/headerFooterStateHook';

/*
Component responsible for the title/body that appears at the top of each settings page. 

If the component is used, it should always include a title
the body/learnMoreLink are optional. 
*/
const SettingsMenuTitle = ({ title, body, learnMoreLink }) => {
    const { t } = useTranslation();
    const styles = useSettingMenuStyles();
    const [fracVis, elRef] = useHeaderVisibility(0.5);
    //const dispatch = useDispatch();
    const [_, dispatch] = useHeaderState();

    // return nothing if there is no title and body
    if (!title && !body) return;

    useEffect(() => {
        if (fracVis === 0 && elRef.current) {
            dispatch({ type: 'titleText', text: title });
        } else {
            dispatch({ type: 'titleText', text: '' });
        }
        return () => dispatch({ type: 'titleText', text: '' });
    }, [fracVis]);

    return (
        <div className={styles.menuTitleWrapper}>
            {title && (
                <Subtitle1 ref={elRef} className={styles.displayBlock}>
                    {title}
                </Subtitle1>
            )}
            {body && (
                <Body1
                    className={mergeClasses(
                        styles.displayBlock,
                        styles.middleMargin
                    )}
                >
                    {body}{' '}
                    {learnMoreLink && (
                        <Link href={learnMoreLink}>
                            {t('common.learnMore')}
                        </Link>
                    )}
                </Body1>
            )}
        </div>
    );
};

export default SettingsMenuTitle;
