import { Label, Tooltip } from '@fluentui/react-components';
import React, { useRef, useState } from 'react';

/*
Description
Renders the name of an asset in the different views - has hover tool tip for overflowing names
________________________________________________________________________________________________________________

Props:

disabled -> boolean to indicate if the label is disabled in appearance
labelText -> string for the text to display in the label
labelClassName -> style class name for the label itself - used for positioning and size
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const AssetLabelToolTip = ({
    disabled = false,
    labelText = '',
    labelClassName = '',
}) => {
    //used to manually control when the tool tip should render
    const labelRef = useRef();
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <Tooltip
            visible={showTooltip}
            onVisibleChange={(e, data) => {
                if (
                    data.visible &&
                    (labelRef.current.scrollHeight >
                        labelRef.current.clientHeight ||
                        labelRef.current.scrollWidth >
                            labelRef.current.clientWidth)
                ) {
                    setShowTooltip(true);
                }

                if (!data.visible) setShowTooltip(false);
            }}
            withArrow
            showDelay={200}
            content={labelText}
        >
            <Label
                ref={labelRef}
                disabled={disabled}
                className={labelClassName}
            >
                {labelText}
            </Label>
        </Tooltip>
    );
};

export default AssetLabelToolTip;
