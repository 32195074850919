// Class extended for Error to customError
class customError extends Error {
    // Constructor method used to intialise a new customError with a message and an error code.
    constructor(
        errorMessage,
        errorCode,
        responseBool = false,
        responseDifferentMessage = null
    ) {
        // Call the constructor of the parent Error class with errorMessage argument
        super(errorMessage);

        // Set the name property to the name of the constructor function (customError)
        this.name = this.constructor.name;

        // Add a custom property 'code' to store an additional error code
        this.errorCode = errorCode; // Note - this refers to the status code (404, 401, 500, etc.) NOT the node error.code code indentifier

        // Add a custom property 'responseBool' to signify that a response is to be given to the user
        this.responseBool = responseBool;

        // Add a custom property 'responseDifferentMessage' to signify that the response message is to be this one instead of the errorMessage
        this.responseDifferentMessage = responseDifferentMessage;
    }
}

export default customError;
// Example custom Error
/*
 customError(
    errorMessage,
    errorCode,
    responseBool,
    responseDifferentMessage
);
throw new customError(
    'Azure SSO Error',
    404,
    true,
    'We are experiencing a problem with our server. Please try again.'
);
*/
// --------------------------------------------------------
// TEST error wrapper
// wraps the func in the error wrapper function
// can handle both asynchronous and synchronous functions
/* 
   Handling Synchronous Code:
        The useErrorWrapper invokes func(req, res, next) immediately within Promise.resolve(func(req, res, next)).
        Promise.resolve(func(req, res, next)) converts the return value of func(req, res, next) into a Promise.
        If func(req, res, next) returns a non-Promise value (e.g., undefined, null, or a regular value), Promise.resolve() converts it into a resolved Promise with that value.
        If an error occurs during the execution of func(req, res, next) (e.g., an exception is thrown), the Promise returned by Promise.resolve() is rejected with that error.

   Handling Asynchronous Code:
        If func(req, res, next) returns a Promise (whether immediately or after some asynchronous operation), Promise.resolve(func(req, res, next)) simply returns that Promise.
        If the Promise returned by func(req, res, next) resolves successfully, the outer Promise.resolve(...) also resolves successfully.
        If the Promise returned by func(req, res, next) is rejected (i.e., an error occurs during its execution or it returns a rejected Promise), the .catch(next) handler attached to the outer Promise catches the error.
*/

export const useErrorWrapper = (func) => (req, res, next) =>
    Promise.resolve(func(req, res, next)).catch(next);
// --------------------------------------------------------
