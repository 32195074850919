import { Avatar, Image } from '@fluentui/react-components';
import React from 'react';

/*
Description 
renders the Icon associated with a workspace, with avatar initials fallback
________________________________________________________________________________________________________________

Props:

iconURL -> the src url for the icon
name -> the workspace name
className -> used for styling forwarding 
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const WorkspaceIcon = ({ iconURL = '', name = '', className, setIconRef = null }) => {
    return iconURL ? (
        <Image
            ref={setIconRef}
            className={className}
            src={iconURL}
            height={32}
            width={32}
            shape="rounded"
        />
    ) : (
        <Avatar
            ref={setIconRef}
            className={className}
            color="colorful"
            name={name}
            shape="square"
            size={32}
        />
    );
};

export default WorkspaceIcon;
