import {
    Menu,
    MenuButton,
    MenuDivider,
    MenuGroup,
    MenuGroupHeader,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@fluentui/react-components';
import ColourIcon from 'global/components/ColourIcon';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectLibraryById } from 'reduxStore/slices/teamSettingsSlice';
import { bcColorMapper } from 'utils/colors/bcColorMapping';

/*
The Recolour tool for the BrightCarbon colour mapping
________________________________________________________________________________________________________________

Props:
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> The colour Icon styles and the copy are not extracted into the other files as this feature should be as
     self contained as possible to make removing it later easy
________________________________________________________________________________________________________________
*/

export const AssetToolBCColour = () => {
    const mapper = new bcColorMapper();
    const defaultMap = mapper.defaultMap;
    const [currDefault, setCurrDefault] = useState(defaultMap);
    const { libraryId } = useParams();
    const library = useSelector(selectLibraryById(libraryId));

    //Only show tool in BrightCarbon Icon Library
    if (
        library.location.driveId !==
        'b!OTS627NxUUG_2aPE7UICkcyjxajDy9hFpY05svxzTLPDVNcLzh9ZQ5o2Or7HnuTu'
    )
        return null;

    return (
        <Menu hasIcons>
            <MenuTrigger>
                <MenuButton
                    appearance="subtle"
                    icon={
                        <ColourIcon
                            colour={mapper.colourMapToMainHex(currDefault)}
                        />
                    }
                >
                    Color
                </MenuButton>
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    <MenuGroup>
                        <MenuGroupHeader>Primary</MenuGroupHeader>
                        <ColorMenuItem
                            name="Fuchsia"
                            mapName="toPink"
                            setCurrDefault={setCurrDefault}
                        />
                        <ColorMenuItem
                            name="Grey"
                            mapName="toGrey"
                            setCurrDefault={setCurrDefault}
                        />
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup>
                        <MenuGroupHeader>Secondary</MenuGroupHeader>
                        <ColorMenuItem
                            name="Teal"
                            mapName="toTeal"
                            setCurrDefault={setCurrDefault}
                        />
                        <ColorMenuItem
                            name="Gold"
                            mapName="toGold"
                            setCurrDefault={setCurrDefault}
                        />
                    </MenuGroup>
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};

const ColorMenuItem = ({ name, mapName, setCurrDefault }) => {
    const mapper = new bcColorMapper();
    return (
        <MenuItem
            onClick={() => {
                mapper.defaultMap = mapName;
                setCurrDefault(mapName);
            }}
            icon={<ColourIcon colour={mapper.colourMapToMainHex(mapName)} />}
        >
            {name}
        </MenuItem>
    );
};
