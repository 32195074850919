import {
    Image,
    Menu,
    Spinner,
    mergeClasses,
    MenuTrigger,
} from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import { useAssetStyles } from 'assetNav/hooks/assetStylesHook';
import AssetLabelToolTip from './AssetLabelToolTip';
import AssetMoreMenu, { AssetMenuPopover } from './AssetMoreMenu';
import { useNavigate, useParams } from 'react-router-dom';
import { useNavigateBreadcrumbs } from 'assetNav/hooks/breadcrumbNavigateHook';
import { useSelector } from 'react-redux';
import { selectLibraryByDriveId } from 'reduxStore/slices/teamSettingsSlice';

/*
Description 
One of the different asset display types, this displays assets as a list item. 
________________________________________________________________________________________________________________

Props:

asset -> a single asset object (see requests/uniform-asset-data)
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const AssetListItem = ({ asset }) => {
    const [showMore, setShowMore] = useState(false);
    const styles = useAssetStyles();
    const library = useSelector(selectLibraryByDriveId(asset.driveId));
    const navigate = useNavigateBreadcrumbs(asset.name, library.id, asset.id);
    const { teamId, workspaceId, libraryId, folderId } = useParams();

    //Controls the active state of the tile
    const [mouseDown, setMouseDown] = useState(false);

    //Controls if the visual hover state should appear outside of mouse hover event
    const [mockHover, setMockHover] = useState(false);

    //Controls the inserting state of the tile
    // Will probably be connected to a hook later on to
    //handle insertion lifetime and interact with controller
    const [inserting, setInserting] = useState(false);

    return (
        <Menu openOnContext closeOnScroll>
            <MenuTrigger>
                <div
                    onMouseEnter={() => {
                        setShowMore(true);
                    }}
                    onMouseLeave={() => {
                        setMouseDown(false);
                        setShowMore(false);
                    }}
                    onMouseDown={() => setMouseDown(true)}
                    onMouseUp={() => setMouseDown(false)}
                    onContextMenu={() => {
                        setMouseDown(false);
                    }}
                    onClick={() => {
                        const url = `/teams/${teamId}/${workspaceId}/${libraryId}/${asset.id}`;
                        navigate(url);
                    }}
                    className={mergeClasses(
                        styles.assetListItem,
                        mouseDown && styles.assetTileActive,
                        (inserting || mockHover) && styles.assetTileInserting
                    )}
                >
                    {!inserting && <AssetListIcon assetType={asset.type} />}
                    {inserting && (
                        <Spinner
                            size="tiny"
                            className={styles.assetListItemSpinner}
                        />
                    )}
                    <AssetLabelToolTip
                        labelText={asset.name}
                        labelClassName={styles.assetListItemLabel}
                        disabled={inserting}
                    />
                    {showMore && (
                        <AssetMoreMenu
                            disabled={inserting}
                            asset={asset}
                            isMenuOpen={setMockHover}
                        />
                    )}
                </div>
            </MenuTrigger>
            <AssetMenuPopover asset={asset} isMenuOpen={setMockHover} />
        </Menu>
    );
};

const AssetListIcon = ({ assetType }) => {
    const styles = useAssetStyles();
    let url;
    switch (assetType) {
        case 'folder':
            //User agent isn't fully reliable for detecting OS version - it can be changed by the user
            //But since this is just a small visual effect that users have to go out of there way to change, should be fine.
            const isMac =
                navigator.userAgent.toLowerCase().indexOf('mac') !== -1;
            url = isMac ? '/assets/macFolder.svg' : '/assets/winFolder.svg';
            break;
        case 'image/svg+xml':
        case 'image/tiff':
        case 'image/png':
        case 'image/gif':
        case 'image/jpeg':
            url = '/assets/image.svg';
            break;
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'application/vnd.openxmlformats-officedocument.presentationml.template':
            url = '/assets/ppt.svg';
            break;
    }
    return <Image className={styles.assetListItemIcon} src={url} />;
};

export default AssetListItem;
