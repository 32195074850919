import { signUpStates } from 'login/pages/SignUpPage';
import bcServerRequests from 'requests/endpoints/bc-server-endpoints';
import { json } from 'react-router-dom';

/*
Stores the loader functions used in the sign in routes
________________________________________________________________________________________________________________
*/
export const signUpLoader = async ({ request }) => {
    //Get the registration secret
    const url = new URL(request.url);
    const regSecret = url.searchParams.get('registration_secret_URLenc');

    if (!regSecret) return json({ state: signUpStates.noSecret });

    try {
        await bcServerRequests.endpoints.signUp({
            regCode: regSecret,
        });
        return json({ state: signUpStates.success });
    } catch (res) {
        console.log(res);
        switch (res.status) {
            case 401:
                if (res.error.text === 'Failed to get user ID token') {
                    return json({
                        state: signUpStates.pending,
                    });
                }
                switch (res.error.payload) {
                    case 'user already part of team':
                        return json({
                            state: signUpStates.userAlreadyExists,
                        });
                    case 'registration code expired':
                        return json({
                            state: signUpStates.secretExpired,
                        });
                    case 'registration code already used':
                        return json({
                            state: signUpStates.secretAlreadyUsed,
                        });
                    default:
                        return json({
                            state: signUpStates.unknownFailure,
                        });
                }
            default:
                return json({
                    state: signUpStates.unknownFailure,
                });
        }
    }
};
