import {
    Body2,
    Button,
    Caption1,
    Image,
    Subtitle1,
} from '@fluentui/react-components';
import { useSignUpStyles } from 'login/hooks/loginStylesHook';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import bcServerRequests from 'requests/endpoints/bc-server-endpoints';
import links from '../../linkManagement/links.json';

/*
The page used to register a new user with the team.
It will display a text box that the user will input their sign up code into, where we will then authenticate them
________________________________________________________________________________________________________________

Custom Components:

SettingsMenuTitle -> Used for app wide title/body template
________________________________________________________________________________________________________________

IMPORTANT NOTES
    -> Name
        Description
________________________________________________________________________________________________________________

*/

export const signUpStates = {
    pending: 'pending',
    success: 'success',
    unknownFailure: 'failure',
    secretExpired: 'expired',
    secretAlreadyUsed: 'alreadyUsed',
    userAlreadyExists: 'userAlreadyExists',
    noSecret: 'noSecret',
    noIdToken: 'noIdToken',
};

const SignUpPage = () => {
    const signUpLoaderData = useLoaderData();
    const { t } = useTranslation();
    const [signUpState, setSignUpState] = useState(
        signUpLoaderData.state || signUpStates.unknownFailure
    );
    const styles = useSignUpStyles();

    return (
        <div className={styles.signUpPage}>
            <Image width={80} src="/assets/BI Logo 161x61.png" />
            <SignUpContent
                signUpState={signUpState}
                setSignUpState={setSignUpState}
            />
            <div className={styles.signUpFooter}>
                <Image width={20} src="/assets/BC Logo.svg" />
                <Caption1 align="center">{t('signUpPage.caption')}</Caption1>
            </div>
        </div>
    );
};

const SignUpContent = ({ signUpState, setSignUpState }) => {
    const styles = useSignUpStyles();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const handleClick = useCallback(async () => {
        //Get the registration secret
        const regSecret = searchParams.get('registration_secret_URLenc');

        if (!regSecret) {
            setSignUpState(signUpStates.noSecret);
            return;
        }

        //Attempt to sign up - on failure parse error into login state type
        try {
            await bcServerRequests.endpoints.signUp({
                regCode: regSecret,
            });
            setSignUpState(signUpStates.success);
        } catch (res) {
            console.log(res);
            switch (res.status) {
                case 401:
                    if (res.error.text === 'Failed to get user ID token') {
                        setSignUpState(signUpStates.noIdToken);
                        return;
                    }
                    switch (res.error.payload) {
                        case 'user already part of team':
                            setSignUpState(signUpStates.userAlreadyExists);
                            break;
                        case 'registration code expired':
                            setSignUpState(signUpStates.secretExpired);
                            break;
                        case 'registration code already used':
                            setSignUpState(signUpStates.secretAlreadyUsed);
                            break;
                        default:
                            setSignUpState(signUpStates.unknownFailure);
                    }
                    break;
                default:
                    setSignUpState(signUpStates.unknownFailure);
            }
        }
    }, [searchParams]);

    const content = useMemo(() => {
        const content = { title: '', body: '', iconSrc: '' };
        switch (signUpState) {
            case signUpStates.success:
                content.title = t('signUpPage.success.title');
                content.body = t('signUpPage.success.body');
                content.iconSrc = '/assets/Success 300@2x.png';
                break;
            case signUpStates.pending:
                content.title = t('signUpPage.pending.title');
                content.body = t('signUpPage.pending.body');
                content.iconSrc = '/assets/Almost done 300@2x.png';
                break;
            default:
                console.log(`Error Signing Up: ${signUpState}`);
                content.title = t('signUpPage.failure.title');
                content.body = t('signUpPage.failure.body');
                content.iconSrc = '/assets/Failure 300@2x.png';
                break;
        }
        return content;
    }, [signUpState]);

    return (
        <div className={styles.signUpContent}>
            <Image className={styles.signUpImage} src={content.iconSrc} />
            <div className={styles.signUpText}>
                <Subtitle1 align="center">{content.title}</Subtitle1>
                <Body2 align="center">{content.body}</Body2>
            </div>
            {signUpState === signUpStates.pending && (
                <Button appearance="primary" onClick={handleClick}>
                    {t('signUpPage.pending.actionButton')}
                </Button>
            )}
            {signUpState !== signUpStates.success &&
                signUpState !== signUpStates.pending && (
                    <a target="_blank" href={links.general.supportEmail}>
                        <Button appearance="primary">
                            {t('signUpPage.failure.actionButton')}
                        </Button>
                    </a>
                )}
        </div>
    );
};

export default SignUpPage;
