/*
Hook to return a boolean state, indicating if a fixed scroll bar is present in the window
if one is present, the document width narrows while the window inner width is the same.
________________________________________________________________________________________________________________
*/

import { useEffect, useState } from "react";

export const useWindowScrollBarVisible = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const Observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === document.documentElement) {
                    setVisible(
                        document.documentElement.clientWidth < window.innerWidth
                    );
                }
            }
        });
        Observer.observe(document.documentElement);
        return () => {
            Observer.disconnect();
        };
    }, []);

    return visible;
};
