import { Label, ProgressBar } from '@fluentui/react-components';
import { useTutorialStyles } from 'firstRun/hooks/firstRunStylesHook';
import React from 'react';
import { useTranslation } from 'react-i18next';

/*
A multi purpose progress bar that renders in the footer

The footer state contains the max value for the bar, as well as the current value.
The bar will transition between the two different states.
________________________________________________________________________________________________________________

Props:

progressState -> object {visible:bool, value:int, max:int} used to indicate how the progress bar renders
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const FooterProgressBar = ({ progressState }) => {
    const classes = useTutorialStyles();
    const { t } = useTranslation();

    if (!progressState.visible) return null;

    return (
        <div className={classes.progressBarContainer}>
            <ProgressBar value={progressState.value} max={progressState.max} />
            <Label className={classes.progressLabel}>
                {t('footer.progressBar', {
                    value: progressState.value,
                    max: progressState.max,
                })}
            </Label>
        </div>
    );
};

export default FooterProgressBar;
