import { requestLayer } from 'requests/request-class';
import * as $ from 'jquery';
import {
    prepareSSOGraphToken,
    prepareSSOUserIdToken,
} from 'requests/preppers/prepare-sso-token';
import { prepareBCJWT } from 'requests/preppers/prepare-bc-jwt';
import { createAbortLink } from 'requests/request-utils';
import { parseJQueryAjaxError } from 'requests/parsers/error-parsers';
import { libraryItemsResponseParser } from 'requests/parsers/library-response-parser';
import { getAllDrives } from '../helpers/msgraph-helper';
import { parseFetchBody } from 'requests/parsers/fetch-api-body-parser';
import { parseFetchGraphErrors } from 'requests/parsers/graph-error-parser';

/*
Exports an instance/configuration of the request layer that handles 
all requests to the BrightCarbon server.
________________________________________________________________________________________________________________
*/

const bcServerRequests = new requestLayer();

bcServerRequests.configure({
    signUp: {
        request: async (req) => {
            const response = await fetch(
                location.origin +
                    `/sso/user-onboard-first-time-sso?registration_secret_URLenc=${req.payload.regCode}`,
                {
                    method:'POST',
                    headers: {
                        Authorization: `Bearer ${req.idToken}`,
                    },
                    signal: req.signal || null
                }
            );
            if (!response.ok) throw response;
            return { ...req, data: response, status: response.status };
        },
        prepare: [prepareSSOUserIdToken],
        resParser: parseFetchBody,
        errParser: parseFetchGraphErrors,
    },

    getUserTeams: {
        request: async (req) => {
            let res;
            await $.ajax({
                type: 'GET',
                url: `/auth/get-available-teams`,
                headers: { Authorization: 'Bearer ' + req.idToken },
                cache: false,
                ...createAbortLink(req.signal),
                success: (data, statusText, jqXhr) => {
                    res = { ...req, data: data, status: jqXhr.status };
                },
            });
            return res;
        },
        prepare: [prepareSSOUserIdToken],
        errParser: parseJQueryAjaxError,
    },

    /*
    User Preference requests
    */
    getUserPreferences: {
        request: async (req) => {
            let res;
            await $.ajax({
                type: 'GET',
                url: `/user-prefs-SSO/get-preferences-SSO`,
                headers: { Authorization: 'Bearer ' + req.idToken },
                cache: false,
                ...createAbortLink(req.signal),
                success: (data, statusText, jqXhr) => {
                    res = { ...req, data: data, status: jqXhr.status };
                },
            });
            return res;
        },
        prepare: [prepareSSOUserIdToken],
        errParser: parseJQueryAjaxError,
    },

    updateUserPreferences: {
        request: async (req) => {
            let res;
            await $.ajax({
                type: 'POST',
                url: `/user-prefs-SSO/update-preferences-SSO`,
                headers: { Authorization: 'Bearer ' + req.idToken },
                cache: false,
                data: JSON.stringify({ preferences: req.payload.preferences }),
                contentType: 'application/json',
                ...createAbortLink(req.signal),
                success: (data, statusText, jqXhr) => {
                    res = { ...req, data: data, status: jqXhr.status };
                },
            });
            return res;
        },
        prepare: [prepareSSOUserIdToken],
        errParser: parseJQueryAjaxError,
    },

    /*
    Workspace requests
    */
    getWorkspaces: {
        request: async (req) => {
            let res;
            await $.ajax({
                type: 'GET',
                url: `/ws/landing-pane-workspaces`,
                headers: { Authorization: 'Bearer ' + req.BCJWT },
                cache: false,
                ...createAbortLink(req.signal),
                success: (data, statusText, jqXhr) => {
                    res = { ...req, data: data, status: jqXhr.status };
                },
            });
            return res;
        },
        prepare: [prepareBCJWT],
        errParser: parseJQueryAjaxError,
    },

    getWorkspaceDetails: {
        request: async (req) => {
            let res;
            await $.ajax({
                type: 'GET',
                url: `/ws/get-workspace-details`,
                headers: { Authorization: 'Bearer ' + req.idToken },
                cache: false,
                ...createAbortLink(req.signal),
                data: `workspace_fid=${req.payload}`,
                contentType: 'application/json; charset=utf-8', // Set the content type to JSON
                success: (data, statusText, jqXhr) => {
                    res = { ...req, data: data, status: jqXhr.status };
                },
            });
            return res;
        },
        prepare: [prepareSSOUserIdToken],
        errParser: parseJQueryAjaxError,
    },

    /*
    Library requests
    */
    //Should probably be replaced by request to server + request to the graph??
    getLibrariesByWorkspaceId: {
        request: async (req) => {
            let dataRes;

            if (req.payload) {
                dataRes = await getAllDrives(
                    req.graphToken,
                    req.payload.siteId
                );
            }
            const res = { ...req, status: 200, data: dataRes };
            return res;
        },
        resParser: libraryItemsResponseParser,
        prepare: [prepareSSOGraphToken(['Sites.Read.All'])],
    },
});

export default bcServerRequests;

export const {
    getUserPreferences,
    getTeamWorkspaces,
    getWorkspaceDetails,
    getLibrariesByWorkspaceId,
    updateUserPreferences,
} = bcServerRequests.endpoints;
