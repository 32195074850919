import { newErrMsg } from 'utils/messages/messages';
import customError from '../../middle-tier/helpers/customError';
/*
Handles the conversion of errors from a particular request type to a standard error response token
________________________________________________________________________________________________________________
*/

//Generic error parser - the fall back error handling for all endpoints if no err parser specified
export const parseGenericError = async (req, error) => {
    //If already parsed into error object then just return it
    if (
        (error instanceof Object && error.hasOwnProperty('status')) ||
        error.hasOwnProperty('data') ||
        error.hasOwnProperty('error')
    ) {
        return error;
    }

    //Handle errors thrown by the fetch api
    if (error instanceof Response) {
        let body;
        try {
            body = await error.clone().json();
        } catch (e) {}
        if (!body) {
            try {
                body = await error.clone().text();
            } catch (e) {}
        }
        const headers = {};
        error.headers.forEach((value, key) => {
            headers[key] = value;
        });
        return {
            ...req,
            status: error.status,
            headers: headers,
            error: newErrMsg(
                error.statusText || body?.error?.message || '',
                body || ''
            ),
        };
    }
    //Parse Custom Errors
    if (error instanceof customError) {
        return {
            ...req,
            status: error.errorCode,
            error: newErrMsg(error.message, error.name),
        };
    }

    //Parse generic js errors
    if (error instanceof Error) {
        return {
            ...req,
            status: 400,
            error: newErrMsg(error.message, error.name),
        };
    }

    //unknown errors come under network errors: timeout, cors, lost connection
    //Attempt to filter out cors throttling errors
    if (error instanceof TypeError && error.message === 'Load failed') {
        return {
            ...req,
            status: 429,
            headers: { 'retry-after': 5 },
            error: newErrMsg('Network error', error),
        };
    }

    return {
        ...req,
        status: 400,
        error: newErrMsg('Unknown Network error', error),
    };
};

//Handle Different Request Error Parsers
export const parseJQueryAjaxError = (req, error) => {
    //error is of type jqXHR?
    //Convert the response text to JSON object if possible
    let responseData;
    try {
        responseData = JSON.parse(error.responseText);
    } catch {
        responseData = error.responseText;
    }

    return {
        ...req,
        status: error.status,
        error: newErrMsg(error.statusText || error.message, responseData),
    };
};

export const parseFetchAPIError = (req, error) => {
    if (error?.status && error?.statusText) {
        return {
            ...req,
            status: error.status,
            error: newErrMsg(error.statusText),
        };
    }
    return { ...req, status: 404, error: newErrMsg('failed to fetch data') };
};
