/*

I want to build a request layer that sits on the boundary between the frontend and backend. It will handle all requests made from 
the frontend while ensuring that they adhere to a consistent format and are sanitised.

The class should be able to take in a declarative configuration for the API endpoints, which will inform how the request should handle
the configuration.

Taking notes from the structure of redux and the flexibility of backend middleware, I want to implement the class to accept "request tokens".
These request tokens are not too dissimilar from redux actions - they are an object with a type which tells the class what configured functions to run.
The idea wil using a request token is that it will normalise how we pass data to our functions, as well as abstract the information the request needs.
For example, all requests to the server and SharePoint require an auth token; if the configuration specifies this token is needed, then the 
request token need not supply the token, the class will read the config and acquire the needed token. 

Since I will be implementing the request token structure, I think it would be best to add in some functions that generate tokens - like redux's action generators.


Spec:
The class should abstract the following away from other parts of the app:
- The exact api endpoints and request structure
- The needed tokens and authorisation
- The true request format - returned format should be standardised/sanitised across all requests
- The true error format  - error formats should be standardised/sanitised across all requests

To help with possible future developments, it should be compatible with:
- RTK Query

The class would ideally have two methods:
- configure
- makeRequest

the configure will take in an object with the following structure: 
{
    requestName:{
        request: async req => {},  //Takes in a request token an populates the response property, then returns the request token again
        tokens: ['ms_id_token', 'ms_graph_token'],
        resParser: (res) => (standardResponse), -> parses the response data into a standard form (useful for multiple dams)
        errParser: (err) => (standardError),    -> parses the error into a standard form (useful for different request standards)
    },
    ...
}

The make request method accepts a request token of the following form;
{
    type: 'requestName' //name from config
    payload: any data that the end request will need to send over,
    signal: controller signal for aborting the request
}

The class will generate request token functions like:
const {requestName} = requestLayer.endpoints, where
requestName(payload, signal) ===  {
    type: 'requestName',
    payload:payload,
    signal:signal
}


During the request process, extra properties will be stapled to the request token:
{
    id_token: ...,
    graph_token: ...,
    (any future auth tokens : ...,)
    resolve: function to resolve the global request promise
    reject : function to reject the global request promise
    data: response data
    status: the status of the returned request
    error: standard Error Msg if the request failed
}

This should be filtered out to only return specific properties if they exists:
{
    data:...
    status:..
    error:...
}

The payload and the response data both need sanitising - this should not be a configurable option by a requirement. 

NOTES:
- SSO Tokens can only be acquired once office has loaded (Office.onReady fires)
________________________________________________________________________________________________________________
*/

import { msgTypes, newMsg } from 'utils/messages/messages';
import { parseGenericError } from './parsers/error-parsers';

export class requestLayer {
    //configuration
    #apiEndpoints;
    #requestTokenFuncs;
    #requestFuncs;

    //throttling fallback
    #throttlingTimes;

    constructor() {
        this.#apiEndpoints = {};
        this.#requestTokenFuncs = {};
        this.#requestFuncs = {};
        this.#throttlingTimes = {};
    }

    /////////   Configuration   ///////////
    configure(config) {
        if (this.#validateConfig(config)) {
            this.#apiEndpoints = this.#reversePreppers(config);
            this.#generateTokenFuncs(config);
            this.#generateRequestFuncs(config);
        } else {
            throw new Error('API Configuration Not Valid');
        }
    }

    #validateConfig(config) {
        if (
            typeof config !== 'object' ||
            Object.getPrototypeOf(config) !== Object.prototype
        ) {
            console.log('Config should be object');
            return false;
        }

        for (const key in config) {
            if (!this.#validateEndpoint(key, config[key])) {
                return false;
            }
        }

        return true;
    }

    #validateEndpoint(name, endpointConfig) {
        try {
            // Validate request function
            const request = endpointConfig.request;
            if (!request || typeof request !== 'function') {
                throw 'No request function';
            }

            //Validate Prepare Functions
            const preppers = endpointConfig.prepare;
            if (preppers) {
                if (!Array.isArray(preppers)) {
                    throw 'Prepare property must be an array';
                }
                if (preppers.some((prepper) => typeof prepper !== 'function')) {
                    throw 'Prepare property must be an array of functions';
                }
            }
            return true;
        } catch (reason) {
            if (typeof reason === 'string') {
                console.log(
                    'Invalid Endpoint Config: ' + name + ' : ' + reason
                );
            }
            return false;
        }
    }

    //Array.reverse acts in place (mutates array). Should only reverse array for prepare function construction once on load.
    #reversePreppers(config) {
        for (const key in config) {
            const endpointConfig = config[key];
            if (
                !endpointConfig.prepare ||
                !Array.isArray(endpointConfig.prepare)
            ) {
                continue;
            }
            endpointConfig.prepare.reverse();
        }
        return config;
    }

    #generateTokenFuncs(config) {
        for (const name in config) {
            this.#requestTokenFuncs[name] = (
                payload = undefined,
                signal = undefined
            ) => ({
                type: name,
                payload: payload,
                signal: signal,
            });
        }
    }

    #generateRequestFuncs(config) {
        for (const name in config) {
            this.#requestFuncs[name] = async (
                payload = undefined,
                signal = undefined
            ) => {
                return this.sendRequest(
                    this.#requestTokenFuncs[name](payload, signal)
                );
            };
        }
    }

    //Token/request generators
    get endpoints() {
        return this.#requestFuncs;
    }

    get endpointTokens() {
        return this.#requestTokenFuncs;
    }

    //////     Requesting     ///////
    async sendRequest(req) {
        // get request config
        const reqConfig = this.#apiEndpoints[req.type];
        let res;

        //Validate request token

        //Check if config exists
        if (!reqConfig) {
            res = {
                error: newMsg(msgTypes.err, 'No API Configuration Found '),
            };
            throw res;
        }

        //Validate Configuration
        if (!this.#validateEndpoint(req.type, reqConfig)) {
            res = {
                error: newMsg(msgTypes.err, 'No API Configuration Found '),
            };
            throw res;
        }

        //Call the configured endpoint - errors thrown
        try {
            res = await this.#prepareAndCallAPI(req);

            if (!res) {
                throw {
                    ...res,
                    error: newMsg(
                        msgTypes.internalErr,
                        'Response returned null'
                    ),
                };
            }
            //format returned data
            res = {
                error: res?.error,
                data: res?.data,
                status: res?.status,
            };
            return res;
        } catch (e) {
            //Check if error is matching response token
            if (
                (e instanceof Object && e.hasOwnProperty('status')) ||
                e.hasOwnProperty('data') ||
                e.hasOwnProperty('error')
            ) {
                //format known error
                res = { error: e.error, data: e.data, status: e.status };
            } else {
                //Unknown error
                res = {
                    error: newMsg(msgTypes.internalErr, 'Failed to call API'),
                    data: e,
                };
            }
            throw res;
        }
    }

    //////    Generating API Prep + Call Function   ////

    async #prepareAndCallAPI(req) {
        const config = this.#apiEndpoints[req.type];

        let preppers = config.prepare || [];
        let res;

        if (!Array.isArray(preppers) || preppers.length === 0) {
            res = await this.#callAPI(req);
            return res;
        }

        const that = this;

        //Build Prepare and call function - then execute
        res = await preppers.reduce(
            (prev, curr) => curr(that)(prev),
            this.#callAPI.bind(this)
        )(req);

        //errors handled by error parser + sendRequest
        return res;
    }

    ///////    Calling API + Uniforming Response    //////
    async #callAPI(req) {
        const apiConfig = this.#apiEndpoints[req.type];

        let res;

        try {
            //Send off for the request
            res = await apiConfig.request(req);
        } catch (e) {
            //process the request error
            if (apiConfig.errParser) {
                res = await apiConfig.errParser(req, e);
            } else {
                res = await parseGenericError(req, e);
            }
            throw res;
        }

        //normalise the response data
        if (res.data !== undefined && apiConfig.resParser) {
            res = await apiConfig.resParser(res);
        }

        return res;
    }

    /*
    Throttling handler
    */

    getThrottlingTime(throttleKey) {
        return this.#throttlingTimes[throttleKey] || 0;
    }
    setThrottlingTime(throttleKey, time) {
        this.#throttlingTimes[throttleKey] = time;
    }
}
