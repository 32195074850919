import React from 'react';
import SettingsMenuTitle from 'settings/components/SettingsMenuTitle';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';
import { useHeaderState } from 'headerFooter/hooks/headerFooterStateHook';
import ItemList, { horizontalSpacingSizes } from 'global/components/ItemList';
import TeamCard from 'home/components/TeamCard';
import { useSelector } from 'react-redux';
import { selectTeams } from 'reduxStore/slices/teamSettingsSlice';

/*
Renders a list of teams available to the user
________________________________________________________________________________________________________________

Custom Components:

SettingsMenuTitle -> General page title/body display
ItemList -> Generic flex list display component
TeamCard -> Basic Name/Icon display for navigating
________________________________________________________________________________________________________________

IMPORTANT NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/
const TeamsPage = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    useHeaderState([
        {
            type: 'disableButton',
            button: 'dismiss',
            enabled: searchParams.get('overlay') === 'true',
        },
    ]);
    const teams = useSelector(selectTeams);
    const navigate = useNavigate();

    return (
        <>
            <SettingsMenuTitle title={t('teamsPage.title')} />
            <ItemList paddingSize={horizontalSpacingSizes.large}>
                {Object.values(teams.byId).map((team) => (
                    <TeamCard
                        key={team.id}
                        name={team.name}
                        iconURL={team.iconURL}
                        onClick={() => navigate(`/teams/${team.id}`)}
                    />
                ))}
            </ItemList>
        </>
    );
};

export default TeamsPage;
