/* 
This file contains an attempt to generalise different messages that may need to be sent across the app

Messages crop up in a few places throughout the app:
    - In the global message bar at the top of the app
    - In the badges in the settings menu, detailing how settings below are configured
    - In validation when informing the user how their input should be changed.

The general gist of these messages is that they contain really basic information about how the app renders
or responds to events else where. 
React enforces data flow from parent to child, to enable communication from child to parent, the child must be able to change the parent state.
What messages do, is allow the child a structured way to communicate with the parent, and the parent can decide how to respond. 

This is not to say they should be used in all cases of child -> parent communication, but could find use in more flexible 
or far reaching communications

This file may completely change or get ripped out going forward, but its still useful to set up ANY communication standard
*/

// Function to generate a basic message
/*
    type - tag to indicate how the receiver should process the message e.g. error, warning, success, information
    text - string, often used for UI rendering associated with the message
    payload - for more complex messages, a payload of any type can be supplied. SHOULD BE SERIALISABLE 
    id - Auto generated ID, can be used when rendering lists of messages to ensure react can track the components
*/
export const newMsg = (type, text = '', payload = null) => {
    return {
        type: type,
        text: text,
        payload: payload,
        id: Math.random().toString(16).slice(2),
    };
};

export const msgTypes = {
    err: 'error',
    warn: 'warning',
    succ: 'success',
    info: 'information',
    internalErr: 'internalError',
};

// Simple exports to standardise the error string
export const newErrMsg = (text = '', payload = null) =>
    newMsg(msgTypes.err, text, payload);

export const newWarnMsg = (text = '', payload = null) =>
    newMsg(msgTypes.warn, text, payload);

export const newSuccMsg = (text = '', payload = null) =>
    newMsg(msgTypes.succ, text, payload);

//Message Filters

//Takes all the error messages and reduces them to a single error count msg
// Passing the translation object to the filter as the language config is async => can't translate when initially declaring the function
export const errCountFilter = (t) => (msgs) => {
    const filteredMsgs = msgs.reduce(
        (acc, item) => {
            if (item.type === msgTypes.err) {
                acc.errCount++;
            } else {
                acc.msgs.push(item);
            }
            return acc;
        },
        {
            msgs: [],
            errCount: 0,
        }
    );

    const newMsgs = filteredMsgs.msgs;

    if (filteredMsgs.errCount !== 0) {
        newMsgs.push(
            newErrMsg(t('badges.error', { count: filteredMsgs.errCount }))
        );
    }

    return newMsgs;
};

export const warnCountFilter = (t) => (msgs) => {
    const filteredMsgs = msgs.reduce(
        (acc, item) => {
            if (item.type === msgTypes.warn) {
                acc.warnCount++;
            } else {
                acc.msgs.push(item);
            }
            return acc;
        },
        {
            msgs: [],
            warnCount: 0,
        }
    );

    const newMsgs = filteredMsgs.msgs;

    if (filteredMsgs.warnCount !== 0) {
        newMsgs.push(
            newWarnMsg(t('badges.warning', { count: filteredMsgs.warnCount }))
        );
    }

    return newMsgs;
};
