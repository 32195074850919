import React from 'react';
import { Badge, Caption2Strong } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { useHeaderFooterStyles } from 'headerFooter/hooks/headerFooterStylesHook';

/*
Renders the header admin badge used to indicate user roles
________________________________________________________________________________________________________________

Props:

adminState -> object {visible:bool} used to indicate how the badge renders
titleText -> string, if not empty the badge does not render to provide more room for title text in header
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const HeaderAdminBadge = ({ adminState, titleText }) => {
    const styles = useHeaderFooterStyles();
    const { t } = useTranslation();

    //Return nothing if no visible or there exists header text
    if (!adminState.visible || titleText !== '') return;

    return (
        <Badge className={styles.minContent}>
            <Caption2Strong>{t('common.admin')}</Caption2Strong>
        </Badge>
    );
};

export default HeaderAdminBadge;
