/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSwitches from '../components/LanguageSwitch';
import HeaderFooterLayout from 'headerFooter/components/HeaderFooterLayout';

// Just showing off all the different Fluent UI components
const DevTestPage = () => {
    //const [workspaces, setWorkspaces] = useState([]);
    const { t } = useTranslation();

    const languages = ['en-US', 'de', 'ar', 'bg', 'zh'];
    const labels = ['English', 'German', 'Arabic', 'Bulgarian', 'Mandarin'];
    const defaultLanguage = 'en-US';

    return (
        <HeaderFooterLayout location="firstRun">
            <div>
                <h3>Select a language:</h3>
                <LanguageSwitches
                    languages={languages}
                    labels={labels}
                    defaultLanguage={defaultLanguage}
                />
            </div>
        </HeaderFooterLayout>
    );
};
export default DevTestPage;
