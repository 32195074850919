import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import HomeListItem from 'home/components/HomeListItem';
import ItemList, {
    horizontalSpacingSizes,
    verticalSpacingSizes,
} from 'global/components/ItemList';
import { Tab, TabList } from '@fluentui/react-components';
import HomeWorkspaceTitle from 'home/components/HomeWorkspaceTitle';
import HomeLibraryList from 'home/components/HomeLibraryList';
import { useHomeTabStyles } from 'home/hooks/homeStylesHook';
import { useTranslation } from 'react-i18next';
import { useHeaderState } from 'headerFooter/hooks/headerFooterStateHook';

/*
Description 
This page displays the libraries and features, separated by a tab, of a particular workspace. 
It is a navigation aid, collating all the available resources relating to a workspace. 
________________________________________________________________________________________________________________

Custom Components:

ItemList -> renders children in a flex list, can specify the row gap and side padding
HomeLibraryListItem -> displays a library choice, used to navigate to asset nav page
HomeListItem -> renders a single list item element used for libraries and new file button
HomeLibraryList -> renders a list of libraries for a given workspace id

________________________________________________________________________________________________________________

IMPORTANT NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/
const WorkspaceHomePage = () => {
    const { workspaceId } = useParams();
    const tabStyles = useHomeTabStyles();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useHeaderState([
        {
            type: 'disableButton',
            button: 'dismiss',
            enabled: searchParams.get('overlay') === 'true',
        },
    ]);

    return (
        <ItemList
            rowGapSize={verticalSpacingSizes.extraLarge}
            paddingSize={horizontalSpacingSizes.small}
        >
            <HomeWorkspaceTitle workspaceId={workspaceId} />
            <ItemList
                rowGapSize={verticalSpacingSizes.medium}
                paddingSize={horizontalSpacingSizes.none}
            >
                <HomeListItem
                    iconType="newFile"
                    text={t('workspaceHomePage.buttons.newPresentation')}
                    onClick={() => navigate('./new')}
                />
                <TabList
                    className={tabStyles.tabList}
                    defaultSelectedValue={'assets'}
                >
                    <Tab id="assets" value="assets">
                        {t('workspaceHomePage.tab.assets')}
                    </Tab>
                    <Tab id="features" value="features" disabled={true}>
                        {t('workspaceHomePage.tab.features')}
                    </Tab>
                </TabList>
            </ItemList>
            <HomeLibraryList workspaceId={workspaceId} />
        </ItemList>
    );
};

export default WorkspaceHomePage;
