import React from 'react';
import { toggleDarkModeThunk } from 'reduxStore/slices/userSettingsSlice';
import { useTranslation } from 'react-i18next';
import SettingsMenuTitle from 'settings/components/SettingsMenuTitle';
import ItemList from 'global/components/ItemList';
import SettingsFeatureCard from 'settings/components/SettingsFeatureCard';

/*
This is the first page the user lands on when they navigate to the settings portion of the app.

It contains user preferences and a link to the admin panel.
*/
const SettingsHomePage = () => {
    const { t } = useTranslation();

    return (
        <>
            <SettingsMenuTitle title={t('common.settings')} />
            <ItemList>
                <SettingsFeatureCard
                    title={t('settingsHomePage.darkModeCard.header')}
                    body={t('settingsHomePage.darkModeCard.body')}
                    selector={(state) => state.userSettings.darkMode}
                    action={toggleDarkModeThunk}
                />
            </ItemList>
        </>
    );
};

export default SettingsHomePage;
