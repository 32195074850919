//partition function with some funky casting and comma operators
// Splits an array into two arrays, based on the boolean return of the check function.
// usage ->
// array = [1,2,3,4]
// [even, odd] = partition(array, (num) => num % 2 === 0)
export const partition = (array, check) => {
    return array.reduce(
        (acc, item) => (acc[+!check(item)].push(item), acc),
        [[], []]
    );
};
