// Home Routes
import React from 'react';
import GlobalLayout from 'headerFooter/pages/GlobalLayout';
import ErrorElement from 'global/pages/GeneralErrorPage';
import WorkspacesPage from 'home/pages/WorkspacesPage';
import WorkspaceHomePage from 'home/pages/WorkspaceHomePage';
import WorkspaceNewFilePage from 'home/pages/WorkspaceNewFilePage';
import store from 'reduxStore/store';
import TeamsPage from 'home/pages/TeamsPage';
import { json, redirect } from 'react-router-dom';
import { requestAndCacheData } from 'global/routes/cacheLoaderHelpers';

/*
Description 
These Routes handle the core of the app - workspace/library select + new Files
________________________________________________________________________________________________________________

Navigated Pages:

WorkspacesPage -> /workspaces
WorkspaceHomePage -> /workspaces/:workspaceId e.g. /workspaces/124123
WorkspaceNewFile -> /workspaces/:workspaceId/new e.g /workspaces/124123/new
_______________________________________________________________________________________________________________
*/

export const homeRoutes = {
    element: <GlobalLayout />,
    children: [
        {
            //Error element to render below the global layout if possible
            errorElement: <ErrorElement />,
            children: [
                {
                    path: '/teams',
                    element: <TeamsPage />,
                    loader: async () => {
                        await requestAndCacheData(store, null, true);
                        const teamIds =
                            store.getState().teamSettings.entities.teams.allIds;
                        if (teamIds.length === 1) {
                            return redirect(`/teams/${teamIds[0]}`);
                        }
                        return null;
                    },
                },
                {
                    path: '/teams/:teamId',
                    element: <WorkspacesPage />,
                    loader: async ({ params }) => {
                        await requestAndCacheData(store, params, true, true);
                        //redirect based on number of workspaces
                        const workspaceIds =
                            store.getState().teamSettings.entities.workspaces
                                .allIds;
                        if (workspaceIds.length === 1) {
                            return redirect(
                                `/teams/${params.teamId}/${workspaceIds[0]}`
                            );
                        }
                        return null;
                    },
                },
                {
                    path: '/teams/:teamId/:workspaceId',
                    element: <WorkspaceHomePage />,
                    loader: async ({ params }) => {
                        await requestAndCacheData(
                            store,
                            params,
                            true,
                            true,
                            true
                        );
                        return null;
                    },
                },
                {
                    path: '/teams/:teamId/:workspaceId/new',
                    element: <WorkspaceNewFilePage />,
                    loader: async ({ params }) => {
                        //Fetch cache templates for a single workspace
                        await requestAndCacheData(store, params, false, true);

                        return null;
                    },
                },
            ],
        },
    ],
};
