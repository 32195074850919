/*
A hook which returns a boolean react state indicating if the users mouse is hovering over the taskpane
________________________________________________________________________________________________________________
*/

import { useEffect, useState } from 'react';

export const useWindowHover = () => {
    const [isMouseOver, setIsMouseOver] = useState(false);
    useEffect(() => {
        const rootEl = document.getElementById('root');
        const handleMouseOver = (e) => {
            setIsMouseOver(true);
        };
        const handleMouseOut = (e) => {
            setIsMouseOver(false);
        };
        rootEl.addEventListener('mouseover', handleMouseOver);
        rootEl.addEventListener('mouseout', handleMouseOut);

        return () => {
            rootEl.removeEventListener('mouseover', handleMouseOver);
            rootEl.removeEventListener('mouseout', handleMouseOut);
        };
    }, []);

    return isMouseOver;
};
