//User Id Prep Functions
import accountManager from '../../naa/accountManager';
import { msgTypes, newErrMsg, newMsg } from 'utils/messages/messages.js';

/*
Request Layer prepare functions that staple tokens for the users MS SSO account
-Id token: specified the individual - used to sign into BC server
-GraphToken: used to authenticate use for graph requests.
________________________________________________________________________________________________________________
*/

export const prepareSSOUserIdToken = (apiLayer) => (next) => {
    const idTokenLayer =
        (attemptCount = 0) =>
        async (req) => {
            let res;

            // This gets the SSO token - either by popping an SSO box on first use, or by scarping from office 'session'
            // gets replaced by dev token for staging
            let idToken;

            try {
                //Attempt to get SSO token
                try {
                    idToken = await accountManager.getIdToken(
                        ['Sites.Read.All'],
                        attemptCount === 1,
                        req.payload?.silentOnly ?? false
                    );
                } catch (e) {
                    //failed to get sso token
                    throw {
                        ...req,
                        status: 401,
                        error: newErrMsg('Failed to get user ID token'),
                    };
                }

                //Staple ID token to request token
                req = { ...req, idToken: idToken };

                //Send to next prepper
                res = await next(req);
            } catch (e) {
                //Check if the error is from an expired token
                if (
                    e.status === 401 &&
                    e.error.payload === 'Token Expired' &&
                    attemptCount === 0
                ) {
                    res = await idTokenLayer(1)(req);
                } else {
                    throw e;
                }
            }

            return res;
        };
    return idTokenLayer();
};

export const prepareSSOGraphToken =
    (claims = ['User.Read']) =>
    (apiLayer) =>
    (next) =>
    async (req) => {
        let res;
        try {
            // This gets the SSO token - either by popping an SSO box on first use, or by scarping from office 'session'
            // gets replaced by dev token for staging
            let graphToken;

            //Attempt to get SSO token
            try {
                graphToken = await accountManager.getGraphToken(claims);
            } catch (e) {
                //failed to get sso token
                throw {
                    ...req,
                    status: 401,
                    error: newErrMsg('Failed to get user graph token'),
                };
            }

            //Staple ID token to request token
            req = { ...req, graphToken: graphToken };

            //Send to next prepper
            res = await next(req);

            return res;
        } catch (e) {
            throw e;
        }
    };
