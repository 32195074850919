import React, { createContext, useContext, useEffect, useState } from 'react';
import accountManager from './accountManager';

const AccountManagerContext = createContext(null);

// Creating a provider component for AccountManagerContext
export const AccountManagerProvider = ({ children }) => {
    const [initialized, setInitialized] = useState(false);

    // Using useEffect to perform initialization after the component mounts
    useEffect(() => {
        const initializeAccountManager = async () => {
            await accountManager.initialize();
            setInitialized(true); // Updating the state to indicate initialization is complete
        };

        initializeAccountManager();
    }, []); // Empty dependency array ensures this runs only once after the initial render

    // If the account manager is not initialized, display a loading message
    if (!initialized) {
        return <div>Loading...</div>;
    }

    // Once initialized, provide the account manager to the context and render children components
    return (
        <AccountManagerContext.Provider value={accountManager}>
            {children}
        </AccountManagerContext.Provider>
    );
};

// Custom hook to access the AccountManagerContext
export const useAccountManager = () => useContext(AccountManagerContext);
