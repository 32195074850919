import { makeStyles, tokens, shorthands } from '@fluentui/react-components';

/*
Used for styling both the header/footer containers as well as the buttons within them
________________________________________________________________________________________________________________

Components Styled Here

AppFooter -> useHeaderFooterStyles -> used for container position and splitting buttons between left and right
    as well as elevate the footer above when content can scroll
AppHeader -> useHeaderFooterStyles -> used for container position and splitting buttons between left and right
    as well as adjust to the presence of the personality menu
FooterHelpMenu -> useHeaderFooterStyles -> navBar button styling
FooterSettingsButton -> useHeaderFooterStyles -> navBar button styling
HeaderAdminBadge -> useHeaderFooterStyles -> text width styling
HeaderBackNavButton -> useHeaderFooterStyles -> navBar button styling
HeaderDismissButton -> useHeaderFooterStyles -> navBar button styling
HeaderNavMenu -> useHeaderFooterStyles -> navBar button styling
HeaderSearchComponent -> useHeaderFooterStyles -> navBar button styling
________________________________________________________________________________________________________________
*/

const useHeaderFooterStyles = makeStyles({
    navBar: {
        ...shorthands.padding('8px'),
        height: '32px',
        zIndex: 1000,
    },

    topNavBar: {
        left: '0px',
        right: '0px',
        top: '0px',
        position: 'fixed',
        backgroundColor: tokens.colorNeutralBackground2,
    },

    topNavBarPersonalityMacNoScroll: {
        paddingRight: '42px',
    },
    topNavBarPersonalityMacScroll: {
        paddingRight: '27px',
    },
    topNavBarPersonalityWinNoScroll: {
        paddingRight: '24px',
    },
    topNavBarPersonalityWinScroll: {
        paddingRight: '8px',
    },
    bottomNavBar: {
        bottom: '0px',
        left: '0px',
        right: '0px',
        position: 'fixed',
        backgroundColor: 'subtle',
        pointerEvents:'none',
    },

    //Top and Bottom Global Navigation
    navButton: {
        height: '32px',
        width: '32px',
        pointerEvents:'all',
    },

    //Flex display -> used for dynamic content
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
    },

    flexRowEnd: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        justifyItems: 'flex-end',
        justifyContent: 'flex-end',
    },

    //Used for flex in the highest div in header/footer
    flexParent: {
        justifyContent: 'space-between',
        columnGap: '8px',
    },

    width100: { width: '100%' },

    colGap2: { columnGap: '2px' },
    colGap4: { columnGap: '4px' },
    padding4: { padding: '4px' },

    footerElevate: {
        backgroundColor: tokens.colorNeutralBackground2,
        ...shorthands.borderRadius('4px'),
        boxShadow: tokens.shadow8,
    },

    footerElevateTrans: {
        transitionProperty: 'all',
        transitionDuration: '500ms',
    },

    //For text rendered in the middle of the header
    middleText: {
        textOverflow: 'ellipsis',
        flexGrow: '1',
    },

    //Helps fix bug where badge width calculated incorrectly for flex display
    minContent: { width: 'min-content' },

    //Divider stylings for header
    headerDivider: {
        position: 'fixed',
        top: '48px',
        marginTop: '0px',
        marginBottom: '0px',
        zIndex: '10000', //Weird bug where fluent UI cards will display above the divider when scrolling?
    },

    searchBox: {
        flexShrink: 1,
        flexGrow: 1,
        width: '100%',
        maxWidth: '1000px',
        minWidth: 0,
    },
    searchCancel: {
        width: 'max-content',
        minWidth: 0,
    },
    menuItem: {
        textDecoration: 'none',
    },
});

export { useHeaderFooterStyles };
