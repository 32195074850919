import { newErrMsg } from 'utils/messages/messages';

/*
Checks if the successful returned data is:
a) A string and b) svg xml markup
This may not be the best place for the checks?
They could be implemented with the validation functions in the component
but for now will keep them here for ease of use.
It could be possible to sanitize the SVG here, but DOMPurify docs say to be wary of
editing the xml after sanitizing.
NOTE: Move this to a response parser
________________________________________________________________________________________________________________
*/
export const prepareSVGDownload = (apiLayer) => (next) => async (req) => {
    //Don't wrap in try catch => only process successful requests
    const res = await next(req);

    //Throw error if response data is not a string
    if (typeof res.data !== 'string') {
        throw {
            ...res,
            error: newErrMsg('Source image is not an SVG'),
        };
    }

    //Attempt to parse response data as an SVG
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(res.data, 'image/svg+xml');
    const errorNode = svgDoc.querySelector('parsererror');
    if (errorNode) {
        throw {
            ...res,
            error: newErrMsg('Source image is not an SVG'),
        };
    }
    return res;
};
