import { Toaster, useId } from '@fluentui/react-components';
import React from 'react';
const { createContext } = require('react');

/*
Adds a toaster component at the top level - acts as a generic message centre
it provides the toaster Id so that the useToasterDispatch() can send it to the right place.
________________________________________________________________________________________________________________
*/

export const GlobalToasterId = createContext();

export const ToasterProvider = ({ children }) => {
    const toasterId = useId('toaster');
    return (
        <>
            <Toaster toasterId={toasterId} />
            <GlobalToasterId.Provider value={toasterId}>
                {children}
            </GlobalToasterId.Provider>
        </>
    );
};
