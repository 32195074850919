import { makeStyles, tokens, shorthands } from '@fluentui/react-components';

const useSettingMenuStyles = makeStyles({
    menuTitleWrapper: {
        marginTop: '20px',
        marginBottom: '20px',
        paddingTop: '9px',
        paddingBottom: '10px',
        paddingLeft: '16px',
        paddingRight: '16px',
    },
    displayBlock: { display: 'block' },
    middleMargin: { marginTop: '8px' },

    //replaces the active (mouse down) state of the menu item card so that it is the same as hover
    itemCardActiveDefault: {
        ':active': {
            backgroundColor: tokens.colorNeutralBackground1Hover,
            boxShadow: tokens.shadow8,
        },
    },
    itemCardActiveReplace: {
        ':active': {
            backgroundColor: tokens.colorNeutralBackground1Pressed,
            boxShadow: tokens.shadow8,
        },
    },

    toggleNoMargin: {
        ...shorthands.margin('-8px'),
    },
    toggleExpandFlex: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '2px',
    },

    justifyItems: {
        justifyContent: 'space-between',
    },

    rowFlexCenter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    workspaceIconName: {
        float: 'left',
        columnGap: '8px',
    },

    workspacePinMenu: {
        float: 'right',
        columnGap: '4px',
    },

    libraryFlex: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '8px',
        justifyContent: 'space-between',
    },
    libraryName: {
        flexGrow: 1,
    },
});

export { useSettingMenuStyles };
