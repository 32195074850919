import { Divider, Subtitle2, mergeClasses } from '@fluentui/react-components';
import { useHeaderFooterStyles } from 'headerFooter/hooks/headerFooterStylesHook';
import React, { useMemo } from 'react';

import { useWindowScrollBarVisible } from 'global/hooks/windowFixedScrollHook';
import { useWindowFocus } from 'global/hooks/windowFocusHook';
import { useWindowHover } from 'global/hooks/windowHoverHook';
import { useHeaderState } from 'headerFooter/hooks/headerFooterStateHook';
import HeaderAdminBadge from './HeaderAdminBadge';
import HeaderAvatar from './HeaderAvatar';
import HeaderBackNavButton from './HeaderBackNavButton';
import HeaderDismissButton from './HeaderDismissButton';
import HeaderNavMenu from './HeaderNavMenu';
import HeaderSearchComponent from './HeaderSearchComponent';

/*
Component to render the fixed app header
________________________________________________________________________________________________________________

Props:

________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Fixed position
        The app is rendered in a fixed position, if we want to animate in, we may need to render it relative
    -> Header context
        The header must render as a child of the header context provider to access header state (useHeaderState)
________________________________________________________________________________________________________________
*/

const AppHeader = () => {
    const styles = useHeaderFooterStyles();
    const [state, dispatch] = useHeaderState();

    //Header should adjust right padding if the personality menu takes up space, less so if
    // the scroll bar is fixed and causes the body to indent
    //This only happens on:
    // - Mac - Desktop - Window event(onFocus or onHover)

    const windowHover = useWindowHover();
    const windowFocus = useWindowFocus();
    const windowScrollbar = useWindowScrollBarVisible();
    const isMac = useMemo(
        () => navigator.userAgent.toLowerCase().indexOf('mac') !== -1,
        []
    );
    const isIframe = useMemo(inIframe, []);

    if (!state.visible) return;
    return (
        <>
            <div
                className={mergeClasses(
                    styles.navBar,
                    styles.topNavBar,
                    styles.flexRow,
                    styles.flexParent,
                    !isIframe &&
                        (windowScrollbar
                            ? isMac && styles.topNavBarPersonalityMacScroll
                            : isMac
                            ? styles.topNavBarPersonalityMacNoScroll
                            : styles.topNavBarPersonalityWinNoScroll)
                )}
            >
                {(state.dismiss.visible ||
                    state.backNav.visible ||
                    state.navMenu.visible) && (
                    <div
                        className={mergeClasses(styles.flexRow, styles.colGap2)}
                    >
                        <HeaderDismissButton dismissState={state.dismiss} />
                        <HeaderNavMenu navMenuState={state.navMenu} />
                        <HeaderBackNavButton backNavState={state.backNav} />
                    </div>
                )}
                {state.titleText && !state.search.open && (
                    <Subtitle2 wrap={false} className={styles.middleText}>
                        {state.titleText}
                    </Subtitle2>
                )}
                {(state.admin.visible || state.avatar.visible) && (
                    <div
                        className={mergeClasses(
                            styles.flexRowEnd,
                            styles.colGap4
                        )}
                    >
                        <HeaderAdminBadge
                            titleText={state.titleText}
                            adminState={state.admin}
                        />
                        <HeaderAvatar avatarState={state.avatar} />
                    </div>
                )}
                <HeaderSearchComponent
                    searchState={state.search}
                    dispatch={dispatch}
                />
            </div>
            {state.titleText && <Divider className={styles.headerDivider} />}
        </>
    );
};

//This needs thorough testing - apparently cors blocks access to either self or top if cross-origin iframe
//, hence error handler.
const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

export default AppHeader;
