import React from 'react';
import ItemList, { horizontalSpacingSizes } from 'global/components/ItemList';
import { Divider } from '@fluentui/react-components';
import WorkspaceCard from 'home/components/WorkspaceCard';
import {
    useSettingsCache,
    settingCacheConfig,
} from 'settings/hooks/reduxSettingsHooks';
import { partition } from 'utils/arrays/arrayMethods';
import { selectWorkspaces } from 'reduxStore/slices/teamSettingsSlice';
import { useSelector } from 'react-redux';

/*
renders list of all workspaces, splitting them up depending on their pinned status
________________________________________________________________________________________________________________

Props:

Admin -> bool to indicate if the list is in the admin settings
noSidePadding -> bool to indicate if the ItemList wrapper component has no side padding
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const WorkspaceList = ({ admin = false, noSidePadding = false }) => {
    const workspaces = useSelector(selectWorkspaces);
    const workspacesArr = Object.values(workspaces?.byId || {});
    const [pinned, notPinned] = partition(
        workspacesArr,
        (item) => item.pinned.value
    );
    pinned.sort((item1, item2) =>
        item1.name.value < item2.name.value ? -1 : 1
    );
    notPinned.sort((item1, item2) =>
        item1.name.value < item2.name.value ? -1 : 1
    );

    return (
        <ItemList
            paddingSize={
                noSidePadding
                    ? horizontalSpacingSizes.none
                    : horizontalSpacingSizes.large
            }
        >
            {pinned.map((workspace) => (
                <WorkspaceCard
                    key={workspace.id}
                    workspaceId={workspace.id}
                    admin={admin}
                />
            ))}
            {pinned.length > 0 && <Divider />}
            {notPinned.map((workspace) => (
                <WorkspaceCard
                    key={workspace.id}
                    workspaceId={workspace.id}
                    admin={admin}
                />
            ))}
        </ItemList>
    );
};

export default WorkspaceList;
