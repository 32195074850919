import { Diamond20Regular } from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import { useHeaderFooterStyles } from 'headerFooter/hooks/headerFooterStylesHook';
import { Button, mergeClasses } from '@fluentui/react-components';
import { Link } from 'react-router-dom';
import FooterSettingsButton from './FooterSettingsButton';
import FooterHelpMenu from './FooterHelpMenu';
import { useFooterState } from 'headerFooter/hooks/headerFooterStateHook';
import FooterPolicyButtons from './FooterPolicyButtons';
import FooterProgressBar from './FooterProgressBar';
import FooterBCButton from './FooterBCButton';

/*
Component that renders the app footer at a fixed position at the bottom of the screen
________________________________________________________________________________________________________________

Props:

________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Fix Position
        Currently, the footer renders in at a fixed position - if we want to animate it in, it may need to update
        to relative
    -> Footer Context
        Needs to be rendered as the child of the footer context provider to access the footer state (useFooterState)
________________________________________________________________________________________________________________
*/

const AppFooter = () => {
    const styles = useHeaderFooterStyles();
    const [contentBelow, setContentBelow] = useState(false);
    const [state, _] = useFooterState();

    // Effect to add an interval to check when the content scrolls below the footer
    // NOTE: I had to use a polling method as I couldn't find any events to latch on to for consistent scroll checks
    // Need to check how this works along side the bridge
    // NOTE: This needs testing in an iFrame - can we reference the document/body the same in an iframe as in a webview??
    useEffect(() => {
        //If scroll height is greater than client height => overflow of content
        const checkScrollState = () => {
            const root =
                document.compatMode == 'BackCompat' //not too sure why there is a check for backwards compatibility?
                    ? document.body
                    : document.documentElement;
            setContentBelow(root.scrollHeight > root.clientHeight);
        };
        const intervalId = setInterval(checkScrollState, 250);

        //Return interval clearer for component unmounting
        return () => clearInterval(intervalId);
    }, []);

    if (!state.visible) return;

    return (
        <div
            className={mergeClasses(
                styles.navBar,
                styles.bottomNavBar,
                styles.flexRow,
                styles.flexParent
            )}
        >
            {state.progress.visible ? (
                <FooterProgressBar progressState={state.progress} />
            ) : (
                <>
                    <div
                        className={mergeClasses(
                            styles.flexRow,
                            styles.colGap4,
                            styles.footerElevateTrans,
                            contentBelow && styles.footerElevate
                        )}
                    >
                        <FooterBCButton />
                    </div>
                    <div
                        className={mergeClasses(
                            styles.flexRow,
                            styles.footerElevateTrans,
                            contentBelow && styles.footerElevate,
                            state.policy.visible && styles.padding4
                        )}
                    >
                        <FooterPolicyButtons policyState={state.policy} />
                        <FooterHelpMenu helpMenuState={state.help} />
                        <FooterSettingsButton settingsState={state.settings} />
                    </div>
                </>
            )}
        </div>
    );
};

export default AppFooter;
