// TaskPaneBody.js
import React from 'react';
import { Body1 } from '@fluentui/react-components';
import { useEmptyStateStyles } from 'home/hooks/emptyStateStylesHook';

/*
The EmptyStateBody component manages the content and styling of the body text inside EmptyState pages.
________________________________________________________________________________________________________________

Props: 
content -> The text needed for the specified empty state page. 
________________________________________________________________________________________________________________
*/

const EmptyStateBody = ({ content }) => {
    const classes = useEmptyStateStyles();
    return <Body1 className={classes.emptyStateText}>{content}</Body1>;
};

export default EmptyStateBody;
