import React, { createContext, useReducer } from 'react';

export const FooterStateContext = createContext();
export const FooterStateDispatchContext = createContext();

/*
Context to provide and control if the footer is visible, and the state of the buttons shown in the footer. 
Uses context instead of redux to provide the state so we can have multiple instances with different states
for animations. 

The context has 3 components
1. The footer state - object literal to contain all the footer state data 
    - see generalDefaultFooterState for structure
2. The reducer - function that controls how the state is updated.
    - The reducer take the current state and an object called action as arguments.
    - An action is an object with property "type", all other properties are dependant on the type
    - The reducer takes the current state and action, and returns a new state, non mutably.
3. Dispatch function - used to send actions to the reducer. 

Note: the default state depends on the location the header footer is configured to rendered in.
The location based defaults are set up in two parts:
1. The base default state - has the entire default state structure
2. The location differences - indexed by location, it contains what properties of the default state is different
    that location. 
    e.g. The default state has the visible property set to true - i.e the footer renders
         The asset navigation pages don't have the footer visible.
         Configure assetNav:{visible:false}
The default for a given location is given by Object.assign(default, locationDiff)
________________________________________________________________________________________________________________
*/

const generalDefaultFooterState = () => ({
    visible: true,
    settings: { visible: true, enabled: true },
    help: {
        visible: true,
        enabled: true,
    },
    policy: {
        visible: false,
    },
    progress: {
        visible: false,
        value: 0,
        max: 0,
    },
});

const defaultDifferences = {
    home: {},
    assetNav: {
        visible: false,
    },
    settings: {
        visible: false,
    },
    login: {
        policy: {
            visible: true,
        },
        settings: { visible: false, enabled: true },
        help: {
            visible: false,
            enabled: true,
        },
    },
    firstRun: {
        settings: { visible: false, enabled: true },
        help: {
            visible: false,
            enabled: true,
        },
        progress: {
            visible: true,
            value: 0,
            max: 4,
        },
    },
    error: {
        settings: { visible: false, enabled: true },
    },
};

const FooterStateProvider = ({ children, location = 'home' }) => {
    //Is a reducer created at every render if I generate it with the default state?
    const defaultState = Object.assign(
        {},
        generalDefaultFooterState(),
        defaultDifferences[location] || {}
    );

    const [state, dispatch] = useReducer(
        footerStateReducer(defaultState),
        defaultState
    );

    return (
        <FooterStateContext.Provider value={state}>
            <FooterStateDispatchContext.Provider value={dispatch}>
                {children}
            </FooterStateDispatchContext.Provider>
        </FooterStateContext.Provider>
    );
};

const footerStateReducer = (defaultState) => (state, action) => {
    switch (action.type) {
        case 'reset':
            return defaultState;
        case 'showFooter':
            return { ...state, visible: action.visible };
        case 'showButton':
            return {
                ...state,
                [action.button]: {
                    ...state[action.button],
                    visible: action.visible,
                },
            };
        case 'disableButton':
            return {
                ...state,
                [action.button]: {
                    ...state[action.button],
                    enabled: action.enabled,
                },
            };
        case 'errorPage':
            return Object.assign(
                {},
                generalDefaultFooterState(),
                defaultDifferences['error']
            );
        case 'updateProgress':
            return {
                ...state,
                progress: {
                    ...state.progress,
                    max: action.max,
                    value: action.value,
                },
            };
        default:
            return state;
    }
};

export default FooterStateProvider;
