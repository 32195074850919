import { createSlice } from '@reduxjs/toolkit';
import { resetTrackedTeamSetting } from './teamSettingsSlice';
/*
This slice manages the change log associated with the settings

*/
const defaultChangeLog = () => {
    return {};
};

// thunk to reset settings
// Not technically part of this slice but logically should be in this file???
const resetChanges = (dispatch, getState) => {
    const state = getState();
    const changes = state.changeLog;
    const changePaths = Object.keys(changes);

    for (let i = 0; i < changePaths.length; i++) {
        const change = changes[changePaths[i]];
        const path = changePaths[i].split('/');

        // Change paths are relative to the entire state
        // Slice paths are relative to the slice
        // Therefore, remove the first element of the path arr before dispatch
        switch (path[0]) {
            case 'teamSettings':
                dispatch(
                    resetTrackedTeamSetting({
                        path: path.filter((_, i) => i !== 0),
                        change: change,
                    })
                );
        }
    }

    dispatch(clearChangeLog());
};

//Create a slice to store the user preferences
export const changeLog = createSlice({
    name: 'changeLog',
    initialState: defaultChangeLog(),

    // Typically, reducers should return new states, not mutated ones. But in the create
    // slice function, redux uses immer which handles the mutations.
    reducers: {
        //Takes payload {pathStr: "", change: {init:..., curr:...} }
        addToChangeLog: (state, action) => {
            const { pathStr, change } = action.payload;
            state[pathStr] = change;
        },

        //Takes in payload {pathStr: ""}
        removeFromChangeLog: (state, action) => {
            const { pathStr } = action.payload;
            const { [pathStr]: change, ...otherChanges } = state;
            return otherChanges;
        },

        clearChangeLog: (state) => {
            return defaultChangeLog();
        },
    },
});

export const { addToChangeLog, removeFromChangeLog, clearChangeLog } =
    changeLog.actions;
export { resetChanges };
export default changeLog.reducer;
