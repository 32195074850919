// First Run routes
import React from 'react';
import GlobalLayout from 'headerFooter/pages/GlobalLayout';
import UserPageOne from 'firstRun/pages/UserPageOne';
import UserPageTwo from 'firstRun/pages/UserPageTwo';
import UserPageThree from 'firstRun/pages/UserPageThree';
import UserPageFour from 'firstRun/pages/UserPageFour';
import ErrorElement from 'global/pages/GeneralErrorPage';
import store from 'reduxStore/store';
import { redirect } from 'react-router-dom';
/*
These routes are called when the user should experience the first run
________________________________________________________________________________________________________________

Navigated Pages:

Page Name -> Path
________________________________________________________________________________________________________________
*/

export const firstRunRoutes = {
    element: <GlobalLayout layoutLocation="firstRun" />,
    children: [
        {
            errorElement: <ErrorElement />,
            children: [
                {
                    path: '/UserPageOne',
                    loader: () => {
                        if (!store.getState().userSettings.showFirstRun.value) {
                            return redirect('/teams');
                        }
                        return null;
                    },
                    element: <UserPageOne />,
                },
                {
                    path: '/UserPageTwo',
                    element: <UserPageTwo />,
                },
                {
                    path: '/UserPageThree',
                    element: <UserPageThree />,
                },
                {
                    path: '/UserPageFour',
                    element: <UserPageFour />,
                },
            ],
        },
    ],
};
