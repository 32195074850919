import { makeStyles, tokens, shorthands } from '@fluentui/react-components';

/*
Description 
________________________________________________________________________________________________________________

Components Styled Here

Component Name -> Style Name -> Description
________________________________________________________________________________________________________________
*/

const useEmptyStateStyles = makeStyles({
    emptyStateWrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 400px)',
        textAlign: 'center',
    },
    emptyStateContentDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '20px',
        textAlign: 'center',
    },
    emptyStateText: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '12px',
        width: '240px',
        alignItems: 'center',
        textAlign: 'center',
    },
    tutorialImage: {
        '@media screen and (min-height:593px)': {
            height: '200px',
            width: '200px',
        },
        '@media screen and (max-height:592px)': {
            height: '100px',
            width: '100px',
        },
        transitionProperty: 'width height',
        transitionDuration: '300ms',
    },
});

export { useEmptyStateStyles };
