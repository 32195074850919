import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import React from 'react';

/*
16x16px circle used for displaying colours - could be extended later to include multiple sizes if needed for 
areas like Brand Check
________________________________________________________________________________________________________________

Props:

colour -> string describing any css valid colour, typically hex codes
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const useColourIconStyles = makeStyles({
    colourIcon: {
        ...shorthands.borderStyle('solid'),
        borderRadius: '1000px',
        ...shorthands.borderColor(tokens.colorNeutralForeground2),
        ...shorthands.borderWidth('1px'),
        width: '16px',
        height: '16px',
    },
});

const ColourIcon = ({ colour = tokens.colorNeutralBackground2 }) => {
    const styles = useColourIconStyles();
    return (
        <div
            className={styles.colourIcon}
            style={{ backgroundColor: colour }}
        />
    );
};

export default ColourIcon;
