import DevTestPage from 'global/pages/DevTestPage';
import React, { createContext, useReducer } from 'react';

export const HeaderStateContext = createContext();
export const HeaderStateDispatchContext = createContext();

/*
Context to provide and control if the footer is visible, and the state of the buttons shown in the footer. 
Uses context instead of redux to provide the state so we can have multiple instances with different states
for animations. 

The context has 3 components
1. The header state - object literal to contain all the header state data 
    - see generalDefaultHeaderState for structure
2. The reducer - function that controls how the state is updated.
    - The reducer take the current state and an object called action as arguments.
    - An action is an object with property "type", all other properties are dependant on the type
    - The reducer takes the current state and action, and returns a new state, non mutably, based entirely
    on the current state and the action type/other properties.
3. Dispatch function - used to send actions to the reducer. 

Note: the default state depends on the location the header footer is configured to rendered in.
The location based defaults are set up in two parts:
1. The base default state - has the entire default state structure
2. The location differences - indexed by location, it contains what properties of the default state is different
    that location. 
    e.g. The default state has no visible buttons - i.e. empty header 
         The home pages don't require the dismiss button to be visible but not enabled, and the avatar visible:
         so we configure the difference to be
         home: {
            dismiss: { visible: true, enabled: false, navigation: { path: -1 } },
            avatar: { visible: true },
        },
The default for a given location is given by Object.assign(default, locationDiff)
________________________________________________________________________________________________________________
*/

const generalDefaultHeaderState = () => ({
    visible: true,
    titleText: '',
    backNav: { visible: false, enabled: true },
    dismiss: { visible: false, enabled: false, navigation: { path: -1 } },
    search: { visible: false, enabled: true, placeholder: '', open: false },
    navMenu: { visible: false, enabled: true },
    avatar: { visible: false },
    admin: { visible: false },
});

const defaultDifferences = {
    home: {
        dismiss: { visible: true, enabled: false, navigation: { path: -1 } },
        avatar: { visible: true },
    },
    assetNav: {
        navMenu: { visible: true, enabled: true },
        search: { visible: true, enabled: true, placeholder: '', open: false },
        backNav: { visible: true, enabled: true },
    },
    searchOpen: (placeholderText) => ({
        search: {
            visible: true,
            enabled: true,
            placeholder: placeholderText,
            open: true,
        },
    }),
    settings: {
        dismiss: { visible: true, enabled: true, navigation: { path: -1 } },
    },
    login: {
        visible: false,
        backNav: { visible: true, enabled: true },
        dismiss: { visible: false, enabled: true },
    },
    error: {
        backNav: { visible: true, enabled: true },
    },
    firstRun: {
        backNav: { visible: true, enabled: true },
        avatar: { visible: true },
    },
};

const HeaderStateProvider = ({ children, location = 'home' }) => {
    //Is a reducer created at every render if I generate it with the default state?
    const defaultState = Object.assign(
        {},
        generalDefaultHeaderState(),
        defaultDifferences[location] || {}
    );

    const [state, dispatch] = useReducer(
        headerStateReducer(defaultState),
        defaultState
    );

    return (
        <HeaderStateContext.Provider value={state}>
            <HeaderStateDispatchContext.Provider value={dispatch}>
                {children}
            </HeaderStateDispatchContext.Provider>
        </HeaderStateContext.Provider>
    );
};

const headerStateReducer = (defaultState) => (state, action) => {
    switch (action.type) {
        case 'reset':
            return defaultState;
        case 'showHeader':
            return { ...state, visible: action.visible };
        case 'titleText':
            return { ...state, titleText: action.text };
        case 'openSearch':
            return Object.assign(
                generalDefaultHeaderState(),
                { titleText: state.titleText },
                action.open
                    ? defaultDifferences.searchOpen(action.placeholder || '')
                    : defaultDifferences.assetNav
            );
        case 'showButton':
            return {
                ...state,
                [action.button]: {
                    ...state[action.button],
                    visible: action.visible,
                },
            };
        case 'disableButton':
            return {
                ...state,
                [action.button]: {
                    ...state[action.button],
                    enabled: action.enabled,
                },
            };
        case 'errorPage':
            return Object.assign(
                {},
                generalDefaultHeaderState(),
                defaultDifferences['error']
            );
        default:
            return state;
    }
};

export default HeaderStateProvider;
