import { useEffect, useState } from 'react';

// Needs testing in an iFrame
/*
returns true if the taskpane has focus, false otherwise
________________________________________________________________________________________________________________
*/
export const useWindowFocus = () => {
    const [windowFocus, setWindowFocus] = useState(document.hasFocus());

    useEffect(() => {
        const handleFocus = () => {
            setWindowFocus(true);
        };
        const handleBlur = () => {
            setWindowFocus(false);
        };
        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    }, []);

    return windowFocus;
};
