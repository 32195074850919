import React, { createContext } from 'react';

const FeatureCardToggleContext = createContext(false);
const FeatureCardSetDisabledContext = createContext(() => {});

const FeatureCardContextProvider = ({ toggled, setDisabled, children }) => {
    return (
        <FeatureCardToggleContext.Provider value={toggled}>
            <FeatureCardSetDisabledContext.Provider value={setDisabled}>
                {children}
            </FeatureCardSetDisabledContext.Provider>
        </FeatureCardToggleContext.Provider>
    );
};

export default FeatureCardContextProvider;
export { FeatureCardSetDisabledContext, FeatureCardToggleContext };
