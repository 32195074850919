import {
    MessageBar,
    MessageBarActions,
    MessageBarBody,
    MessageBarTitle,
    Button,
} from '@fluentui/react-components';
import React from 'react';
import { useGlobalStyles } from 'global/hooks/globalStylesHook';
import { useSelector, useDispatch } from 'react-redux';
import { resetChanges, clearChangeLog } from 'reduxStore/slices/changeLogSlice';
import { useTranslation } from 'react-i18next';

const PublishSettingsMsgBar = () => {
    const styles = useGlobalStyles();
    const dispatch = useDispatch();
    const changeLog = useSelector((state) => state.changeLog);
    const { t } = useTranslation();

    //Return nothing if no changes detected
    if (Object.keys(changeLog).length === 0) return;

    return (
        <MessageBar
            className={styles.globalMargins}
            layout="multiline"
            intent="info"
        >
            <MessageBarBody className={styles.globalMessageFont}>
                <MessageBarTitle className={styles.globalMessageFont}>
                    {t('globalSettingChangeNotification.title')}
                </MessageBarTitle>
                {t('globalSettingChangeNotification.body')}
            </MessageBarBody>
            <MessageBarActions>
                <Button onClick={() => dispatch(clearChangeLog())}>
                    {t('buttons.publish')}
                </Button>
                <Button onClick={() => dispatch(resetChanges)}>
                    {t('buttons.reset')}
                </Button>
            </MessageBarActions>
        </MessageBar>
    );
};

export default PublishSettingsMsgBar;
