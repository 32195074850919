import { makeStyles, tokens, shorthands } from '@fluentui/react-components';
import { customTokens } from 'global/contexts/themeContext';

/*
Description 
used for styling displayed assets
________________________________________________________________________________________________________________

Components Styled Here

AssetListItem -> useAssetStyles -> position sizing and hover state of the asset list item label/spinner/icon/tooltip

AssetTile -> useAssetStyles -> position, sizing, hover and interaction states of the asset tile preview/label/container

AssetTilePreview -> useAssetStyles -> position and scaling of the thumbnail to fit the image container

AssetNavBreadcrumbs -> useBreadcrumbStyles -> position and padding of breadcrumb container

AssetNavToolbar -> useToolbarStyles -> Position/sizing of tool groups, and tool buttons

AssetSearchPage -> useSearchStyles -> position of the search state text
________________________________________________________________________________________________________________
*/
const useAssetStyles = makeStyles({
    //Styling for the whole asset tile structure
    assetTile: {
        boxSizing: 'border-box', // Add this line
        minWidth: '128px',
        maxWidth: '274px',
        width: '100%',
        height: '186px',
        ...shorthands.borderRadius('12px'),
        ':hover': {
            backgroundColor: customTokens.colorBackgroundHoverAsset,
            boxShadow: tokens.shadow4,
        },
        display: 'grid',
        gridTemplateColumns: '10px auto 10px',
        gridTemplateRows:
            '10px [img-start] 96px [img-end] 10px [txt-start] 40px [txt-end] 2px [icn-start] 24px [icn-end] 4px',
        alignItems: 'center',
        justifyItems: 'center',
        cursor: 'pointer',
        overflowX: 'hidden', // Add this line to hide any horizontal overflow
        '& > label': { cursor: 'pointer' },
    },

    assetTileActive: {
        ':active': {
            backgroundColor: tokens.colorNeutralBackground6,
            boxShadow: '0 0 2px rgba(0,0,0,0.24)',
        },
    },

    assetTileInserting: {
        backgroundColor: customTokens.colorBackgroundHoverAsset,
        boxShadow: tokens.shadow4,
    },

    //Positioning for elements in the image portion of the grid
    previewPosition: {
        gridColumnStart: '2',
        gridColumnEnd: '3',
        gridRowStart: 'img-start',
        gridRowEnd: 'img-end',
    },

    //Styling for the generic image preview styling
    imagePreview: {
        ...shorthands.borderRadius('4px'),
        ...shorthands.borderWidth('2px'),
        ...shorthands.borderColor(tokens.colorNeutralBackground1),
        //backgroundColor: tokens.colorNeutralBackground1,
        boxShadow: tokens.shadow4,
        maxWidth: '108px',
        maxHeight: '96px',
        backgroundImage: 'url("/assets/Transparency checker.png")',
    },

    imageWidth: {
        width: '108px',
    },
    imageHeight: {
        height: '96px',
    },

    //Styling for the icon preview
    iconPreview: {
        ...shorthands.padding('12px'),
        ...shorthands.borderRadius('4px'),
        ...shorthands.borderWidth('2px'),
        ...shorthands.borderColor(tokens.colorNeutralBackground1),
        ...shorthands.borderStyle('solid'),
        backgroundColor: '#FFFFFF',
        boxShadow: tokens.shadow4,
        width: '48px',
        height: '48px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
    },

    width100: {
        width: '100%',
    },
    height100: {
        height: '100%',
    },

    iconSize: {
        width: '72px',
        height: '72px',
    },

    previewOverlay: {
        width: '100%',
        height: '100%',
        backgroundColor: customTokens.colorBackgroundHoverAsset,
        opacity: '0.6',
    },

    //Asset description position/styling
    assetDescription: {
        gridColumnStart: '2',
        gridColumnEnd: '3',
        gridRowStart: 'txt-start',
        gridRowEnd: 'txt-end',
        textAlign: 'center',
        overflowWrap: 'break-word',
        maxWidth: '100%',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        overflow: 'hidden', // Ensure content doesn't overflow
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
    },

    assetMoreMenu: {
        gridColumnStart: '2',
        gridColumnEnd: '3',
        gridRowStart: 'icn-start',
        gridRowEnd: 'icn-end',
    },

    //////////   Asset List Item   ////////
    assetListItem: {
        height: '24px',
        ...shorthands.padding('8px'),
        ...shorthands.borderRadius('8px'),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ':hover': {
            backgroundColor: customTokens.colorBackgroundHoverAsset,
            boxShadow: tokens.shadow4,
        },
        cursor: 'pointer',
        '& > label': { cursor: 'pointer' },
    },

    assetListItemIcon: {
        marginRight: '8px',
        width: '24px',
        height: '24px',
    },
    assetListItemSpinner: {
        marginRight: '8px',
        width: '24px',
        height: '20px',
        flexShrink: 0,
    },
    assetListItemLabel: {
        flexGrow: 1,
        flexShrink: 1,
        textWrap: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: '0px',
    },
});

const useBreadcrumbStyles = makeStyles({
    assetNavBreadcrumb: {
        marginTop: '20px',
        marginLeft: '8px',
        marginRight: '8px',
        maxWidth: '100%',
    },
});

const useToolbarStyles = makeStyles({
    endGroup: {
        justifyContent: 'space-between',
    },
    sortButton: {
        ...shorthands.padding('5px'),
        minWidth: '0px',
    },
    viewButton: { width: '46px', maxWidth: '46px', minWidth: '46px' },
    iconFlex: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
    gridIcon: { marginRight: '4px' },
});

//Not In USE
const useIconStyles = makeStyles({
    brandColor: {
        color: tokens.colorBrandForeground1,
    },
});

export const useSearchStyles = makeStyles({
    searchStateText: {
        height: '50vh', //This is subject to design review
        width: '100%',
        maxHeight: '480px',
        textAlign: 'center',
        alignContent: 'center',
    },
});

export { useAssetStyles, useBreadcrumbStyles, useIconStyles, useToolbarStyles };
