import React from 'react';
import { Button } from '@fluentui/react-components';
import { Dismiss20Regular } from '@fluentui/react-icons';
import LinkSaved from 'global/components/LinkSaved';
import { useHeaderFooterStyles } from 'headerFooter/hooks/headerFooterStylesHook';

/*
Renders the cross/X dismiss button, used throughout the app to exit out of certain navigation paths
________________________________________________________________________________________________________________

Props:

dismissState -> object {visible:bool, enabled:bool, navigation:{path:string/int}} used to indicate how 
    the button renders and where it navigates to
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const HeaderDismissButton = ({ dismissState }) => {
    const styles = useHeaderFooterStyles();

    if (!dismissState.visible) return;
    return (
        <LinkSaved to={dismissState.navigation.path} savedNavState={'settings'}>
            <Button
                disabled={!dismissState.enabled}
                appearance="subtle"
                className={styles.navButton}
                icon={<Dismiss20Regular />}
                size="medium"
            />
        </LinkSaved>
    );
};

export default HeaderDismissButton;
