/*
Parses Fetch API errors received from the graph calls into response tokens to be returned from the request layer
________________________________________________________________________________________________________________
*/

import customError from '../../middle-tier/helpers/customError';
import { newErrMsg } from 'utils/messages/messages';

export const parseFetchGraphErrors = async (req, error) => {
    //Handle errors thrown by the fetch api

    if (error instanceof Response) {
        let body;
        try {
            body = await error.clone().json();
        } catch (e) {}
        if (!body) {
            try {
                body = await error.clone().text();
            } catch (e) {}
        }
        const headers = {};
        error.headers.forEach((value, key) => {
            headers[key] = value;
        });
        return {
            ...req,
            status: error.status,
            headers: headers,
            error: newErrMsg(
                error.statusText || body?.error?.message || '',
                body || ''
            ),
        };
    }
    if (error instanceof customError) {
        return {
            ...req,
            status: error.errorCode,
            error: newErrMsg(error.message, error.name),
        };
    }

    //unknown errors come under network errors: timeout, cors, lost connection
    if (error instanceof TypeError && error.message === 'Load failed') {
        return {
            ...req,
            status: 429,
            headers: { 'retry-after': 5 },
            error: newErrMsg('Network error', error),
        };
    }
    return {
        ...req,
        status: 400,
        error: newErrMsg('Unknown Network error', error),
    };
};
