import { Button, SearchBox, mergeClasses } from '@fluentui/react-components';
import { Search20Regular } from '@fluentui/react-icons';
import { useHeaderFooterStyles } from 'headerFooter/hooks/headerFooterStylesHook';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

/*
Description 
This component is responsible for the search icon and bar in the header - 
It is going to be designed such that it can be used on the asset nav page.
Ideally, it will be structure so it can be used on any page in the app (with minimal config)
________________________________________________________________________________________________________________

Props:

searchState -> object {visible:bool, enabled:bool, placeholder:string, open:bool} used to indicate how the button renders
dispatch -> function to dispatch changes to the header state reducer
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTES
    -> Render Position
        When open, The component should only be rendered by itself in the header, and just below the top 
        div in the header component.
________________________________________________________________________________________________________________
*/

const HeaderSearchComponent = ({ searchState, dispatch }) => {
    const styles = useHeaderFooterStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const searchRef = useRef(null);

    useEffect(() => {
        if (searchState.open && searchRef.current) {
            searchRef.current.focus();
        }
    }, [searchState]);

    if (!searchState.visible) return;

    return (
        <div
            className={mergeClasses(
                styles.flexRowEnd,
                styles.colGap4,
                searchState.open && styles.width100
            )}
        >
            {!searchState.open ? (
                /** Search Icon - search not open */
                <Button
                    disabled={!searchState.enabled}
                    appearance="subtle"
                    className={styles.navButton}
                    icon={<Search20Regular />}
                    size="medium"
                    onClick={() => {
                        navigate(location.pathname + '/search');
                    }}
                />
            ) : (
                /** Search Box and cancel- search open */
                <>
                    <SearchBox
                        ref={searchRef}
                        className={styles.searchBox}
                        onChange={(e, data) => {
                            //Captures text & dismiss not enter
                            if (e.type === 'click' && data.value === '') {
                                //Dismiss Clicked = search state reset
                                setSearchParams(``, { replace: true });
                            }
                        }}
                        onKeyDown={(e) => {
                            //captures text and enter not dismiss
                            if (e.key === 'Enter' && e.target?.value !== '') {
                                setSearchParams(
                                    `?searchText=${e.target.value}`,
                                    { replace: true }
                                );
                            }
                        }}
                        placeholder={searchState.placeholder}
                        defaultValue={searchParams.get('searchText') || ''}
                    />
                    <Button
                        onClick={() => {
                            navigate(-1);
                        }}
                        appearance="subtle"
                        className={styles.searchCancel}
                    >
                        {t('buttons.cancel')}
                    </Button>
                </>
            )}
        </div>
    );
};

export default HeaderSearchComponent;
