import React from 'react';

/*
The page that renders when the front-end detects the user has navigated to a route that doesn't exist
________________________________________________________________________________________________________________

Custom Components:

Name -> Description
________________________________________________________________________________________________________________

IMPORTANT NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const Error404Page = () => {
    return (
        <div>
            Oops! Looks like this page doesn't exist. Please reload or navigate
            back!
        </div>
    );
};

export default Error404Page;
