// Asset Nav routes
import React from 'react';
import GlobalLayout from 'headerFooter/pages/GlobalLayout';
import ErrorElement from 'global/pages/GeneralErrorPage';
import AssetNavPage from 'assetNav/pages/AssetNavPage';
import AssetSearchPage from 'assetNav/pages/AssetSearchPage';
import store from 'reduxStore/store';
import { requestAndCacheData } from 'global/routes/cacheLoaderHelpers';

/*
Description 
Routes to handle asset navigation and search
________________________________________________________________________________________________________________

Navigated Pages:

AssetNavPage -> /workspaces/:workspaceId/:libraryId/:folderId? e.g. /workspaces/1234/531 or /workspaces/1234/531/321
AssetSearchPage -> /workspaces/:workspaceId/:libraryId/:folderId?/search e.g. /workspaces/1234/531/search or /workspaces/1234/531/321/search
________________________________________________________________________________________________________________
*/

export const assetNavRoutes = {
    element: <GlobalLayout layoutLocation="assetNav" />,
    children: [
        {
            errorElement: <ErrorElement />,
            children: [
                {
                    path: '/teams/:teamId/:workspaceId/:libraryId/:folderId?',
                    element: <AssetNavPage />,
                    loader: async ({ params }) => {
                        await requestAndCacheData(
                            store,
                            params,
                            false,
                            true,
                            true
                        );
                        return null;
                    },
                },
                {
                    path: '/teams/:teamId/:workspaceId/:libraryId/:folderId?/search',
                    element: <AssetSearchPage />,
                    loader: async ({ params }) => {
                        //Fetch and cache all workspaces
                        await requestAndCacheData(
                            store,
                            params,
                            false,
                            true,
                            true
                        );
                        return null;
                        //Search fo assets in the given library
                    },
                },
            ],
        },
    ],
};
