import { Label, Subtitle1, Tooltip } from '@fluentui/react-components';
import { ArrowSwap20Regular } from '@fluentui/react-icons';
import { useHomeWorkspaceTitleStyles } from 'home/hooks/homeStylesHook';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    settingCacheConfig,
    useSettingsCache,
} from 'settings/hooks/reduxSettingsHooks';
import WorkspaceIcon from './WorkspaceIcon';
import { useHeaderVisibility } from 'headerFooter/hooks/visibilityHook';
import { useHeaderState } from 'headerFooter/hooks/headerFooterStateHook';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectWorkspaceById } from 'reduxStore/slices/teamSettingsSlice';

/*
Description 
responsible for displaying the workspace name at the top of the home page, as well as for workspace swapping
________________________________________________________________________________________________________________

Props:

workspaceId -> used to fetch the workspace details (name/Icon) to display
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const HomeWorkspaceTitle = ({ workspaceId }) => {
    const workspace = useSelector(selectWorkspaceById(workspaceId));
    const styles = useHomeWorkspaceTitleStyles();
    const { t } = useTranslation();
    const [hovered, setHovered] = useState(false);
    const navigate = useNavigate();
    const [iconRef, setIconRef] = useState(null);
    const [fracVis, elRef] = useHeaderVisibility(0.5);
    const [_, dispatch] = useHeaderState();
    const workspaceRequestState = { status: 'loaded' };
    const { teamId } = useParams();

    useEffect(() => {
        if (
            fracVis === 0 &&
            elRef.current &&
            workspaceRequestState.status === 'loaded'
        ) {
            dispatch({ type: 'titleText', text: workspace.name.value });
        } else {
            dispatch({ type: 'titleText', text: '' });
        }
        return () => dispatch({ type: 'titleText', text: '' });
    }, [fracVis]);

    if (workspaceRequestState.status !== 'loaded') return;

    return (
        <Tooltip
            positioning={{ target: iconRef }}
            content={t('workspaceHomePage.tooltip.swapWorkspace')}
            withArrow
            relationship="label"
        >
            <div
                className={styles.homeWorkspaceTitle}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={() => navigate(`/teams/${teamId}?overlay=true`)}
            >
                <WorkspaceIcon
                    className={styles.homeWorkspaceTitleImage}
                    iconURL={workspace.iconURL.value}
                    name={workspace.name.value}
                    setIconRef={setIconRef}
                />
                {hovered && <SwapOverlay />}
                <Label ref={elRef}>
                    <Subtitle1>{workspace.name.value}</Subtitle1>
                </Label>
            </div>
        </Tooltip>
    );
};

const SwapOverlay = () => {
    const styles = useHomeWorkspaceTitleStyles();
    return (
        <div className={styles.iconOverlay}>
            <ArrowSwap20Regular />
        </div>
    );
};

export default HomeWorkspaceTitle;
