import {
    Breadcrumb,
    BreadcrumbButton,
    BreadcrumbDivider,
    BreadcrumbItem,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Overflow,
    OverflowDivider,
    OverflowItem,
    partitionBreadcrumbItems,
    useIsOverflowGroupVisible,
    useIsOverflowItemVisible,
    useOverflowMenu,
} from '@fluentui/react-components';
import React, { useEffect } from 'react';
import { useHeaderVisibility } from 'headerFooter/hooks/visibilityHook';
import { useBreadcrumbStyles } from 'assetNav/hooks/assetStylesHook';
import { MoreHorizontal20Regular } from '@fluentui/react-icons';
import { useHeaderState } from 'headerFooter/hooks/headerFooterStateHook';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigateBreadcrumbs } from 'assetNav/hooks/breadcrumbNavigateHook';

/*
Description 
The breadcrumbs used to navigate at the top of the asset navigation page
currently just a dummy display
________________________________________________________________________________________________________________

Props:

Name -> Description
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const AssetNavBreadcrumbs = () => {
    const styles = useBreadcrumbStyles();
    const [fracVis, elRef] = useHeaderVisibility(0.5);
    const [_, dispatch] = useHeaderState();
    const location = useLocation();

    let breadcrumbs = location.state?.breadcrumbs || [];

    useEffect(() => {
        if (fracVis === 0 && elRef.current) {
            if (breadcrumbs.length > 0) {
                dispatch({
                    type: 'titleText',
                    text: breadcrumbs.slice(-1)[0].name,
                });
            }
        } else {
            dispatch({ type: 'titleText', text: '' });
        }
        return () => dispatch({ type: 'titleText', text: '' });
    }, [fracVis]);

    //Set breadcrumb priority - low priority => hidden first
    breadcrumbs = breadcrumbs.map((breadcrumb, index, arr) => {
        let priority;
        const id = '' + breadcrumb.libraryId + breadcrumb.folderId || '';
        if (index === 0 && arr.length === 0) {
            priority = 1;
        } else if (index === 0 && arr.length) {
            priority = arr.length - 1;
        } else if (index === arr.length - 1) {
            priority = arr.length;
        } else {
            priority = index;
        }
        return { ...breadcrumb, priority: priority, id: id };
    });

    //render breadcrumbs in a basic list
    return (
        <Overflow key={breadcrumbs[breadcrumbs.length-1].id} ref={elRef} minimumVisible={0}>
            <Breadcrumb  size="large" className={styles.assetNavBreadcrumb}>
                {breadcrumbs.map((breadcrumb, index, arr) => {
                    const current = index === arr.length - 1;
                    return (
                        <React.Fragment key={breadcrumb.id}>
                            {index !== 0 && (
                                <CustomOverflowDivider
                                    groupId={'group' + breadcrumb.id}
                                />
                            )}

                            <OverflowItem
                                id={breadcrumb.id}
                                groupId={'group' + breadcrumb.id}
                                priority={breadcrumb.priority}
                            >
                                <CustomBreadcrumbItem
                                    breadcrumb={breadcrumb}
                                    current={current}
                                />
                            </OverflowItem>
                            {index === 0 && (
                                <BreadcrumbOverflowMenu items={breadcrumbs} />
                            )}
                        </React.Fragment>
                    );
                })}
            </Breadcrumb>
        </Overflow>
    );
};

const CustomOverflowDivider = ({ groupId }) => {
    return (
        <OverflowDivider groupId={groupId}>
            <BreadcrumbDivider />
        </OverflowDivider>
    );
};

//Overflow Item sends a ref to its single child so it can get data on its position for calculations.
//Custom components can't accept refs, so use React.forwardRef to create a ref compatible component.
const CustomBreadcrumbItem = React.forwardRef(
    ({ breadcrumb, current }, ref) => {
        const navigate = useNavigateBreadcrumbs(
            breadcrumb.name,
            breadcrumb.libraryId,
            breadcrumb.folderId
        );
        const { teamId, workspaceId } = useParams();

        return (
            <BreadcrumbItem ref={ref}>
                <BreadcrumbButton
                    onClick={() => {
                        const url = `/teams/${teamId}/${workspaceId}/${
                            breadcrumb.libraryId
                        }${
                            breadcrumb.folderId ? `/${breadcrumb.folderId}` : ``
                        }`;
                        navigate(url);
                    }}
                    current={current}
                >
                    {breadcrumb.name}
                </BreadcrumbButton>
            </BreadcrumbItem>
        );
    }
);

const BreadcrumbOverflowMenu = ({ items }) => {
    const { ref, overflowCount, isOverflowing } = useOverflowMenu();

    if (!isOverflowing) return null;

    return (
        <>
            {overflowCount < items.length - 1 && <BreadcrumbDivider />}
            <Menu>
                <MenuTrigger>
                    <MenuButton
                        appearance="subtle"
                        ref={ref}
                        icon={<MoreHorizontal20Regular />}
                    />
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        {items.map((item) => (
                            <OverflowMenuItem key={item.id} item={item} />
                        ))}
                    </MenuList>
                </MenuPopover>
            </Menu>
        </>
    );
};

const OverflowMenuItem = ({ item }) => {
    const isVisible = useIsOverflowItemVisible(item.id);
    const navigate = useNavigateBreadcrumbs(
        item.name,
        item.libraryId,
        item.folderId
    );
    const { teamId, workspaceId } = useParams();
    if (isVisible) return null;

    return (
        <MenuItem
            onClick={() => {
                const url = `/teams/${teamId}/${workspaceId}/${item.libraryId}${
                    item.folderId ? `/${item.folderId}` : ``
                }`;
                navigate(url);
            }}
        >
            {item.name}
        </MenuItem>
    );
};

export default AssetNavBreadcrumbs;
