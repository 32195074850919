import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAssetStyles } from 'assetNav/hooks/assetStylesHook';
import {
    Skeleton,
    SkeletonItem,
    displayClassNames,
    mergeClasses,
} from '@fluentui/react-components';
import { Image as ImageFluent } from '@fluentui/react-components';
import generalRequests from 'requests/endpoints/generic-endpoints';
import { SVG } from 'utils/svgs/svg-class';
import graphRequests from 'requests/endpoints/graph-endpoints';

/*
Renders a preview of the asset based on asset type, downloadURL and thumbnailURL
________________________________________________________________________________________________________________

Props:

asset -> a single asset object (see requests/uniform-asset-data)

________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const AssetTilePreview = ({ asset, setAsset }) => {
    switch (asset.type) {
        case 'folder':
        case 'audio/mpeg':
        case 'video/mp4':
        case 'text/plain':
            return;
        case 'image/svg+xml':
            return <IconPreview asset={asset} setAsset={setAsset} />;
        default:
            return <ImagePreview asset={asset} setAsset={setAsset} />;
    }
};

const ImagePreview = ({ asset, setAsset }) => {
    const styles = useAssetStyles();
    const [sizeHorizontally, setSizeHorizontally] = useState(null);

    const fitToFrame = useCallback((e) => {
        const img = e.target;
        if (!img.width || !img.height) return;
        setAsset({ ...asset, image: { aspectRatio: img.width / img.height } });
        setSizeHorizontally(img.width * 96 > 108 * img.height);
    }, []);

    //If the download URL is given to the Image, then gifs will play - not just static thumbnail
    const [srcURL, setSrcURL] = useState('');

    useEffect(() => {
        switch (asset.type) {
            case 'image/gif':
                if (!asset.downloadURL) {
                    graphRequests.endpoints
                        .driveItemDownloadURL({
                            driveId: asset.driveId,
                            itemId: asset.id,
                        })
                        .then((res) => {
                            setSrcURL(res.data);
                        })
                        .catch((e) => setSrcURL('error'));
                } else setSrcURL(asset.downloadURL);
                break;
            default:
                if (!asset.thumbnailURL) {
                    graphRequests.endpoints
                        .driveItemThumbnailURL({
                            driveId: asset.driveId,
                            itemId: asset.id,
                        })
                        .then((res) => {
                            setSrcURL(res.data);
                        })
                        .catch((e) => setSrcURL('error'));
                } else {
                    setSrcURL(asset.thumbnailURL);
                }
        }
    }, []);

    return (
        <>
            {srcURL && srcURL !== 'error' && (
                <ImageFluent
                    bordered
                    shadow
                    className={mergeClasses(
                        styles.previewPosition,
                        styles.imagePreview,
                        sizeHorizontally && styles.imageWidth,
                        sizeHorizontally === false && styles.imageHeight
                    )}
                    src={srcURL}
                    onLoad={(e) => {
                        fitToFrame(e);
                    }}
                    onError={(e) => {
                        fitToFrame(e);
                    }}
                />
            )}
            {/* size horizontally is set when the image loads - can indicate life time of skeleton */}
            {sizeHorizontally === null && srcURL !== 'error' && (
                <Skeleton className={styles.previewPosition}>
                    <SkeletonItem
                        className={mergeClasses(
                            styles.imageHeight,
                            styles.imageWidth
                        )}
                    />
                </Skeleton>
            )}
            {/*loadState === 'error' && Image Error Element TBD*/}
        </>
    );
};

/*
The Icon preview will download the SVG and manually insert it into the DOM.
This is so we can edit the stylings of the SVG before insertion.
This opens up vulnerabilities for xss, so we need to ensure we sanitise the SVG XML. 
The DOMPurify docs recommend sanitising after editing the XML - Is this applicable for us?
*/
const IconPreview = ({ asset, setAsset }) => {
    const styles = useAssetStyles();

    //Error message that gets populated when the request fails to return svg xml text.

    const [displayType, setDisplayType] = useState('none');

    useEffect(() => {
        const controller = new AbortController();
        if (asset.thumbnailURL) {
            const img = new Image();
            img.onload = function (e) {
                const width = this.width;
                const height = this.height;

                if (!width && !height) {
                    setDisplayType('error');
                    return;
                }

                if (width >= 48 && height >= 48) {
                    setDisplayType(
                        height > width ? 'image-height' : 'image-width'
                    );
                    return;
                }
                //Image too small - need to download the asset for display
                generalRequests.endpoints
                    .downloadAsset(
                        { asset: asset, optimised: false },
                        controller.signal
                    )
                    .then((res) => {
                        const svg = new SVG(res.data);
                        svg.width = 48;
                        svg.height = 48;

                        setAsset({
                            ...asset,
                            icon: { ...asset.icon,safeXML: svg.toString() },
                        });
                        setDisplayType('svg');
                    })
                    .catch((res) => {
                        setDisplayType('error');
                    });
            };
            img.src = asset.thumbnailURL;
        } else {
            setDisplayType('error');
        }

        return () => controller.abort();
    }, []);

    const safeXML = asset?.icon?.safeXML || '';

    return (
        <>
            {displayType === 'svg' && (
                <div
                    className={mergeClasses(
                        styles.previewPosition,
                        styles.iconPreview
                    )}
                    //Scarily named to ensure devs remember to sanitise before inserting.
                    dangerouslySetInnerHTML={{ __html: safeXML }}
                />
            )}

            {displayType.includes('image') && (
                <div
                    className={mergeClasses(
                        styles.previewPosition,
                        styles.iconPreview
                    )}
                >
                    <ImageFluent
                        height={
                            displayType.includes('image-height')
                                ? 48
                                : undefined
                        }
                        width={
                            displayType.includes('image-width') ? 48 : undefined
                        }
                        onLoad={(e) => {
                            setDisplayType(displayType + '-loaded');
                        }}
                        onError={() => {
                            setDisplayType('error');
                        }}
                        src={asset.thumbnailURL}
                    />
                </div>
            )}
            {/* Display the skeleton when fetching svg text, or when loading the image */}
            {(displayType === 'none' ||
                (displayType.includes('image') &&
                    !displayType.includes('loaded'))) && (
                <Skeleton className={styles.previewPosition}>
                    <SkeletonItem className={styles.iconSize} />
                </Skeleton>
            )}
            {/* displayType === 'error' && render Image error element TBD */}
        </>
    );
};
export default AssetTilePreview;
