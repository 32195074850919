import React from 'react';
import { Link, useLocation } from 'react-router-dom';

/*
Wrapper of react router link component to implement same history management as useSavedNavigate hook
On error - return the link component with no changes
________________________________________________________________________________________________________________

Props:

savedNavState -> string used to identify a previously defined save state
saveState -> string used to save the current location the user is navigating.
linkProps - all other valid react-router Link props
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const LinkSaved = ({ children, savedNavState, saveState, ...linkProps }) => {
    const location = useLocation();

    try {
        //Get saved states
        const savedNavStates = location.state?.savedNavStates || {};

        //Clone props
        const newLinkProps = { ...linkProps };

        //Ensure state prop is an object
        newLinkProps.state =
            linkProps.state &&
            Object.getPrototypeOf(newLinkProps.state) === Object.prototype
                ? newLinkProps.state
                : {};

        // Update new state props with old saved states
        newLinkProps.state.savedNavStates = { ...savedNavStates };

        //If a saved state is specified and exists, update nav link prop
        if (
            savedNavState &&
            savedNavStates[savedNavState] &&
            history.state.idx
        ) {
            newLinkProps.to = savedNavStates[savedNavState] - history.state.idx;
        }

        //if a state should be saved, update props
        if (saveState) {
            newLinkProps.state.savedNavStates[saveState] = history.state.idx;
        }

        return <Link {...newLinkProps}>{children}</Link>;
    } catch {
        return <Link {...linkProps}>{children}</Link>;
    }
};

export default LinkSaved;
