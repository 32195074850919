//User Id Prep Functions
import { matchPath } from 'react-router-dom';
import accountManager from '../../naa/accountManager';
import { msgTypes, newErrMsg, newMsg } from 'utils/messages/messages.js';

/*
Request prepare function to staple the BrightCarbon JSON Web Token to the request. If the request returns that the
token has expired, then this prepper also handles the re-authentication flow too.
________________________________________________________________________________________________________________
*/

export const prepareBCJWT = (apiLayer) => (next) => {
    const bcjwtLayer =
        (attemptCount = 0) =>
        async (req) => {
            let res;

            // This gets the BC JWT token by making a request to validate the current users sso id token against our servers team members
            let BCJWT;

            //get team id from location
            const teamId = req.payload?.teamId;

            if (!teamId) {
                //can't request jwt without team id
                throw {
                    ...req,
                    status: 401,
                    error: newErrMsg('Auth error: Invalid team id'),
                };
            }

            //Attempt to get BC JWT token without refreshing it
            try {
                BCJWT = await accountManager.getBCJWT(
                    teamId,
                    attemptCount >= 1
                );
            } catch (e) {
                //failed to get sso token
                throw {
                    ...req,
                    status: 401,
                    error: newErrMsg('Auth error: jwt failure'),
                };
            }

            //Staple ID token to request token
            req = { ...req, BCJWT: BCJWT };
            //Send to next prepper
            try {
                res = await next(req);
            } catch (e) {
                console.log(e);
                //Check if the error is from an expired token
                if (
                    e.status === 401 &&
                    e.error.payload === 'Token Expired' &&
                    attemptCount === 0
                ) {
                    res = await bcjwtLayer(1)(req);
                } else {
                    throw e;
                }
            }

            return res;
        };
    return bcjwtLayer();
};
