///Downloading file as base 64

import { downloadAsset } from 'requests/endpoints/generic-endpoints';
import { newErrMsg } from 'utils/messages/messages';
import { SVG } from 'utils/svgs/svg-class';

/* 
Functions to check the asset type against the office coercion types
*/
const isImageCoercionType = (asset) => {
    switch (asset.type) {
        case 'image/tiff':
        case 'image/gif':
        case 'image/png':
        case 'image/jpeg':
            return true;
        default:
            return false;
    }
};

const isSVGCoercionType = (asset) => {
    return asset.type === 'image/svg+xml';
};

const isPresentationCoercionType = (asset) => {
    return (
        asset.type ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    );
};

const isTemplateType = (asset) => {
    return asset.type === 'template';
};

/*
This object defines the different insertion types for different asset types
 */
export const insertTypes = {
    default: 'default', //-> basic insert
    image: {
        original: 'original',
        optimised: 'optimised',
    },
    presentation: {
        asSlides: 'asSlides',
        asPresentation: 'asPresentation',
    },
};

/*
General asset insertion function.
is this needed??
*/
export const insertAsset = async (asset, insertType = insertTypes.default) => {
    try {
        if (isPresentationCoercionType(asset)) {
            if (
                insertType === insertTypes.default ||
                insertType === insertTypes.presentation.asSlides
            ) {
                await insertAssetAsSlides(asset);
            } else if ((insertType = insertTypes.presentation.asPresentation)) {
                await insertAssetAsNewPresentation(asset);
            } else {
                //How to handle incorrect insert type?
            }
            return;
        }
        if (isTemplateType(asset)) {
            await insertAssetAsNewPresentation(asset);
        }

        if (isSVGCoercionType(asset)) {
            await insertAssetAsSVG(asset);
        }

        if (
            isImageCoercionType(asset) &&
            (insertType === insertTypes.default ||
                insertType === insertTypes.image.optimised ||
                insertType === insertTypes.image.original)
        ) {
            if (asset.type === 'image/gif') {
                await insertAssetAsImage(asset, insertTypes.image.original);
            } else {
                await insertAssetAsImage(asset, insertType);
            }
        } else {
            //Insert and asset type not compatible
        }
    } catch (e) {
        console.log(e);
        if (e.type && e.text) throw e;
        throw newErrMsg('Failed to insert asset');
    }
};

/*
SVG Asset Insertion
*/

const insertAssetAsSVG = async (asset) => {
    let svgXML = '';
    try {
        if (!asset.icon.safeXML) {
            const res = await downloadAsset({ asset: asset, optimised: false });
            svgXML = res.data;
        } else {
            svgXML = asset.icon.safeXML;
        }
    } catch (e) {
        console.log(e);
        throw newErrMsg('Failed to download file');
    }

    //Extract size from XML and position in the center
    const svg = new SVG(svgXML);
    const width = svg.width;
    const height = svg.height;

    if (
        asset.driveId ===
        'b!OTS627NxUUG_2aPE7UICkcyjxajDy9hFpY05svxzTLPDVNcLzh9ZQ5o2Or7HnuTu'
    ) {
        svg.bcRecolour(asset.icon.colour);
    }

    const sizeProps =
        width >= height
            ? {
                  imageWidth: 72,
                  imageLeft: 444,
                  imageTop: 270 - (36 * height) / width,
              }
            : {
                  imageHeight: 72,
                  imageLeft: 480 - (36 * width) / height,
                  imageTop: 234,
              };

    //Would be really handy to have this as an awaitable version
    return new Promise((resolve, reject) => {
        Office.context.document.setSelectedDataAsync(
            svg.toString(),
            {
                coercionType: Office.CoercionType.XmlSvg,
                ...sizeProps,
            },
            (result) => {
                if (result.error) {
                    reject(newErrMsg('Failed to insert icon'));
                }
                resolve();
            }
        );
    });
};

/*
Image Asset Insertion
*/

const insertAssetAsImage = async (
    asset,
    insertType = insertTypes.image.optimised
) => {
    const res = await downloadAsset({
        asset: asset,
        optimised: insertType !== insertTypes.image.original,
    });
    const file = res.data;

    // Want to center image on slide, but API can't do this atm
    // Assume standard 16:9 slide or 960 by 540 points
    let sizePosProps = {};

    //controls how much the image takes up the slide on its longest side
    const slideCoverage = 0.75;

    if (asset.image.aspectRatio > 0 && asset.image.aspectRatio >= 16 / 9) {
        sizePosProps = {
            imageWidth: slideCoverage * 960, //slideCoverage*width in points
            imageLeft: 0.125 * 960,
            imageTop:
                (540 - (slideCoverage * 960) / asset.image.aspectRatio) / 2,
        };
    }

    if (asset.image.aspectRatio > 0 && asset.image.aspectRatio < 16 / 9) {
        sizePosProps = {
            imageHeight: slideCoverage * 540, //slideCoverage*width in points
            imageLeft:
                (960 - slideCoverage * 540 * asset.image.aspectRatio) / 2,
            imageTop: 0.125 * 540,
        };
    }

    return new Promise((resolve, reject) => {
        Office.context.document.setSelectedDataAsync(
            file.data,
            {
                coercionType: Office.CoercionType.Image,
                ...sizePosProps,
            },
            (result) => {
                if (result.error) {
                    reject(newErrMsg('Failed to insert image'));
                }
                resolve();
            }
        );
    });
};

/*
Slide asset insertion function
*/
const insertAssetAsSlides = async (asset) => {
    const res = await downloadAsset({ asset: asset, optimised: false });
    const file = res.data;
    try {
        //Insert slides as base 64 after current slide
        await PowerPoint.run(async function (context) {
            const slideId = await getSelectedSlideID();
            context.presentation.insertSlidesFromBase64(file.data, {
                formatting: 'UseDestinationTheme',
                targetSlideId: slideId + '#',
            });

            await context.sync();
        });
    } catch (e) {
        throw newErrMsg('Failed to insert slides');
    }

    //move view to first slide in inserted set i.e. next slide when inserted after
    //Error logged to console but not reported to user as its a non-critical UX bug
    const nextSlideId = Office.Index.Next;
    Office.context.document.goToByIdAsync(
        nextSlideId,
        Office.GoToType.Index,
        (asyncResult) => {
            if (asyncResult.status === 'failed') {
                console.log(
                    `Failed to navigate to slide : ${asyncResult.error.code}`
                );
            }
        }
    );
};

//get the current slide ID
async function getSelectedSlideID() {
    return new OfficeExtension.Promise(function (resolve, reject) {
        Office.context.document.getSelectedDataAsync(
            Office.CoercionType.SlideRange,
            function (asyncResult) {
                try {
                    if (
                        asyncResult.status === Office.AsyncResultStatus.Failed
                    ) {
                        reject(console.error(asyncResult.error.message));
                    } else {
                        resolve(asyncResult.value.slides[0].id);
                    }
                } catch (error) {
                    reject(console.log(error));
                }
            }
        );
    });
}

/*
Open asset slides in new presentation
*/
const insertAssetAsNewPresentation = async (asset) => {
    const res = await downloadAsset({ asset: asset, optimised: false });
    const file = res.data;
    try {
        await PowerPoint.createPresentation(file.data);
    } catch (e) {
        throw newErrMsg('Failed to create presentation');
    }
};
