/*
Handles the throttling of the route by backing off from the request until the after a retry period has elapsed
Should be the first prepper in the prepper array

The throttle group is a string used to group together different configured endpoints to the same request timeout:
e.g. downloading a drive item and requesting all drives for a site are subject to the same throttling limit

we can group these two requests together by providing the same group name in config
prepare = [prepareThrottleHandler('driveItems')]

leaving the group empty applies a separate throttling limit for just that endpoint
________________________________________________________________________________________________________________
*/

import { newErrMsg } from 'utils/messages/messages';

export const prepareThrottleHandler =
    (throttleGroup = '') =>
    (apiLayer) =>
    (next) =>
    async (req) => {
        let retryCount = 0;
        const throttleKey = throttleGroup || req.type || 'global';

        while (true) {
            try {
                await waitIfThrottled(throttleKey, apiLayer);
                //Check if request is aborted
                if (req.signal?.aborted) {
                    throw { status: 299, error: newErrMsg('aborted') };
                }
                //send of to next prepper
                return await next(req);
            } catch (error) {
                if (!error?.status || error.status !== 429 || retryCount >= 5) {
                    throw error;
                }
                console.log(error, retryCount)
                retryCount++;
                const retryAfter = error.headers['retry-after'];
                apiLayer.setThrottlingTime(
                    throttleKey,
                    Date.now() + 1000 * Number(retryAfter)
                );
            }
        }
    };

const waitIfThrottled = async (throttleKey, apiLayer) => {
    while (
        apiLayer.getThrottlingTime(throttleKey) &&
        Date.now() < apiLayer.getThrottlingTime(throttleKey)
    ) {
        await waitUntil(apiLayer.getThrottlingTime(throttleKey));
    }
};

const waitUntil = async (date) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, date - Date.now());
    });
};
