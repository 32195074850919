/*
Utility functions useful for many different request types
________________________________________________________________________________________________________________
*/

//jQuery Ajax function doesn't support abort signal normally: this functions creates
// an event link between the abort signal and the intrinsic jQuery abort function
export const createAbortLink = (signal) => {
    let callback;
    if (!signal) return {};

    return {
        beforeSend: (jqXhr) => {
            callback = () => jqXhr.abort();
            signal.addEventListener('abort', callback);
        },
        complete: (jqXhr, status) => {
            signal.removeEventListener('abort', callback);
        },
    };
};
