import AssetDisplay from 'assetNav/components/AssetDisplay';
import {
    useFooterState,
    useHeaderState,
} from 'headerFooter/hooks/headerFooterStateHook';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectWorkspaceById } from 'reduxStore/slices/teamSettingsSlice';
import graphRequests from 'requests/endpoints/graph-endpoints';
import SettingsMenuTitle from 'settings/components/SettingsMenuTitle';
import EmptyStateTitle from 'home/components/emptyStates/EmptyStateTitle';
import EmptyStateBody from 'home/components/emptyStates/EmptyStateBody';
import { Button } from '@fluentui/react-components';
import { useEmptyStateStyles } from 'home/hooks/emptyStateStylesHook';
import EmptyStateImage from 'home/components/emptyStates/EmptyStateImage';
/*
Description 
________________________________________________________________________________________________________________

Custom Components:

SettingsMenuTitle -> Generic title/subtitle used throughout the app
AssetDisplay -> generic display for assets
________________________________________________________________________________________________________________

IMPORTANT NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const templatesStates = {
    loading: 'loading',
    error: 'error',
    loaded: 'loaded',
};

const WorkspaceNewFilePage = () => {
    const { t } = useTranslation();
    const { workspaceId } = useParams();
    const workspace = useSelector(selectWorkspaceById(workspaceId));
    const classes = useEmptyStateStyles();
    useHeaderState([
        { type: 'disableButton', button: 'dismiss', enabled: true },
        { type: 'showButton', button: 'avatar', visible: false },
    ]);
    useFooterState([{ type: 'showFooter', visible: false }]);

    const [templates, setTemplates] = useState({
        state: templatesStates.loading,
        data: [],
    });

    useEffect(() => {
        setTemplates({ state: templatesStates.loading, data: [] });
        graphRequests.endpoints
            .getTemplatesBySiteId({
                siteId: workspace.location.siteId,
            })
            .then((res) => {
                const unfilteredAssets = res.data;
                //Filter out all but pptx files, then change the asset type to template
                //potx not supported, changing the asset type here allows us to distinguish
                //slides vs templates.
                const filteredAssets = unfilteredAssets
                    .filter(
                        (asset) =>
                            asset.type ===
                            'application/vnd.openxmlformats-officedocument.presentationml.presentation'
                    )
                    .map((asset) => ({ ...asset, type: 'template' }));

                setTemplates({
                    state: templatesStates.loaded,
                    data: filteredAssets,
                });
            })
            .catch((e) => {
                console.log(e);
                setTemplates({ state: templatesStates.error, data: [] });
            });
    }, []);

    const handleButtonClick = async () => {
        try {
            const res = await graphRequests.endpoints.driveTemplatesShareLink({
                siteId: workspace.location.siteId,
            });
            window.open(res.data, '_blank')?.focus();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <SettingsMenuTitle title={t('workspaceNewPage.title')} />
            {templates.state === templatesStates.loaded &&
                templates.data?.length>0 && (
                    <AssetDisplay assets={templates.data} />
                )}
            {templates.state === templatesStates.loaded &&
                !templates.data?.length && (
                    <div className={classes.emptyStateWrapper}>
                        <div className={classes.emptyStateContentDiv}>
                            <EmptyStateImage />
                            <div className={classes.emptyStateText}>
                                <EmptyStateTitle
                                    title={t('emptyTemplates.title')}
                                />
                                <EmptyStateBody
                                    content={t('emptyTemplates.body')}
                                />
                            </div>
                            <Button
                                appearance="primary"
                                onClick={handleButtonClick}
                            >
                                {t('buttons.openSP')}
                            </Button>
                        </div>
                    </div>
                )}
        </>
    );
};

export default WorkspaceNewFilePage;
