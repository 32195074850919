import React from 'react';
import { Body1, Card, mergeClasses } from '@fluentui/react-components';

import { useSettingMenuStyles } from 'settings/hooks/settingsStylesHook';

import WorkspaceIcon from './WorkspaceIcon';

/*
Used to render a team to navigate into:
uses the workspaces card as its basis
________________________________________________________________________________________________________________

Props:

Name -> Display name for team
iconURL -> url for the team if it exists
onClick -> event handler for the card click
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const TeamCard = ({ name, iconURL, onClick }) => {
    const styles = useSettingMenuStyles();

    // Only add props when not in preview -> props trigger the hover/click states of the card
    const cardProps = onClick
        ? {
              onClick: onClick,
          }
        : {};

    return (
        <Card {...cardProps} size="large">
            <div
                className={mergeClasses(
                    styles.rowFlexCenter,
                    styles.justifyItems
                )}
            >
                <div
                    className={mergeClasses(
                        styles.rowFlexCenter,
                        styles.workspaceIconName
                    )}
                >
                    <WorkspaceIcon iconURL={iconURL} name={name} />
                    <Body1>{name}</Body1>
                </div>
            </div>
        </Card>
    );
};

export default TeamCard;
