import { addToChangeLog, removeFromChangeLog } from './slices/changeLogSlice';

/*
This module contains all the code for the settings tracking middleware.

The middleware is accessed by adding the track property to the payload
the value of the track property has the form
payload.track = {
    path: [...] path to setting value as array of property names
    isEqualFcn: ""  string to specify the comparison function used to detect setting change
}

The default comparison operator is ===
Current alternative is the "hasSameElements" function which checks if two arrays have the same 
values regardless of where they are positioned.

LIMITATIONS:
The Middleware only runs if the payload has the property "track".
This assumes the payload is an object, so any setting that is being tracked must have an object payload

*/

/*
Immutable recursive functions to get and set properties of the slice settings
*/

// Get the value of a property at a given path
const getStateValue = (state, path) => {
    return path.length === 1
        ? state[path[0]]
        : getStateValue(
              state[path[0]],
              path.filter((_, i) => i !== 0)
          );
};

//Recursive function to generate a new copy of state, with the property at the given path updated to value.
const getNewSettingState = (state, path, value) => {
    return {
        ...state,
        [path[0]]:
            path.length === 1
                ? value
                : getNewSettingState(
                      state[path[0]],
                      path.filter((_, i) => i !== 0),
                      value
                  ),
    };
};

// Function to check if two arrays have the same elements regardless of order
// only works on types with unique serialisation (numbers, strings)
// Looping though the elements would be better
const hasSameElements = (arr1, arr2) => {
    if (!(arr1.length === arr2.length)) return false;

    const arrCopy1 = [...arr1];
    const arrCopy2 = [...arr2];

    return arrCopy1.sort().join(',') === arrCopy2.sort().join(',');
};

/// Now can we build some middleware with the same functionality as the code above?
/// If we call Next in the middleware code, will this return from the current function, or will code after it still run too?

/// Middleware functions act as wrappers for the dispatch function that are applied to every route.
/// The above code acts as an optional wrapper, but it wouldn't be any real latency to extend it to all routes.

export const SettingsTracking = (store) => (next) => (action) => {
    //Check if the setting is being tracked
    if (!action?.payload?.track) {
        return next(action);
    }

    //Extract track payload
    const { path, isEqualFcn } = action.payload.track;
    const pathStr = path.join('/');

    //Get the initial value/state
    const prevState = store.getState();
    const prevValue = getStateValue(prevState, path);

    let result = next(action);

    //Get the new state/value
    const currState = store.getState();
    const currValue = getStateValue(currState, path);

    if (currValue === undefined || prevValue === undefined) return result;

    //Construct a change object for the change log
    const change = prevState.changeLog[pathStr]
        ? { ...prevState.changeLog[pathStr], curr: currValue }
        : { init: prevValue, curr: currValue };

    //Check if the value has changed.
    let equal;
    switch (isEqualFcn) {
        case 'arraySameElements':
            equal = hasSameElements(change.init, change.curr);
            break;
        default:
            equal = change.init === change.curr;
    }

    equal
        ? store.dispatch(removeFromChangeLog({ pathStr: pathStr }))
        : store.dispatch(addToChangeLog({ pathStr: pathStr, change: change }));

    return result;
};

export { getNewSettingState, getStateValue };
