/*
What would I want the SVG class to do?

input - accept svg xml as a string and parse it
- get the width and height of the svg
- set the width and height of the svg
- get safe/sanitised version of the svg xml
- revert to original
- Allow SVG color mapping
*/

import DOMPurify from 'dompurify';
import { bcColorMapper } from 'utils/colors/bcColorMapping';

export class SVG {
    /*
    Private fields
    */
    #parser;
    #serializer;
    #ogXML;

    //Curr xml is used as the source of truth
    #currXML;

    constructor(svgString = '') {
        this.#parser = new DOMParser();
        this.#serializer = new XMLSerializer();
        this.#ogXML = '';
        this.#currXML = '';
        this.fromString(svgString);
        return this;
    }

    /*
    Public Methods
    */

    ////////// String in/output  ///////////
    fromString(svgString = '') {
        try {
            const doc = this.#svgDocument(svgString);
            this.#ogXML = svgString;
            this.#currXML = svgString;
        } catch (e) {
            throw new Error('Invalid SVG string: ' + svgString);
        }
    }

    toStringDangerously() {
        return this.#currXML;
    }

    toString() {
        return DOMPurify.sanitize(this.#currXML);
    }

    reset() {
        this.fromString(this.#ogXML);
    }

    /////////// SVG Dimensions /////////////

    get height() {
        //first check for height attribute
        let height = this.#svgElement().getAttribute('height');
        if (height) return Number(height);

        //if no height, check for viewBox
        const viewBox = this.#svgElement().getAttribute('viewBox');
        if (viewBox) {
            const bounds = viewBox.split(' ');
            return Number(bounds[3]);
        }

        //if no view box, return bounding box
        return this.#getBBox().height;
    }

    set height(size) {
        if (!this.#hasViewBox()) {
            this.#addViewBox();
        }
        const svgEl = this.#svgElement();
        svgEl.setAttribute('height', size);

        this.#updateCurrentFromNode(svgEl);
    }

    get width() {
        //First check the width attribute
        let width = this.#svgElement().getAttribute('width');
        if (width) return Number(width);

        //If no width, check for viewbox
        const viewBox = this.#svgElement().getAttribute('viewBox');
        if (viewBox) {
            const bounds = viewBox.split(' ');
            return Number(bounds[2]);
        }

        //if no viewBox, temp insert and use bounding box
        return this.#getBBox().width;
    }
    set width(size) {
        //If there isn't a view box - add one based on current width/height
        if (!this.#hasViewBox()) {
            this.#addViewBox();
        }
        //extract element and set width attribute
        const svgEl = this.#svgElement();
        svgEl.setAttribute('width', size);

        //update current xml
        this.#updateCurrentFromNode(svgEl);
    }

    /////// SVG ReColouring  ////////

    bcRecolour(color = 'toPink') {
        const colorMapper = new bcColorMapper();
        colorMapper.setColorMap(color);
        const doc = this.#svgDocument();
        const elements = doc.querySelectorAll('*');

        elements.forEach((element) => {
            // Get current fill and stroke colors
            const currentFill = element.getAttribute('fill');
            const currentStroke = element.getAttribute('stroke');

            if (currentFill) {
                element.setAttribute('fill', colorMapper.mapColor(currentFill));
            }

            if (currentStroke) {
                element.setAttribute(
                    'stroke',
                    colorMapper.mapColor(currentStroke)
                );
            }
        });

        this.#updateCurrentFromNode(doc);

        return this;
    }

    /*
    Private Methods
    */
    #svgDocument(svgString = '') {
        const doc = this.#parser.parseFromString(
            svgString || this.#currXML,
            'image/svg+xml'
        );
        const errorNode = doc.querySelector('parsererror');
        if (errorNode) {
            throw new Error('Invalid SVG XML');
        }
        return doc;
    }
    #svgElement(svgString = '') {
        return this.#svgDocument(svgString || this.#currXML).documentElement;
    }

    #addViewBox() {
        const svgEl = this.#svgElement();
        let width = svgEl.getAttribute('width');
        let height = svgEl.getAttribute('height');

        if (width && height) {
            svgEl.setAttribute('viewBox', `0 0 ${width} ${height}`);
        }
        this.#updateCurrentFromNode(svgEl);
    }

    #hasViewBox() {
        const svgEl = this.#svgElement();
        return !!svgEl.getAttribute('viewBox');
    }

    #getBBox() {
        // temporary SVG element to calc the bounding box
        var tempSvg = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'svg'
        );
        tempSvg.style.visibility = 'hidden';
        tempSvg.style.position = 'absolute';

        document.body.appendChild(tempSvg);
        tempSvg.innerHTML = this.toString();
        const bBox = tempSvg.getBBox();
        document.body.removeChild(tempSvg);

        return bBox;
    }

    #updateCurrentFromNode(node) {
        this.#currXML = this.#serializer.serializeToString(node);
    }
}
