import React from 'react';
import { partition } from 'utils/arrays/arrayMethods';
import ItemList, {
    horizontalSpacingSizes,
    verticalSpacingSizes,
} from 'global/components/ItemList';
import AssetListItem from './AssetListItem';
import AssetTileGrid from './AssetTileGrid';
import AssetTile from './AssetTile';

/*
Description 
Displays a list of assets provided by the controller, splitting the assets by file/folder
________________________________________________________________________________________________________________

Props:

assets -> An array of assets (see requests/uniform-asset-data)
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const AssetDisplay = ({ assets = [] }) => {
    //If not an array input - treat as empty
    if (!Array.isArray(assets)) {
        assets = [];
    }

    if (assets.length === 0) {
        /*
        TBC - Needs Empty State - doesn't appear in 0.1 spec
        */
    }

    //Unsure where the files will be ordered later on
    //But safe to know that partition should preserve any ordering
    const [files, folders] = partition(
        assets,
        (asset) => asset.type !== 'folder'
    );

    return (
        <ItemList
            rowGapSize={verticalSpacingSizes.extraLarge}
            paddingSize={horizontalSpacingSizes.none}
        >
            {folders.length !== 0 && (
                <ItemList
                    rowGapSize={verticalSpacingSizes.xxSmall}
                    paddingSize={horizontalSpacingSizes.small}
                >
                    {folders.map((asset) => (
                        <AssetListItem key={asset.id} asset={asset} />
                    ))}
                </ItemList>
            )}
            {files.length !== 0 && (
                <AssetTileGrid>
                    {files.map((asset) => (
                        <AssetTile key={asset.id} asset={asset} />
                    ))}
                </AssetTileGrid>
            )}
        </ItemList>
    );
};

export default AssetDisplay;
