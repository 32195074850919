import { newErrMsg } from 'utils/messages/messages';
import { requestLayer } from 'requests/request-class';

import { prepareSVGDownload } from 'requests/preppers/prepare-download-sanitizer';
import { parseFetchAPIError } from 'requests/parsers/error-parsers';
import graphRequests from './graph-endpoints';
/*
Exports an instance/configuration of the request layer that handles 
general/misc. requests that don't fit in elsewhere
________________________________________________________________________________________________________________
*/

const generalRequests = new requestLayer();

generalRequests.configure({
    ///////  Asset Downloading   ///////

    downloadAsset: {
        request: async (req) => {
            const { asset, optimised } = req.payload;

            if (!asset) {
                throw { ...req, status: 400, error: newErrMsg('no asset') };
            }

            let downloadURL = asset.downloadURL;
            let thumbnailURL = asset.thumbnailURL;

            //Fetch download url if it doesn't exist and is needed
            if (!optimised && !downloadURL) {
                const res = await graphRequests.endpoints.driveItemDownloadURL(
                    {
                        driveId: asset.driveId,
                        itemId: asset.id,
                    },
                    req.signal
                );
                downloadURL = res.data;
            }

            //Fetch thumbnail url if it doesn't exist and is needed
            if (optimised && !thumbnailURL) {
                const res = await graphRequests.endpoints.driveItemThumbnailURL(
                    {
                        driveId: asset.driveId,
                        itemId: asset.id,
                    },
                    req.signal
                );
                thumbnailURL = res.data;
            }

            //Check asset type for download process
            switch (asset.type) {
                case 'image/svg+xml':
                    if (!downloadURL) {
                        throw {
                            ...req,
                            status: '404',
                            error: newErrMsg('Asset Not Found'),
                        };
                    }
                    return await generalRequests.endpoints.downloadSVG(
                        downloadURL,
                        req.signal
                    );
                default:
                    return await generalRequests.endpoints.downloadAsBase64(
                        optimised ? thumbnailURL : downloadURL,
                        req.signal
                    );
            }
        },
    },

    downloadSVG: {
        request: async (req) => {
            return graphRequests.endpoints.downloadDriveItem(
                {
                    url: req.payload,
                    contentType: 'text',
                },
                req.signal
            );
        },
        prepare: [prepareSVGDownload],
    },

    downloadAsBase64: {
        request: async (req) => {
            const response = await fetch(req.payload, { cache: 'no-cache' });

            if (!response.ok) {
                throw response;
            }

            const blob = await response.blob();

            return { ...req, status: response.status, data: blob };
        },
        errParser: parseFetchAPIError,
        resParser: async (res) => {
            return new Promise((resolve, reject) => {
                try {
                    //construct error response
                    const errRes = {
                        ...res,
                        data: null,
                        status: 501,
                        error: newErrMsg('Error parsing file blob'),
                    };

                    //configure up file reader
                    const reader = new FileReader();
                    reader.readAsDataURL(res.data);
                    reader.onloadend = () => {
                        //split up parsed data to extract name, base64 and type
                        const dataUrl = reader.result;
                        const base64String = dataUrl.split(',')[1];
                        const fileType = dataUrl.split(';')[0].split(':')[1];
                        const fileName = res.payload.substring(
                            res.payload.lastIndexOf('/') + 1
                        );

                        resolve({
                            ...res,
                            data: {
                                name: fileName,
                                type: fileType,
                                data: base64String,
                            },
                        });
                    };
                    reader.onerror = () => {
                        reject(errRes);
                    };
                } catch {
                    reject(errRes);
                }
            });
        },
    },
});

export default generalRequests;

export const { downloadSVG, downloadAsBase64, downloadAsset } =
    generalRequests.endpoints;
