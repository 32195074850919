import React from 'react';
import { useTranslation } from 'react-i18next';
import TutorialImage from 'firstRun/components/TutorialImage';
import TutorialTitle from 'firstRun/components/TutorialTitle';
import TutorialBody from 'firstRun/components/TutorialBody';
import { Button } from '@fluentui/react-components';
import { useTutorialStyles } from 'firstRun/hooks/firstRunStylesHook';
import { useNavigate } from 'react-router-dom';
import { useFooterState } from 'headerFooter/hooks/headerFooterStateHook';
import { useDispatch } from 'react-redux';
import { showFirstRunFlowThunk } from 'reduxStore/slices/userSettingsSlice';

/*
Tutorial page to tell users where to go if they need to edit a workspace
________________________________________________________________________________________________________________

Custom Components:

TutorialImage -> Component that manages the image topper for tutorials
TutorialTitle -> Component that manages the formatting for the title of the page
TutorialBody -> Component that manages the formatting of the body/tutorial text of the page
TutorialProgress -> Component for the progress bar at the bottom of the page
________________________________________________________________________________________________________________

IMPORTANT NOTES
    -> Button
        Uses the Primary button styling instead of the standard like the other pages
________________________________________________________________________________________________________________
*/

const UserPageFour = () => {
    const { t } = useTranslation();
    const classes = useTutorialStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useFooterState([{ type: 'updateProgress', value: 4, max: 4 }]);
    const handleButtonClick = () => {
        dispatch(showFirstRunFlowThunk(false))
        navigate('/teams');
    };

    return (
        <div className={classes.tutorialContentDiv}>
            <TutorialImage />
            <div className={classes.tutorialText}>
                <TutorialTitle title={t('tutorialEditYourWorkspace.title')} />
                <TutorialBody content={t('tutorialEditYourWorkspace.body')} />
            </div>
            <Button appearance="primary" onClick={handleButtonClick}>
                {t('buttons.brandIn')}
            </Button>
        </div>
    );
};

export default UserPageFour;
