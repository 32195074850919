import React from 'react';
import SettingsMenuTitle from 'settings/components/SettingsMenuTitle';
import { useTranslation } from 'react-i18next';
import WorkspaceList from 'home/components/WorkspaceList';
import { useSearchParams } from 'react-router-dom';
import { useHeaderState } from 'headerFooter/hooks/headerFooterStateHook';

/*
Renders the initial list of workspaces a user will interact with to navigate deeper into the app
________________________________________________________________________________________________________________

Custom Components:

SettingsMenuTitle -> Used to help render a general Title/subtitle structure throughout the app
WorkspaceList -> fetches and renders the workspace list 
________________________________________________________________________________________________________________

IMPORTANT NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const WorkspacesPage = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    useHeaderState([
        {
            type: 'disableButton',
            button: 'dismiss',
            enabled: searchParams.get('overlay') === 'true',
        },
    ]);

    return (
        <>
            <SettingsMenuTitle
                title={t('common.workspaces')}
                body={t('workspacesPage.body')}
            />
            <WorkspaceList />
        </>
    );
};

export default WorkspacesPage;
