// Account manager instance to allow it to persist.
import { AccountManager } from './authConfig';

const accountManager = new AccountManager();

Office.onReady(async (info) => {
    if(info.host){
        await accountManager.initialize();
    }
})

export default accountManager;
