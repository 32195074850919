// TaskPaneBody.js
import React from 'react';
import { Body1 } from '@fluentui/react-components';
import { useTutorialStyles } from 'firstRun/hooks/firstRunStylesHook';

/*
The TutorialBody component manages the content and styling of the body text inside tutorial pages.
________________________________________________________________________________________________________________

Props: 
content -> The text needed for the specified tutorial page. 
________________________________________________________________________________________________________________
*/

const TaskPaneBody = ({ content }) => {
    const classes = useTutorialStyles();
    return <Body1 className={classes.tutorialText}>{content}</Body1>;
};

export default TaskPaneBody;
