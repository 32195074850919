// TaskPaneBody.js
import React from 'react';
import { Image } from '@fluentui/react-components';
import { useTutorialStyles } from 'firstRun/hooks/firstRunStylesHook';

/*
The TutorialImage component simply manages the content and styling of the Brando image in tutorial pages.
________________________________________________________________________________________________________________

Props: 
None
________________________________________________________________________________________________________________
*/

const TutorialImage = () => {
    const classes = useTutorialStyles();
    return (
        <Image
            src="../../assets/Brand Light@2x.png"
            className={classes.tutorialImage}
        />
    );
};

export default TutorialImage;
