import {
    Menu,
    MenuButton,
    MenuPopover,
    MenuTrigger,
    Toolbar,
    ToolbarGroup,
} from '@fluentui/react-components';
import {
    ArrowSortDown20Regular,
    ChevronDown12Regular,
    Grid20Regular,
} from '@fluentui/react-icons';
import { useToolbarStyles } from 'assetNav/hooks/assetStylesHook';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AssetToolBCColour } from './AssetToolBCColour';

/*
Description 
renders the tool bar used to choose the ordering and filtering on the displayed assets
________________________________________________________________________________________________________________

Props:

Name -> Description
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> V0.1 Styling
        All buttons are disabled and not in use
________________________________________________________________________________________________________________
*/

const AssetNavToolbar = () => {
    const styles = useToolbarStyles();
    const { t } = useTranslation();
    return (
        <Toolbar className={styles.endGroup}>
            <ToolbarGroup>
                <Menu>
                    <MenuTrigger>
                        <MenuButton
                            disabled={true}
                            appearance="subtle"
                            icon={<ArrowSortDown20Regular />}
                            className={styles.sortButton}
                        >
                            {t('buttons.sort')}
                        </MenuButton>
                    </MenuTrigger>
                    <MenuPopover></MenuPopover>
                </Menu>
                <AssetToolBCColour />
            </ToolbarGroup>
            <ToolbarGroup>
                <Menu>
                    <MenuTrigger>
                        <MenuButton
                            disabled
                            appearance="subtle"
                            className={styles.viewButton}
                            icon={
                                <div className={styles.iconFlex}>
                                    <Grid20Regular
                                        className={styles.gridIcon}
                                    />
                                    <ChevronDown12Regular />
                                </div>
                            }
                        />
                    </MenuTrigger>
                    <MenuPopover></MenuPopover>
                </Menu>
            </ToolbarGroup>
        </Toolbar>
    );
};

export default AssetNavToolbar;
