/*
parses responses from DAMs into the standard library shape 
________________________________________________________________________________________________________________
*/

const defaultLibrary = () => ({
    name: '',
    friendly_id: null,
    type: '',
    sharepointURL: '',
});

//This parser is used when getting a list of libraries (drives) from the graph
export const libraryItemsResponseParser = (res) => {
    const data = res.data;
    try {
        //return the full response if not in the expected form
        if (!data?.value || !Array.isArray(data.value)) return res;

        //Convert drives into library assets
        const drives = data.value;
        const libAssets = drives
            .filter(filterSystemMadeDrives)
            .map(convertDriveToLibrary(res.payload.siteId));

        return {
            ...res,
            data: libAssets,
        };
    } catch {
        return res;
    }
};

const filterSystemMadeDrives = (drive) => {
    return drive.createdBy?.user?.displayName !== 'System Account';
};

const convertDriveToLibrary = (siteId) => (drive) => {
    const library = defaultLibrary();

    try {
        //Set up basic properties
        console.log(drive);
        library.friendly_id = drive.id;
        library.location = {
            origin: 'sharepoint',
            siteId: siteId,
            driveId: drive.id,
            webUrl: drive.webUrl,
        };

        //Determine the library type
        library.type = 'general';

        //Add name
        library.name = drive.name;

        return library;
    } catch {
        //Set library Flag 1 - error parsing ?
        library.flags += 1;
        return library;
    }
};
