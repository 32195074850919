import { useContext, useEffect } from 'react';
import {
    FooterStateContext,
    FooterStateDispatchContext,
} from 'headerFooter/contexts/footerStateContext';
import {
    HeaderStateContext,
    HeaderStateDispatchContext,
} from 'headerFooter/contexts/headerStateContext';

/*
The hooks provide access to the header and footer state and dispatch function (see context files for details)
In order to do so, they must be used within components below the header/footer providers.

The hooks will return the current state and dispatch function for their navBar.
You can also provide initial actions, which is an array of actions to dispatch on component mount.

NOTE: when the component unmounts the reset action is dispatched, which reverts the state to the location default
Typically, this means any actions dispatched only change the state for the life time of the component they are called from. 
Calling the hook from multiple components may cause odd behaviour if the component lifetimes are out of sync. 

Since the changes to the the header footer typically have the same lifetime as a page. It may be wise to
only call the hooks from components with synced mount/unmounts as the pages.
________________________________________________________________________________________________________________
*/

export const useFooterState = (initialActions = []) => {
    const dispatch = useContext(FooterStateDispatchContext);
    const footerState = useContext(FooterStateContext);
    useEffect(() => {
        if (initialActions.length > 0 && dispatch) {
            initialActions.map((action) => dispatch(action));
            return () => dispatch({ type: 'reset' });
        }
    }, []);

    return [footerState, dispatch];
};

export const useHeaderState = (initialActions = []) => {
    const dispatch = useContext(HeaderStateDispatchContext);
    const headerState = useContext(HeaderStateContext);
    useEffect(() => {
        if (initialActions.length > 0 && dispatch) {
            initialActions.map((action) => dispatch(action));
            return () => dispatch({ type: 'reset' });
        }
        return;
    }, []);

    return [headerState, dispatch];
};
