import React from 'react';
import { Subtitle2 } from '@fluentui/react-components';
import { useTutorialStyles } from 'firstRun/hooks/firstRunStylesHook';

/*
The TutorialTitle component manages the content and styling of the title text of tutorial pages.
________________________________________________________________________________________________________________

Props: 
Title -> The title of the current tutorial page. 
________________________________________________________________________________________________________________
*/

const TutorialTitle = ({ title }) => {
    const styles = useTutorialStyles();

    // return nothing if there is no title and body
    if (!title) return;

    return (
        <div className={styles.menuTitleWrapper}>
            <Subtitle2>{title}</Subtitle2>
        </div>
    );
};

export default TutorialTitle;
