import React from 'react';

/*
Description 
wrapper component used to stop click events from propagating up the dom tree
used in multiple places, often when the wider component has a click event & a more menu
________________________________________________________________________________________________________________

Props:

eventsType -> specifies which click events get stopped
className -> used to style the container that wraps the children
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const StopEventPropagation = ({
    children,
    eventsType = {},
    className = '',
}) => {
    return (
        <div {...eventsType} className={className}>
            {children}
        </div>
    );
};

export const eventStopperTypes = {
    allClickEvents: {
        onClick: (e) => {
            e.stopPropagation();
        },
        onMouseDown: (e) => e.stopPropagation(),
        onMouseUp: (e) => e.stopPropagation(),
        onContextMenu: (e) => e.stopPropagation(),
    },
    onClick: {
        onClick: (e) => {
            e.stopPropagation();
        },
    },
};

export default StopEventPropagation;
