// TaskPaneBody.js
import React from 'react';
import { Image } from '@fluentui/react-components';
import { useSplashStyles } from 'login/hooks/loginStylesHook';

/*
The TutorialImage component simply manages the content and styling of the Brando image in tutorial pages.
________________________________________________________________________________________________________________

Props: 
None
________________________________________________________________________________________________________________
*/

const SplashImage = () => {
    const classes = useSplashStyles();
    return (
        <Image src="../../assets/welcome.svg" className={classes.splashImage} />
    );
};

export default SplashImage;
