import { Subtitle2 } from '@fluentui/react-components';
import AssetDisplay from 'assetNav/components/AssetDisplay';
import AssetNavToolbar from 'assetNav/components/AssetNavToolbar';
import { useSearchStyles } from 'assetNav/hooks/assetStylesHook';
import ItemList, {
    horizontalSpacingSizes,
    verticalSpacingSizes,
} from 'global/components/ItemList';
import { useHeaderState } from 'headerFooter/hooks/headerFooterStateHook';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { selectLibraryById } from 'reduxStore/slices/teamSettingsSlice';
import graphRequests from 'requests/endpoints/graph-endpoints';

/*
Description 
Requests and Displays the asset library search results
________________________________________________________________________________________________________________

Custom Components:

ItemList -> Handles basic list rendering
AssetNavToolbar -> tool bar for filtering and reordering lists
AssetDisplay -> displays a list of assets, separating out into file/folder displays
________________________________________________________________________________________________________________

IMPORTANT NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/
const searchStates = {
    idle: 'idle',
    searching: 'searching',
    success: 'success',
    error: 'error',
};

const AssetSearchPage = () => {
    const { libraryId } = useParams();
    const styles = useSearchStyles();
    const { t } = useTranslation();

    //Get library from redux cache
    const library = useSelector(selectLibraryById(libraryId));
    const libraryName = library?.name?.value || t('common.library');

    //Get search text from url query params
    const [searchParams] = useSearchParams();
    const searchText = searchParams.get('searchText');

    //REPLACE: should be graph call to get search assets
    const [assets, setAssets] = useState([]);
    const [searchState, setSearchState] = useState(
        searchText ? searchStates.searching : searchStates.idle
    );

    //Set the header state on load
    const [_, dispatch] = useHeaderState([
        {
            type: 'openSearch',
            open: true,
            placeholder: t('assetSearchPage.placeholder', {
                library: libraryName,
            }),
        },
    ]);

    useEffect(() => {
        const controller = new AbortController();
        const searchText = searchParams.get('searchText');
        setAssets([]);
        if (searchText && typeof searchText === 'string') {
            setSearchState(searchStates.searching);
            graphRequests.endpoints
                .searchGraphItems(
                    {
                        driveId: library.location.driveId,
                        searchText: searchText,
                    },
                    controller.signal
                )
                .then((res) => {
                    setAssets(res.data);
                    setSearchState(searchStates.success);
                })
                .catch((err) => {
                    setSearchState(searchStates.error);
                    console.log(err);
                });
        } else {
            setSearchState(searchStates.idle);
        }

        return () => controller.abort();
    }, [searchParams, library]);

    let loadingText;

    switch (searchState) {
        case searchStates.error:
            loadingText = t('assetSearchPage.error', { library: libraryName });
            break;
        case searchStates.idle:
            loadingText = t('assetSearchPage.idle', { library: libraryName });
            break;
        case searchStates.searching:
            loadingText = t('assetSearchPage.searching', {
                library: libraryName,
            });
            break;
        case searchStates.success:
            loadingText = t('assetSearchPage.empty', { library: libraryName });
    }

    return (
        <ItemList
            rowGapSize={verticalSpacingSizes.extraLarge}
            paddingSize={horizontalSpacingSizes.none}
            marginBefore={verticalSpacingSizes.extraLarge}
        >
            <AssetNavToolbar />

            {searchState === searchStates.success && assets?.length ? (
                <AssetDisplay assets={assets} />
            ) : (
                    <Subtitle2 className={styles.searchStateText}>
                        {loadingText}
                    </Subtitle2>
            )}
        </ItemList>
    );
};

export default AssetSearchPage;
