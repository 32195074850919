import React from 'react';
import { useListStyles } from 'global/hooks/globalStylesHook';

/*
Description 
Container for multiple asset tile components so they render as a flexible grid view
________________________________________________________________________________________________________________

Props:

children -> the react child components - should be <AssetTile/>s
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const AssetTileGrid = ({ children }) => {
    const styles = useListStyles();

    return <div className={styles.assetGridMedium}>{children}</div>;
};

export default AssetTileGrid;
