import React from 'react';
import { useListStyles } from 'global/hooks/globalStylesHook';
import { tokens } from '@fluentui/react-components';

/*
Generic wrapper for rendering child items in a 1 column list
the row gap, side padding and top margin can all be custom configured
________________________________________________________________________________________________________________

Props:

rowGapSize -> custom row gap size, set using one of the verticalSpacingSizes (see fluent docs for pixel sizes)
paddingSize -> custom side padding size, set using one of the verticalSpacingSizes (see fluent docs for pixel sizes)
marginBefore -> custom top margin size, set using one of the verticalSpacingSizes (see fluent docs for pixel sizes)
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

export const verticalSpacingSizes = {
    none: 'spacingVerticalNone',
    xxSmall: 'spacingVerticalXXS',
    xSmall: 'spacingVerticalXS',
    small: 'spacingVerticalS',
    medium: 'spacingVerticalM',
    large: 'spacingVerticalL',
    extraLarge: 'spacingVerticalXL',
};

export const horizontalSpacingSizes = {
    none: 'spacingHorizontalNone',
    xxSmall: 'spacingHorizontalXXS',
    xSmall: 'spacingHorizontalXS',
    small: 'spacingHorizontalS',
    medium: 'spacingHorizontalM',
    large: 'spacingHorizontalL',
    extraLarge: 'spacingHorizontalXL',
};

const ItemList = ({
    children,
    rowGapSize = verticalSpacingSizes.medium,
    paddingSize = horizontalSpacingSizes.large,
    marginBefore = verticalSpacingSizes.none,
}) => {
    const styles = useListStyles();
    return (
        <div
            className={styles.itemList}
            style={{
                rowGap: tokens[rowGapSize],
                paddingLeft: tokens[paddingSize],
                paddingRight: tokens[paddingSize],
                marginTop: tokens[marginBefore],
            }}
        >
            {children}
        </div>
    );
};

// Controls the spacing and team of list items by using css grid
export default ItemList;
