import ErrorElement from 'global/pages/GeneralErrorPage';
import GlobalLayout from 'headerFooter/pages/GlobalLayout';
import SignUpPage from 'login/pages/SignUpPage';
import SplashScreen from 'login/pages/SplashScreen';
import React from 'react';
import { signUpLoader } from './loginLoaders';

/*
The routes used to show the login and sign up pages
________________________________________________________________________________________________________________

Navigated Pages:

SplashScreen -> /
SignUpPage -> /signUp
________________________________________________________________________________________________________________
*/

export const loginRoutes = [
    // Login Routes

    //This route can be used to validate a user and navigate to the right landing page
    // Signed in user vs First Time login etc.
    {
        path: '/',
        element: <GlobalLayout layoutLocation="login" />,
        children: [
            {
                errorElement: <ErrorElement />,
                children: [
                    {
                        index: true,
                        element: <SplashScreen />,
                    },
                ],
            },
        ],
    },
    { path: '/signUp', element: <SignUpPage />, loader: signUpLoader },
];
