import React from 'react';
import { createRoot } from 'react-dom/client';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import topLevelRoutes from 'global/routes/topLevelRoutes';
import { BCThemeProvider } from 'global/contexts/themeContext';
import './localisation/i18nSetup';
import { Provider } from 'react-redux';
import store from 'reduxStore/store';
import { AccountManagerProvider } from '../naa/accountManagerContext';
import { ToasterProvider } from 'global/contexts/toasterContext';
import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';

/*
Create Hash router. This intercept all url navigations.
url navigation is performed in the Hash portion of the URL
This can be changed to the regular part of the URl but requires the server to handle 
page get requests on page reload. This avoids that, and since users don't have access to
the url in the taskpane, it doesn't hinder url readability.
*/

/*
Create react root. 
This is the place all react elements get rendered below. 
In this case, we have an element in the HTML with ID="root". 
*/

const router = createHashRouter(topLevelRoutes);
const root = createRoot(document.getElementById('root'));

//Add the initial render of the application to the root
/*
React.strict mode is used in development to help enforce idempotent code
It forces components to be rendered twice. All rendering logic should be Idempotent, 
i.e. it shouldn't matter how many times it is called, it should return the same info.
Rendering twice calls the component function twice, and it should result in the same UI
as if it was run once
*/
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BCThemeProvider>
                <ToasterProvider>
                    <AccountManagerProvider>
                        <RouterProvider router={router} />
                    </AccountManagerProvider>
                </ToasterProvider>
            </BCThemeProvider>
        </Provider>
    </React.StrictMode>
);
