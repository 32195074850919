import { makeStyles, tokens, shorthands } from '@fluentui/react-components';

/*
Used to style any components that are either used in many different places, or exist toward the root of the DOM tree
________________________________________________________________________________________________________________

Components Styled Here

ApplyToBody -> UseBackgroundStyles -> styles the size/position/colour of the background Body component

HeaderFooterLayout -> useGlobalStyles -> makes all content (header and footer included) non selectable, and
                                        Positions content
PublishSettingsMsgBar -> useGlobalStyles -> styles the title and body text of the global messages

ModalComponent -> useModalStyles -> used for pane position ad styling, plus internal grid based stylings

ModalComponent -> useButtonStyles -> forces text to render on a single line

WorkspaceEditForm -> useButtonStyles -> forces text to render on a single line

SettingsAdminLibrariesPage -> useButtonStyles -> forces text to render on a single line

SettingsAdminTemplatesPage -> useButtonStyles -> forces text to render on a single line

SettingsAdminWorkspacesPage -> useButtonStyles -> forces text to render on a single line

SettingsMenuItemCard -> useButtonStyles -> disable button

AssetTileGrid -> useListStyles ->  grid stylings for asset tiles

BadgeMessageBar -> useListStyles -> rendering flexible list of messages in card

ButtonList -> useListStyles -> rendering flexible list of button in a row

ItemList -> useListStyles -> supplies basic flex properties


________________________________________________________________________________________________________________
*/

const useBackgroundStyle = makeStyles({
    globalBackgroundColour: {
        backgroundColor: tokens.colorNeutralBackground2,
    },
    bodyStyling: {
        backgroundColor: tokens.colorNeutralBackground2,
        ...shorthands.margin('0px'),
    },
});

const useGlobalStyles = makeStyles({
    // Navbar size, position and padding

    globalContentContainer: {
        left: '0px',
        right: '0px',
        paddingBottom: '24px',
    },

    globalContentTopExtend: {
        paddingTop: '48px',
    },
    globalContentBottomExtend: {
        paddingBottom: '48px',
    },

    globalMargins: {
        marginLeft: '16px',
        marginRight: '16px',
    },
    globalMessageFont: {
        fontSize: tokens.fontSizeBase200,
    },

    //Disables UI Text selection Globally
    // Doesn't interfere with fluent Input - user can select their own text
    unselectable: {
        userSelect: 'none',
        WebkitUserSelect: 'none',
    },

    //OPt in mechanism for selection
    selectText: {
        userSelect: 'text',
        WebkitUserSelect: 'text',
    },
});

const useModalWindowStyles = makeStyles({
    modalWindow: {
        ...shorthands.padding('16px'),
        width: '270px',
        minHeight: '132px',
        rowGap: '12px',
        columnGap: '12px',
        border: '8px',
        opacity: '0.9',
        boxShadow: '0px 0px 8px 0px #00000033',
    },

    buttonLayout: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '238px',
        minHeight: '32px',
        rowGap: '306px',
        columnGap: '306px',
        opacity: '0px',
        marginLeft: '24px',
        marginRight: '24px',
    },

    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end', // Right-aligns buttons
        width: '100%', // Full width of parent container
        minHeight: '32px',
        rowGap: '8px',
        columnGap: '8px',
        flexDirection: 'row',
        gridColumnStart: '1',
        gridColumnEnd: '4',
    },

    confirmButton: {
        minWidth: '66px',
        minHeight: '32px',
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '12px',
        paddingRight: '12px',
        backgroundColor: '#B61B88',
        color: '#FFFFFF',
        rowGap: '8px',
        columnGap: '8px',
    },
});

const useButtonsStyles = makeStyles({
    disabled: {
        color: tokens.colorNeutralForegroundDisabled,
    },
    //Sets the with of the text to the max width
    // that snugly fits the content
    singleLineText: {
        width: 'max-content',
        minWidth: '32px',
        height: '32px',
    },
});

const useInputStyles = makeStyles({
    block: {
        display: 'block',
    },
    marginTop2: {
        marginTop: '2px',
    },
    marginBottom4: {
        marginBottom: '4px',
    },
    width100: {
        width: '100%',
    },
    borderError: {
        borderColor: tokens.colorStatusDangerBorder1,
    },
    colorCaption: {
        color: tokens.colorNeutralForeground3,
    },
});

const useMessageStyles = makeStyles({
    marginTop2: {
        marginTop: '2px',
    },
    gridSpacing: {
        display: 'grid',
        gridTemplateColumns: '12px 1fr',
        columnGap: '2px',
    },

    colorError: {
        color: tokens.colorStatusDangerForeground1,
    },

    colorWarning: {
        color: tokens.colorStatusWarningForeground1,
    },

    colorSuccess: {
        color: tokens.colorStatusSuccessForeground1,
    },

    colorCaption: {
        color: tokens.colorNeutralForeground3,
    },
});

const useListStyles = makeStyles({
    buttonList: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        alignItems: 'center',
        columnGap: '8px',
    },
    badgeList: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '4px',
        rowGap: '4px',
        flexWrap: 'wrap',
        width: '100%',
    },
    itemList: {
        display: 'flex',
        flexDirection: 'column',
    },
    listEdgePaddingL: {
        paddingLeft: tokens.spacingHorizontalL,
        paddingRight: tokens.spacingHorizontalL,
    },
    assetGridMedium: {
        display: 'grid',
        gridTemplateColumns: 'repeat( auto-fill, minmax(128px, 1fr) )',
        paddingRight: '8px',
        paddingLeft: '8px',
        columnGap: '12px',
        justifyItems: 'center',
        alignItems: 'center',
    },
});

const useTextThemes = makeStyles({
    rtlText: (lang) => ({
        direction: lang === 'ar' ? 'rtl' : 'ltr',
        textAlign: lang === 'ar' ? 'right' : 'left',
    }),
});

export {
    useBackgroundStyle,
    useButtonsStyles,
    useGlobalStyles,
    useInputStyles,
    useListStyles,
    useMessageStyles,
    useModalWindowStyles,
    useTextThemes,
};
