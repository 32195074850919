import { Button, makeStyles } from '@fluentui/react-components';
import React from 'react';

/*
Handles the help, privacy and EULA buttons that appear in the footer during login
________________________________________________________________________________________________________________

Props:

Name -> Description
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const usePolicyStyles = makeStyles({
    buttonSize: {
        minWidth:0,
        minHeight:0,
        maxHeight: 64,
        width: 'max-content',
        height:32
    }
})

const FooterPolicyButtons = ({ policyState }) => {
    const styles = usePolicyStyles()
    if (!policyState.visible) return null;

    return (
        <>
            <Button
                className={styles.buttonSize}
                size='small'
                appearance="subtle"
            >
                Help
            </Button>
            <Button
                  className={styles.buttonSize}
                size='small'
                appearance="subtle"
            >
                Privacy
            </Button>
            <Button
                  className={styles.buttonSize}
                size='small'
                appearance="subtle"
            >
                EULA
            </Button>
        </>
    );
};

export default FooterPolicyButtons;
