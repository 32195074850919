import { Breadcrumb } from '@fluentui/react-components';
import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/*
Manages navigation state for breadcrumbs:
breadcrumbs stored in url location state so they persist over reloads
Hook works by returning a version of the react-router navigate function that updates the location state,
maintaining current breadcrumbs, or reverting back.
________________________________________________________________________________________________________________
*/

export const useNavigateBreadcrumbs = (name, libraryId, folderId) => {
    const navigate = useNavigate();
    const location = useLocation();

    const newNavigate = useCallback(
        (to, options) => {
            //Check if the options/state are correct
            if (options && !(options instanceof Object)) {
                throw new Error('Options must be an object');
            }
            const oldOptions = options || {};
            if (options?.state && !(options.state instanceof Object)) {
                throw new Error('State must be an object');
            }
            const oldState = oldOptions?.state || {};

            //Get current breadcrumbs
            const currBreadcrumbs = Array.isArray(location?.state?.breadcrumbs)
                ? [...location?.state?.breadcrumbs]
                : [];

            //Check if the breadcrumb already exists
            const duplicateIndex = currBreadcrumbs.findIndex((breadcrumb) => {
                return (
                    breadcrumb.libraryId === libraryId &&
                    breadcrumb.folderId === folderId
                );
            });

            if (duplicateIndex !== -1) {
                //remove all breadcrumbs after existing breadcrumb if duplicate
                currBreadcrumbs.splice(duplicateIndex + 1);
            } else {
                //push new breadcrumb
                currBreadcrumbs.push({
                    name: name,
                    libraryId: libraryId,
                    folderId: folderId,
                });
            }

            const newOptions = {
                ...oldOptions,
                state: { ...oldState, breadcrumbs: currBreadcrumbs },
            };

            navigate(to, newOptions);
        },
        [name, libraryId, folderId]
    );

    return newNavigate;
};
