import {
    Button,
    Toast,
    ToastBody,
    ToastTitle,
    ToastTrigger,
    useToastController,
} from '@fluentui/react-components';
import { Dismiss16Regular } from '@fluentui/react-icons';
import { GlobalToasterId } from 'global/contexts/toasterContext';
import React, { useContext } from 'react';

export const useToasterDispatch = () => {
    const toasterId = useContext(GlobalToasterId);
    const { dispatchToast } = useToastController(toasterId);

    return (titleText = '', bodyText = '', intent = '') => {
        dispatchToast(
            <Toast style={{alignItems:'center'}}>
                <ToastTitle
                    action={
                        <ToastTrigger>
                            <Button
                                size='small'
                                icon={<Dismiss16Regular />}
                                appearance="transparent"
                            />
                        </ToastTrigger>
                    }
                >
                    {titleText}
                </ToastTitle>
                {bodyText && <ToastBody>{bodyText}</ToastBody>}
            </Toast>,
            {
                intent: intent,
            }
        );
    };
};
