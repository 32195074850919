import React from 'react';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import GlobalNotifications from 'global/components/GlobalNotifications';
import { useGlobalStyles } from 'global/hooks/globalStylesHook';
import FooterStateProvider from 'headerFooter/contexts/footerStateContext';
import HeaderStateProvider from 'headerFooter/contexts/headerStateContext';
import {
    useFooterState,
    useHeaderState,
} from 'headerFooter/hooks/headerFooterStateHook';
import { mergeClasses } from '@fluentui/react-components';

/*
Wraps child components in the standard app-wide layout.
---------
|header |
|-------|
|content|
|-------|
|footer-|
---------
Provides access to the header/footer contexts too.
________________________________________________________________________________________________________________

Props:

children -> react children to be rendered as content in the layout
location -> used to configure the default header/footer states
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const HeaderFooterLayout = ({ children, location = 'home' }) => {
    const styles = useGlobalStyles();

    return (
        <div className={styles.unselectable}>
            <FooterStateProvider key={location} location={location}>
                <HeaderStateProvider key={location} location={location}>
                    <GlobalContentContainer>{children}</GlobalContentContainer>
                    <AppHeader />
                    <AppFooter />
                </HeaderStateProvider>
            </FooterStateProvider>
        </div>
    );
};

const GlobalContentContainer = ({ children }) => {
    const [headerState] = useHeaderState();
    const [footerState] = useFooterState();
    const styles = useGlobalStyles();

    return (
        <div
            className={mergeClasses(
                styles.globalContentContainer,
                headerState.visible && styles.globalContentTopExtend,
                footerState.visible && styles.globalContentBottomExtend
            )}
        >
            <GlobalNotifications />
            {children}
        </div>
    );
};

export default HeaderFooterLayout;
