export class bcColorMapper {
    #colorMaps;
    #currentMap;
    constructor() {
        this.#colorMaps = {
            toTeal: {
                '#FFDCF7': '#D5F8F9',
                '#F5A1DF': '#9DEEF1',
                '#EC66C7': '#65E5E9',
                '#E22BAF': '#2DDBE1',
                '#BD1A8D': '#28A4A8',
                '#87176C': '#236C6E',
                '#51154B': '#1E3535',
            },
            toGold: {
                '#FFDCF7': '#FFF3CF',
                '#F5A1DF': '#FFE38F',
                '#EC66C7': '#FFD24E',
                '#E22BAF': '#FFC20E',
                '#BD1A8D': '#BE9317',
                '#87176C': '#7E6321',
                '#51154B': '#3D342A',
            },
            toGrey: {
                '#FFDCF7': '#EAEAEA',
                '#F5A1DF': '#C6C6C6',
                '#EC66C7': '#A3A3A3',
                '#E22BAF': '#7F7F7F',
                '#BD1A8D': '#6A6A6A',
                '#87176C': '#545454',
                '#51154B': '#3F3F3F',
            },
            toPink: {
                '#FFDCF7': '#FFDCF7',
                '#F5A1DF': '#F5A1DF',
                '#EC66C7': '#EC66C7',
                '#E22BAF': '#E22BAF',
                '#BD1A8D': '#BD1A8D',
                '#87176C': '#87176C',
                '#51154B': '#51154B',
            },
        };
        this.#currentMap = this.#colorMaps.toPink;
    }

    setColorMap(mapName) {
        const colorMapName = mapName === 'default' ? this.defaultMap : mapName;

        if (this.#colorMaps[colorMapName]) {
            this.#currentMap = this.#colorMaps[colorMapName];
        } else {
            throw new Error(`Color map ${colorMapName} not found.`);
        }
    }

    colourMapToMainHex(mapName) {
        switch (mapName) {
            case 'toPink':
                return '#BD1A8D';
            case 'toGrey':
                return '#545454';
            case 'toGold':
                return '#FFD24E';
            case 'toTeal':
                return '#65E5E9';
            default:
                throw new Error(`Color map ${mapName} not found.`);
        }
    }

    colorMapNames() {
        return Object.keys(this.#colorMaps);
    }

    mapColor(color) {
        if (color && this.#currentMap && this.#currentMap[color]) {
            return this.#currentMap[color];
        }
        return color;
    }

    set defaultMap(colourMap = 'toPink') {
        bcColorMapper.defaultColour = colourMap;
    }

    get defaultMap() {
        return bcColorMapper.defaultColour || 'toPink';
    }
}
