import {
    Button,
    Menu,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@fluentui/react-components';
import React from 'react';
import { Question20Regular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';
import { useHeaderFooterStyles } from 'headerFooter/hooks/headerFooterStylesHook';
import links from '../../linkManagement/links.json';

/*
Renders the help menu button which gives access to the admin emails, help desk and ticking system
________________________________________________________________________________________________________________

Props:

helpMenuState -> object {visible:bool, enabled:bool} used to indicate how the button renders
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const FooterHelpMenu = ({ helpMenuState }) => {
    const { t } = useTranslation();
    const styles = useHeaderFooterStyles();

    const handleEmailClick = () => {
        window.open(links.general.supportEmail);
    };

    if (!helpMenuState.visible) return;

    return (
        <Menu>
            <MenuTrigger>
                <Button
                    disabled={!helpMenuState.enabled}
                    appearance="subtle"
                    className={styles.navButton}
                    size="medium"
                    icon={<Question20Regular />}
                />
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    <MenuItem onClick={handleEmailClick}>
                        {t('footer.helpMenu.emailProduct')}
                    </MenuItem>
                    <MenuDivider />
                    <a
                        href={links.general.knowledgeBase}
                        className={styles.menuItem}
                        target="_blank"
                    >
                        <MenuItem>
                            {t('footer.helpMenu.knowledgeBase')}
                        </MenuItem>
                    </a>
                    {/* <a
                        href={links.general.contactSupport}
                        className={styles.menuItem}
                        target="_blank"
                    >
                        <MenuItem>{t('footer.helpMenu.support')}</MenuItem>
                    </a> */}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};

export default FooterHelpMenu;
