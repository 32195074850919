import React, { useState, useEffect } from 'react';
import { Button } from '@fluentui/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHeaderFooterStyles } from 'headerFooter/hooks/headerFooterStylesHook';
import links from '../../linkManagement/links.json';
import { userSettings } from 'reduxStore/slices/userSettingsSlice';

/*
Description 
________________________________________________________________________________________________________________

Props:
None
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTES
    -> Icons
        Two icon options, both compliant, slight differences. 
________________________________________________________________________________________________________________
*/

const FooterBCButton = () => {
    const styles = useHeaderFooterStyles();
    return (
        <a href={links.general.bcWebsite} target="_blank">
            <Button
                appearance="subtle"
                className={styles.navButton}
                size="medium"
                icon={<img src="../../assets/BC logo.svg" alt="Icon" />}
            />
        </a>
    );
};

export default FooterBCButton;
