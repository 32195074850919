import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getUserPreferences,
    updateUserPreferences,
} from 'requests/endpoints/bc-server-endpoints';
import { newErrMsg } from 'utils/messages/messages';
import cacheValidation from 'utils/validation/cacheValidation';
import { newSetting } from './teamSettingsSlice';
/*
user settings are unique to a specific user and are editable by that user only.
The structure is very shallow, taking really basic key:value pairs.
.
e.g.

state = {
    ...settings
}
where settings is a list of key, value pairs

List of all user settings
-> Dark Mode
-> Language

*/

// Generate the default user preferences
// Also defines how the redux slice should look.
// Can be used to generate brand new set of data.
const userInitial = () => ({
    cacheValid: newSetting('bool', false),
    darkMode: newSetting('bool', detectSystemDarkMode()),
    systemDarkMode: newSetting('bool', true), // Default to using system settings
    showFirstRun: newSetting('bool', null),
    autoLogin: newSetting('bool', true),
    lang: newSetting('langChr', 'en-US'),
    devMode: newSetting('bool', false),
});

export const detectSystemDarkMode = () => {
    return (
        typeof window !== 'undefined' &&
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
    );
};

////////   Request Thunks     //////
export const userPreferencesThunk = createAsyncThunk(
    'user/preferences',
    async (arg, thunkAPI) => {
        try {
            if (
                cacheValidation.userPreferences.validator(
                    thunkAPI.getState().userSettings
                )
            ) {
                return 'cacheValid';
            }
            const res = await getUserPreferences(arg, thunkAPI.signal);
            return res.data;
        } catch (e) {
            console.log(e);
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const toggleDarkModeThunk = createAsyncThunk(
    'user/darkModeToggle',
    async (arg, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleDarkMode());
            thunkAPI.dispatch(setSystemDarkMode(false)); // Disable system dark mode when manually toggling
            const currDarkMode =
                thunkAPI.getState().userSettings.darkMode.value;
            await updateUserPreferences(
                {
                    teamId: '83ecc22f-8059-4a4f-be66-e485f5fc8553',
                    preferences: {
                        dark_mode: currDarkMode,
                        system_dark_mode: false, // Ensure the system dark mode is turned off
                    },
                },
                thunkAPI.signal
            );
        } catch (e) {
            console.log(e);
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const toggleSystemDarkModeThunk = createAsyncThunk(
    'user/systemDarkModeToggle',
    async (arg, thunkAPI) => {
        try {
            thunkAPI.dispatch(toggleSystemDarkMode());
            const systemDarkModeEnabled =
                thunkAPI.getState().userSettings.systemDarkMode.value;
            await updateUserPreferences(
                {
                    teamId: '83ecc22f-8059-4a4f-be66-e485f5fc8553',
                    preferences: { system_dark_mode: systemDarkModeEnabled },
                },
                thunkAPI.signal
            );
        } catch (e) {
            console.log(e);
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const showFirstRunFlowThunk = createAsyncThunk(
    'user/firstRunToggle',
    async (arg, thunkAPI) => {
        try {
            thunkAPI.dispatch(showFirstRunFlow(arg));
            await updateUserPreferences(
                {
                    preferences: { show_first_time_help: arg },
                },
                thunkAPI.signal
            );
            //Request has succeeded
            return;
        } catch (e) {
            //Reject request errors
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const userSettings = createSlice({
    name: 'userSettings',
    initialState: userInitial(),

    reducers: {
        toggleDarkMode: (state) => {
            state.darkMode.value = !state.darkMode.value;
        },
        showFirstRunFlow: (state, action) => {
            state.showFirstRun.value = action.payload;
        },
        setDarkMode: (state, action) => {
            state.darkMode.value = action.payload;
        },
        toggleSystemDarkMode: (state) => {
            state.systemDarkMode.value = !state.systemDarkMode.value;
            if (state.systemDarkMode.value) {
                state.darkMode.value = detectSystemDarkMode();
            }
        },
        setSystemDarkMode: (state, action) => {
            state.systemDarkMode.value = action.payload;
        },
        setLanguage: (state, action) => {
            //Check if the action payload is in the correct format
            // Ideally, we would have a list of allowed languages to check against.
            state.lang.value = action.payload;
        },
        clearUserSettingsCache: () => {
            return userInitial();
        },
        updateAutoLogin: (state, action) => {
            state.autoLogin.value = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(userPreferencesThunk.fulfilled, (state, action) => {
                if (action.payload === 'cacheValid') return;
                const preferences = action.payload.preferences;
                state.systemDarkMode.value =
                    preferences.system_dark_mode ?? true;
                if (preferences.system_dark_mode == false) {
                    state.darkMode.value =
                        preferences.dark_mode ?? detectSystemDarkMode();
                }
                state.showFirstRun.value =
                    preferences['show_first_time_help'] || false;
                // Set devMode from preferences if applicable
                state.devMode.value = preferences['dev_mode'] || false;
                state.cacheValid.value = true;
            })
            .addCase(toggleDarkModeThunk.rejected, (state, action) => {
                // Handle failed preference update
                state.darkMode.msgs.push(
                    newErrMsg('Failed to update preferences')
                );
            })
            .addCase(toggleSystemDarkModeThunk.rejected, (state, action) => {
                state.systemDarkMode.msgs.push(
                    newErrMsg('Failed to update preferences')
                );
            });
    },
});

export const {
    toggleDarkMode,
    setDarkMode,
    toggleSystemDarkMode,
    setSystemDarkMode,
    showFirstRunFlow,
    setLanguage,
    clearUserSettingsCache,
    loadPreferencesFromServer,
    updateAutoLogin,
} = userSettings.actions;
export default userSettings.reducer;
