import { makeStyles, tokens, shorthands } from '@fluentui/react-components';

/*
Used to style components in the home (workspace/newfile) pages
________________________________________________________________________________________________________________

Components Styled Here

HomeListItem -> useHomeListItemStyles -> position/interactivity/colouring of the list item component

HomeWorkspaceTitle -> useHomeWorkspaceTitleStyles -> grid based positioning of text/icon/overlay


________________________________________________________________________________________________________________
*/

export const useHomeListItemStyles = makeStyles({
    homeListItem: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '8px',
        alignItems: 'center',
        height: '24px',
        paddingLeft: '6px',
        paddingRight: '6px',
        paddingTop: '5px',
        paddingBottom: '5px',
        ...shorthands.borderRadius('4px'),
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground2Hover,
        },
        ':active': {
            backgroundColor: tokens.colorNeutralBackground2Pressed,
        },
        cursor:'pointer',
        '& > label':{ cursor:'pointer',}
    },
    homeListItemIcon: {
        color: tokens.colorBrandForeground1,
    },

});

export const useHomeWorkspaceTitleStyles = makeStyles({
    homeWorkspaceTitle: {
        marginTop: '20px',
        display: 'grid',
        gridTemplateColumns: '32px auto',
        columnGap: '12px',
        alignItems: 'center',
        ...shorthands.padding('8px'),
        ...shorthands.borderRadius('8px'),
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground2Hover,
        },
        ':active': {
            backgroundColor: tokens.colorNeutralBackground2Pressed,
        },
        cursor:'pointer',
        '& > label':{ cursor:'pointer',}
    },
    homeWorkspaceTitleImage: {
        height: '32px',
        width: '32px',
        ...shorthands.borderRadius('4px'),
        gridColumnStart: 1,
        gridRowStart: 1,
    },
    iconOverlay: {
        display: 'flex',
        gridColumnStart: 1,
        gridRowStart: 1,
        width: '32px',
        height: '32px',
        ...shorthands.borderRadius('4px'),
        backgroundColor: tokens.colorNeutralBackgroundAlpha2,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
    },
});

export const useHomeTabStyles = makeStyles({
    tabList: {
        marginLeft: '-4px',
    },
});
