import React from 'react';
import { useTranslation } from 'react-i18next';
import TutorialImage from 'firstRun/components/TutorialImage';
import TutorialTitle from 'firstRun/components/TutorialTitle';
import TutorialBody from 'firstRun/components/TutorialBody';
import { Button } from '@fluentui/react-components';
import { useTutorialStyles } from 'firstRun/hooks/firstRunStylesHook';
import { useNavigate } from 'react-router-dom';
import {
    useFooterState,
    useHeaderState,
} from 'headerFooter/hooks/headerFooterStateHook';

/*
Tutorial to explain the term "Workspaces" to users
________________________________________________________________________________________________________________

Custom Components:

TutorialImage -> Component that manages the image topper for tutorials
TutorialTitle -> Component that manages the formatting for the title of the page
TutorialBody -> Component that manages the formatting of the body/tutorial text of the page
TutorialProgress -> Component for the progress bar at the bottom of the page
________________________________________________________________________________________________________________

IMPORTANT NOTES
    -> Progress bar
        Each page of the tutorial has two versions of the progress bar. One for going forward and one backwards
________________________________________________________________________________________________________________
*/

const UserPageOne = () => {
    const { t } = useTranslation();
    const classes = useTutorialStyles();
    const navigate = useNavigate();
    useFooterState([{ type: 'updateProgress', value: 1, max: 4 }]);
    useHeaderState([
        { type: 'disableButton', button: 'backNav', enabled: false },
    ]);
    const handleButtonClick = () => {
        navigate('/userPageTwo');
    };

    return (
        <div className={classes.tutorialContentDiv}>
            <TutorialImage />
            <div className={classes.tutorialText}>
                <TutorialTitle title={t('tutorialWorkspacePage.title')} />
                <TutorialBody content={t('tutorialWorkspacePage.body')} />
            </div>
            <Button onClick={handleButtonClick}>{t('buttons.next')}</Button>
        </div>
    );
};

export default UserPageOne;
