import { makeStyles, tokens, shorthands } from '@fluentui/react-components';

/*
Description 
________________________________________________________________________________________________________________

Components Styled Here

Component Name -> Style Name -> Description
________________________________________________________________________________________________________________
*/

const useSplashStyles = makeStyles({
    splashContentDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '85vh',
        rowGap: '20px',
        minHeight: '400px',
    },
    splashText: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
        textAlign: 'center',
        width: '240px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    splashImage: {
        '@media screen and (min-height:593px)': {
            height: '200px',
            width: '200px',
        },
        '@media screen and (max-height:592px)': {
            height: '100px',
            width: '100px',
        },
        transitionProperty: 'width height',
        transitionDuration: '300ms',
    },
    spinnerContainer: {
        size: 'extra-large',
        position: 'absolute',
        top: '50vh',
        left: '50vw',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
    },
});

export const useSignUpStyles = makeStyles({
    signUpPage: {
        boxSizing: 'border-box',
        width: '100vw',
        height: '100vh',
        minHeight: '760px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '68px 0px',
    },
    signUpContent: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '24px',
        alignItems: 'center',
    },
    signUpImage: {
        '@media (max-height: 859px)': {
            height: '200px',
            width: '200px',
        },
        '@media (min-height: 860px)': {
            height: '300px',
            width: '300px',
        },
    },
    signUpText: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 32px',
        maxWidth: '360px',
        rowGap: '16px',
        alignItems: 'center',
    },
    signUpFooter: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '12px',
        alignItems: 'center',
        maxWidth: '420px',
    },
});

export { useSplashStyles };
