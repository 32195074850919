import { Button } from '@fluentui/react-components';
import { Navigation20Regular } from '@fluentui/react-icons';
import { useHeaderFooterStyles } from 'headerFooter/hooks/headerFooterStylesHook';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

/*
Description 
The navigation menu used within the asset navigation to bring up the libraries overlay
________________________________________________________________________________________________________________

Props:

navMenuState -> object {visible:bool, enabled:bool} used to indicate how the button renders
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const HeaderNavMenu = ({ navMenuState }) => {
    const styles = useHeaderFooterStyles();
    const navigate = useNavigate();
    const { teamId, workspaceId } = useParams();

    if (!navMenuState.visible) return;
    return (
        <Button
            disabled={!navMenuState.enabled}
            appearance="subtle"
            className={styles.navButton}
            icon={<Navigation20Regular />}
            size="medium"
            onClick={() => {
                //URL for assets /workspaces/workspaceId/libraryId/fileId?
                //URL for home page /workspaces/workspaceId
                //navigate('..', {relative:'path}) doesn't work, so need to make the URL
                navigate(`/teams/${teamId}/${workspaceId}` + '?overlay=true');
            }}
        />
    );
};

export default HeaderNavMenu;
