import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import HeaderFooterLayout from 'headerFooter/components/HeaderFooterLayout';

/*
Wrapper component for providing the header footer layout for pages, as well as handling scroll reset
________________________________________________________________________________________________________________

Custom Components:

HeaderFooterLayout -> adds the basic header footer layout around child routes/pages
________________________________________________________________________________________________________________

IMPORTANT NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const GlobalLayout = ({ layoutLocation = 'home' }) => {
    const urlLocation = useLocation();

    //Effect to reset the scroll when the user navigates
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [urlLocation]);

    return (
        <HeaderFooterLayout location={layoutLocation}>
            <Outlet />
        </HeaderFooterLayout>
    );
};

export default GlobalLayout;
