import {
    Avatar,
    Menu,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { clearChangeLog } from 'reduxStore/slices/changeLogSlice';
import {
    clearTeamSettingsCache,
    selectTeams,
} from 'reduxStore/slices/teamSettingsSlice';
import { clearUserSettingsCache, updateAutoLogin } from 'reduxStore/slices/userSettingsSlice';
import { getUserInfo } from 'requests/endpoints/graph-endpoints';
import accountManager from '../../../naa/accountManager';

/*
Renders the user's personal avatar. Is used to indicate login in status and to initiate log out flow
________________________________________________________________________________________________________________

Props:

avatarState -> object {visible:bool} used to indicate how the button renders
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const HeaderAvatar = ({ avatarState }) => {
    const [imgURL, setImgURL] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const teams = useSelector(selectTeams);
    const { teamId } = useParams();
    //Requests the user data and gets the icon blob.
    useEffect(() => {
        const controller = new AbortController();
        getUserInfo(undefined, controller.signal)
            .then((res) => {
                //code from https://learn.microsoft.com/en-us/graph/api/profilephoto-get?view=graph-rest-1.0&tabs=http
                //converts blob response to URL so we can display it
                const url = window.URL || window.webkitURL;
                const blobUrl = url.createObjectURL(res.data.photo);
                setImgURL(blobUrl);
            })
            .catch((e) => console.log(e));

        return () => controller.abort();
    }, []);

    if (!avatarState.visible) return;

    return (
        <Menu>
            <MenuTrigger>
                <Avatar
                    style={{
                        cursor: 'pointer',
                    }}
                    image={{ src: imgURL }}
                />
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    {teams?.allIds?.length !== 1 && teamId && (
                        <>
                            <MenuItem
                                onClick={() => navigate('/teams?overlay=true')}
                            >
                                Swap team
                            </MenuItem>
                            <MenuDivider />
                        </>
                    )}
                    <MenuItem
                        onClick={() => {
                            accountManager.resetActiveAccount();
                            //Stops the user from being able to use the 'back' button (history index used as .length is unreliable)
                            navigate(-history.state.idx);
                            //Clear the redux cache
                            dispatch(clearUserSettingsCache());
                            dispatch(clearTeamSettingsCache());
                            dispatch(clearChangeLog());
                            dispatch(updateAutoLogin(false))
                        }}
                    >
                        Log out
                    </MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};

export default HeaderAvatar;
