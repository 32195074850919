import React from 'react';

import DevTestPage from 'global/pages/DevTestPage';
import store from 'reduxStore/store';

import ErrorElement from 'global/pages/GeneralErrorPage';
import Error404Page from 'global/pages/Error404Page';
import { userPreferencesThunk } from 'reduxStore/slices/userSettingsSlice';
import { loginRoutes } from 'login/routes/loginRoutes';
import { homeRoutes } from 'home/routes/homeRoutes';
import { settingsRoutes } from 'settings/routes/settingsRoutes';
import { firstRunRoutes } from 'firstRun/routes/firstRunRoutes';
import { assetNavRoutes } from 'assetNav/routes/assetNavRoutes';
import cacheValidation from 'utils/validation/cacheValidation';

/*
Handles the top layer of routes, and is generally responsible for global error structure.
________________________________________________________________________________________________________________

Navigated Pages:

Error404Page -> "*" -> wildcard match for any unresolved matches
________________________________________________________________________________________________________________
*/

/*
a route is an object, of the following type

interface RouteObject {
  path?: string;
  index?: boolean;
  children?: React.ReactNode;
  caseSensitive?: boolean;
  id?: string;
  loader?: LoaderFunction;
  action?: ActionFunction;
  element?: React.ReactNode | null;
  hydrateFallbackElement?: React.ReactNode | null;
  errorElement?: React.ReactNode | null;
  Component?: React.ComponentType | null;
  HydrateFallback?: React.ComponentType | null;
  ErrorBoundary?: React.ComponentType | null;
  handle?: RouteObject["handle"];
  shouldRevalidate?: ShouldRevalidateFunction;
  lazy?: LazyRouteFunction<RouteObject>;
}

the most common set up of a route is 
{
    path:'/path/to/page',
    element: <Page/>
}

which just specifies what page to render when the url
matches the path property.

the path property can take in dynamic segments, prefixed by :
take the path'workspaces/:id'
urls 'workspaces/1', 'workspaces/23' match the route.
the dynamic segment can then be accessed by the rendered page


Routes can nested using the children property
{
    path:'path/to/page'
    element: <Page/>
    children:[
        {
            path:'relative/path/to/child'
            element: <Child/>
        }
    ]
}
The page component must have an <Outlet/> component.
This specifies where <Child/> will render when the path matches


Sometimes you want a default display at the <Outlet/> component.
Index routes are used as the default child displayed
{
    path:'path/to/page'
    element: <Page/>
    children:[
        {
            index:true,
            element:<Default/>
        },

        {
            path:'relative/path/to/child'
            element: <Child/>
        }
    ]
}


A layout route is a route without a path.
Layout routes play no role in route matching but use the syntax
of nested routes to specify how/where routes should render. 
Can be used to display fixed screens for all child routes.

The default value of the element property is <Outlet/>
Routes without the element prop can be used with index routes to
group and extract routes to different files

*/

//////////// LOADERS ///////////////

/* 
This Loader is called whenever the user first loads into the app, and fetches everything that is
necessary for basic operation

Can probably be refactored later into a batch request to get all the information needed on load
 */
const globalLoader = async () => {
    //Load user preferences from the server and cache them
    //Thunk loading errors handled internally

    await store.dispatch(userPreferencesThunk());

    return null;
};

//////////// Route Config  ///////////////
const topLevelRoutes = [
    //Global Error handler
    {
        errorElement: <ErrorElement />,
        children: [
            ...loginRoutes,
            firstRunRoutes,
            {
                loader: globalLoader,
                children: [
                    {
                        path: '/devtest',
                        element: <DevTestPage />,
                    },
                    homeRoutes,
                    settingsRoutes,
                    assetNavRoutes,
                ],
            },
        ],
    },
    {
        //Catch all route for rendering 404 page
        //Note: <a> tags will send a request to the serve if a relative link is supplied. Use react router navigate instead.
        //      Below doesn't handle 404 responses from a server for all url get requests.
        path: '*',
        element: <Error404Page />,
    },
];

export default topLevelRoutes;
