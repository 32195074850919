//Settings pages
import React from 'react';
import GlobalLayout from 'headerFooter/pages/GlobalLayout';
import ErrorElement from 'global/pages/GeneralErrorPage';
import SettingsHomePage from 'settings/pages/SettingsHomePage';

//Handles Settings navigation, excluding brand profile
export const settingsRoutes = {
    element: <GlobalLayout layoutLocation="settings" />,
    errorElement: <ErrorElement />,
    children: [
        {
            errorElement: <ErrorElement />,
            children: [
                {
                    //Should this be /teams/:teamId/settings - can teams/id be optional or query params?
                    path: '/settings',
                    children: [
                        {
                            index: true,
                            element: <SettingsHomePage />,
                        },

                        // {
                        //     path: 'admin',
                        //     loader: async () => {
                        //         //Check if the user is actually and admin, if not redirect to just the settings
                        //         return null;
                        //     },
                        //     children: [
                        //         {
                        //             index: true,
                        //             element: <SettingsAdminHomePage />,
                        //         },
                        //         {
                        //             path: 'workspaces',
                        //             element: <SettingsAdminWorkspacesPage />,
                        //         },
                        //         {
                        //             path: 'workspaces/:workspaceId?/edit',
                        //             element: <SettingsAdminWorkspaceEditPage />,
                        //         },
                        //         {
                        //             path: 'workspaces/:workspaceId',
                        //             element: <SettingsAdminWorkspacePage />,
                        //         },
                        //         {
                        //             path: 'workspaces/:workspaceId/features',
                        //             element: <SettingsAdminWorkspaceFeaturesPage />,
                        //         },
                        //         {
                        //             path: 'workspaces/:workspaceId/libraries',
                        //             element: <SettingsAdminLibrariesPage />,
                        //         },
                        //         {
                        //             path: 'workspaces/:workspaceId/libraries/add',
                        //             children: [
                        //                 {
                        //                     index: true,
                        //                     element: <SettingsAdminAddLibraryPage />,
                        //                 },
                        //                 {
                        //                     path: 'new',
                        //                     element: <SettingsAdminAddNewLibraryPage />,
                        //                 },
                        //                 {
                        //                     path: 'sp',
                        //                     element: <SettingsAdminAddSPLibraryPage />,
                        //                 },
                        //                 {
                        //                     path: 'existing',
                        //                     element: (
                        //                         <SettingsAdminAddExistingLibraryPage />
                        //                     ),
                        //                 },
                        //             ],
                        //         },
                        //         {
                        //             path: 'workspaces/:workspaceId/templates',
                        //             element: <SettingsAdminTemplatesPage />,
                        //         },
                        //     ],
                        // },
                    ],
                },
            ],
        },
    ],
};
