/*
Before we pass asset data from the graph to the view, we should strip it of all the details that are superfluous,
and set a standard for how the data is to be received in the view. 

It is hard to know how we should support other dams in the future, so the standard will be based around the 
drive item type from the Graph API. The language will change slightly and some facets of the asset will take on different roles,
so the Asset doesn't just apply to a single DAM.

The proposed standard is as follows:
An asset will be represented by a object literal with the following structure:
{
    id: null,
    name: '',
    type: '',
    origin: '',
    driveId: null,
    webURL: '',
    downloadURL: '',
    thumbnailURL: '',
    image: {
        aspectRatio: -1,
    },
    icon: {
        safeXML: '',
        aspectRatio: -1,
        colour: 'default',
    },
    searchResult: null,
    media: null,
    flags: 0,
}

id - string/number
The id will uniquely identify the asset in the DAM - this could be a local identifier 
(e.g. item id only unique up to drive id in SharePoint) or a global one. 

name - string
This is the display name of the asset, and will be rendered in the UI. 

type - string
This will be assigned an asset type. This could relate to either an asset file type - png, jpg, mp4, etc.
or it could be used to indicate the asset is a folder.

origin - string
This stores where the asset came from - for MVP this will just be SharePoint. The UI will be 
agnostic to the origin of the data when rendering it, so storing the origin will allow downwind
logic to decide how to handle the asset (How do you open a folder? - you need to request child items from the right origin)
- Could this extend into an object if we need to store more information? Could I store drive/site id here as well?

webURL - string
This is the URl that will be used to open the asset in an external browser

downloadURL - string
This will be used to download the asset for insertion or general use - e.g. Re-colourable Icons

thumbnail - obj
Data relating to the thumbnail of the asset:
- height in pixels
- width in pixels
- src url string

searchResult - obj
meta data that is non null if the asset is the result of a search.

media - obj
meta data that is non null if the asset is a media type (sound/video),
will include data like media duration.

flags - number
a set of bit wise flags we can assign to the object if needed.
for example, if we detect that the asset may be malicious, then we can add
a flag, which we can check for before inserting/rendering/requesting any of its details. 
________________________________________________________________________________________________________________
*/

const defaultAsset = () => ({
    id: null,
    name: '',
    type: '',
    origin: '',
    driveId: null,
    webURL: '',
    downloadURL: '',
    thumbnailURL: '',
    image: {
        aspectRatio: -1,
    },
    icon: {
        safeXML: '',
        aspectRatio: -1,
        colour: 'default',
    },
    searchResult: null,
    media: null,
    flags: 0,
});

//This parser is used when the expected success response is a list of drive items from the graph
export const driveItemsResponseParser = (res) => {
    const data = res.data;
    try {
        //return the full response if not in the expected form
        if (!data?.value || !Array.isArray(data.value)) return res;

        //Convert the drive items into assets
        const driveItems = data.value;
        const assets = driveItems
            .map(convertDriveItemToAsset)
            .filter(filterSupportedMimeTypes)
            .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase());

        //Return array of assets
        return {
            ...res,
            data: assets,
        };
    } catch {
        return res;
    }
};

const filterSupportedMimeTypes = (asset) => {
    switch (asset.type) {
        case 'folder':
        case 'image/svg+xml':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'image/tiff':
        case 'image/gif':
        case 'image/png':
        case 'image/jpeg':
            return true;
        default:
            return false;
    }
};

const convertDriveItemToAsset = (driveItem) => {
    const asset = defaultAsset();

    try {
        //Set up basic properties
        asset.id = driveItem.id;

        //Determine the asset type
        if (driveItem.file) {
            asset.type = driveItem.file.mimeType;
        } else if (driveItem.folder) {
            asset.type = 'folder';
        } else {
            asset.type = 'unknown';
        }

        //Add name and remove extension
        asset.name = formatAssetName(driveItem.name, asset.type);

        //Set up links to asset at its origin
        asset.origin = 'SharePoint';
        asset.driveId = driveItem.parentReference?.driveId;
        asset.webURL = driveItem.webUrl;
        asset.downloadURL = driveItem['@microsoft.graph.downloadUrl'];

        //Configure the thumbnail
        if (driveItem.file && driveItem.thumbnails) {
            const spThumbnail = driveItem.thumbnails[0]['c1920x1920'];
            asset.thumbnailURL = spThumbnail.url;
        }

        //Attach meta data
        asset.searchResult = driveItem.searchResult;

        if (driveItem.video) {
            asset.media = driveItem.video;
        }
        return asset;
    } catch {
        //Set Asset Flag 1 - error parsing ?
        asset.flags += 1;
        return asset;
    }
};

const formatAssetName = (name, type) => {
    let retName = '';
    retName = removeFileExtension(name, type);
    retName = removeNumberPrefix(retName);
    return retName;
};

//Removing the BI1 file/folder prefixes
function removeNumberPrefix(text) {
    return text.replace(/^\d+\.\s*/, '');
}

//Removing file extension based on reported mime type to ensure we don't remove important information
//by just removing all text past the last period if the file extension is missing (Brandin.1.0.2 => Brandin.1.0 etc)
const removeFileExtension = (name, type) => {
    let fileExtensions = getFileExtensionsByMimeType(type);

    if (!fileExtensions) return name;

    /*
    Construct a regular expression that searches for any of the file extension per mime type. 
    It searches using a short circuiting OR syntax. 
    e.g.
    \.((ext1) | (ext2) | (ext2))$
    \ - escapes the . special regex character -> match starts with .
    $ - modifier to indicate that we are searching at the end of the string
    a|b|c - finds match checking a then b then c. Stops when match found.
    */

    let regexString = fileExtensions.reduce((prev, curr, i) => {
        return prev + `${i === 0 ? '' : '|'}(${curr})`;
    }, '\\.(');
    regexString += ')$';

    const extRegex = new RegExp(regexString);

    return name.replace(extRegex, '');
};

const getFileExtensionsByMimeType = (type = '') => {
    switch (type) {
        case 'audio/mpeg':
            return ['mp3'];
        case 'video/mp4':
            return ['mp4'];
        case 'text/plain':
            return ['txt'];
        case 'image/svg+xml':
            return ['svg'];
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return ['pptx'];
        case 'application/vnd.openxmlformats-officedocument.presentationml.template':
            return ['potx'];
        case 'image/tiff':
            return ['tiff', 'tif'];
        case 'image/gif':
            return ['gif'];
        case 'image/png':
            return ['png'];
        case 'image/jpeg':
            return ['jpg', 'jpeg'];
        default:
            return false;
    }
};
