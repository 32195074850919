import { useHeaderState } from './headerFooterStateHook';

/*
This hook is used to determine if a component is hidden by the header. 
It functions in 2 modes:
1. Fraction - returns what fraction of the component is visible - triggers many state updates on scroll
2. Threshold - rounds the return to either 0 or 1 based on a threshold value:
    e.g. if threshold is 0.6, 
    visibility >=0.6 => round to 1
    visibility < 0.6 => round to 0.
    Only triggers state change when return changes from 0/1

If no threshold is specified, then it runs in fraction mode.

The hook initially assumes the component is visible by default - this can always be changed later with an extra argument

The function also returns a ref, which should be passed into the ref prop of the component you want to check
- Be wary of ref forwarding for custom components
________________________________________________________________________________________________________________
*/

const { useState, useEffect, useRef } = require('react');

export const useHeaderVisibility = (threshold = null) => {
    const [fraction, setFraction] = useState(1);
    const currentElement = useRef(null);
    const [headerState, _] = useHeaderState();

    useEffect(() => {
        const onScroll = () => {
            if (!currentElement.current) {
                setFraction(1);
                return;
            }
            const elTop = currentElement.current.getBoundingClientRect().top;
            const elStyle = window.getComputedStyle(currentElement.current);
            const elTopPad = +elStyle.paddingTop.replace('px', '');
            const elHeight = +elStyle.height.replace('px', '');

            const scTop = headerState.visible ? 48 : 0;

            if (scTop - elTop - elTopPad <= 0) {
                setFraction(1);
                return;
            }

            if (scTop - elTop - elTopPad >= elHeight) {
                setFraction(0);
                return;
            }

            const fraction = 1 - (scTop - elTop - elTopPad) / elHeight;

            if (threshold) {
                setFraction(+(fraction >= threshold));
                return;
            }

            setFraction(fraction);
        };

        window.addEventListener('scroll', onScroll);
        onScroll();
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return [fraction, currentElement];
};
