import React from 'react';
import { useSelector } from 'react-redux';
import { Badge, CardFooter } from '@fluentui/react-components';
import { msgTypes } from 'utils/messages/messages';
import {
    CheckmarkCircle16Regular,
    ErrorCircle16Regular,
    Warning16Regular,
} from '@fluentui/react-icons';
import { useListStyles } from 'global/hooks/globalStylesHook';

/*
In the spec, badges are used for error communication and to guide the user through the UI to the location of the error

The below component is used to display the badges, typically in the footer of a card component. It is a very simple component,
simply acting as a flex box for the buttons.
________________________________________________________________________________________________________________

Props:

msgs -> list of msg objects to display in the badge component
selector -> redux selector that returns list of msg objects
msgFilter -> a function that takes in a list of msgs and returns a list of msgs - can be used to alter how messages are displayed
cardFooter -> specifies if the Msg bar should be returned wrapped in a Card Footer Component
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const BadgeMessageBar = ({
    msgs = [],
    selector = (state) => null,
    msgFilters = [],
    cardFooter = false,
}) => {
    const reduxMessages = useSelector(selector || ((state) => null));
    const styles = useListStyles();

    let allMsgs = msgs.concat(reduxMessages || []);

    if (allMsgs.length === 0) return null;

    if (msgFilters.length) {
        allMsgs = msgFilters.reduce((acc, filter) => filter(acc), allMsgs);
    }

    if (cardFooter) {
        return (
            <CardFooter>
                <div className={styles.badgeList}>
                    {allMsgs.map(getMsgBadge)}
                </div>
            </CardFooter>
        );
    }
    return <div className={styles.badgeList}>{allMsgs.map(getMsgBadge)}</div>;
};

//This function takes in a message and returns a badge component if it can
const getMsgBadge = (msg) => {
    //Exit if there is no msg type or id
    if (!msg?.type || !msg?.id) return null;

    switch (msg.type) {
        case msgTypes.err:
            return <ErrorBadge text={msg.text} key={msg.id} />;
        case msgTypes.warn:
            return <WarningBadge text={msg.text} key={msg.id} />;
        case msgTypes.succ:
            return <SuccessBadge text={msg.text} key={msg.id} />;
        case msgTypes.info:
            return <InformationBadge text={msg.text} key={msg.id} />;
        default:
            return <BrandBadge text={msg.text} key={msg.id} />;
    }
};

const ErrorBadge = ({ text }) => {
    return (
        <Badge color="danger" appearance="tint" icon={<ErrorCircle16Regular />}>
            {text}
        </Badge>
    );
};

const WarningBadge = ({ text }) => {
    return (
        <Badge appearance="tint" color="warning" icon={<Warning16Regular />}>
            {text}
        </Badge>
    );
};

const SuccessBadge = ({ text }) => {
    return (
        <Badge
            appearance="tint"
            color="success"
            icon={<CheckmarkCircle16Regular />}
        >
            {text}
        </Badge>
    );
};

const InformationBadge = ({ text }) => {
    return (
        <Badge appearance="tint" color="informative">
            {text}
        </Badge>
    );
};

const BrandBadge = ({ text }) => {
    return (
        <Badge appearance="tint" color="brand">
            {text}
        </Badge>
    );
};

export default BadgeMessageBar;
