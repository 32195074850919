import React from 'react';
import PublishSettingsMsgBar from 'settings/components/PublishSettingsMsgBar';
import { MessageBarGroup } from '@fluentui/react-components';

/*
A container component that handles app wide notifications

This isn't going to be a full notification centre, sitting at the top of the page and not on a separate page. 
it is going to be a limited set of notifications:
 - Settings need publish/reset
 - Error/issue Found in settings
 - Network Error (offline)

Therefore, It doesn't make sense to build out a full notification structure, but rather build uncontrolled components for each message.
The down side to this would be that the components always appear in the same order. It could be good for muscle memory, but doesn't give
any notion of "Newest/latest notification"
________________________________________________________________________________________________________________

Props:

None
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const GlobalNotifications = () => {
    return (
        <MessageBarGroup animate="exit-only">
            <PublishSettingsMsgBar />
        </MessageBarGroup>
    );
};

export default GlobalNotifications;
