import {
    Menu,
    MenuTrigger,
    Spinner,
    mergeClasses,
} from '@fluentui/react-components';
import { useAssetStyles } from 'assetNav/hooks/assetStylesHook';
import { useToasterDispatch } from 'global/hooks/toastDispatchHook';
import { insertAsset, insertTypes } from 'officeAPI/insertingAssets';
import React, { useCallback, useState } from 'react';
import AssetLabelToolTip from './AssetLabelToolTip';
import AssetMoreMenu, { AssetMenuPopover } from './AssetMoreMenu';
import AssetTilePreview from './AssetTilePreview';

/*
Description 
One of the different asset display types, this component renders an asset as a tile, with a medium thumbnail 
________________________________________________________________________________________________________________

Props: 

asset -> a single asset object (see requests/uniform-asset-data)
________________________________________________________________________________________________________________

IMPORTANT STYLING NOTE
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const AssetTile = ({ asset }) => {
    const styles = useAssetStyles();
    //Asset state we can attach extra details about like aspect ratio, svg text, etc.
    const [assetState, setAssetState] = useState(asset);

    //used to conditionally render more menu
    const [showMore, setShowMore] = useState(false);

    //Controls the active state of the tile
    const [mouseDown, setMouseDown] = useState(false);

    //Controls if the visual hover state should appear outside of mouse hover event
    const [mockHover, setMockHover] = useState(false);

    //Controls the inserting state of the tile
    // Will probably be connected to a hook later on to
    //handle insertion lifetime and interact with controller
    const [inserting, setInserting] = useState(false);
    const sendToast = useToasterDispatch();

    const insertAssetCallback = useCallback(
        (asset, insertType = insertTypes.default, iconColour = 'default') => {
            //Update the icon colour
            asset = {
                ...asset,
                icon: { ...asset.icon, colour: iconColour },
            };
            setInserting(true);
            insertAsset(asset, insertType)
                .catch((e) => {
                    sendToast(e.text, '', e.type);
                })
                .finally(() => setInserting(false));
        },
        []
    );

    return (
        /* Grid Layout Div of the tile - handles hover/click events + position of children*/
        <Menu openOnContext closeOnScroll>
            <MenuTrigger>
                <div
                    onMouseEnter={() => {
                        setShowMore(true);
                    }}
                    onMouseLeave={() => {
                        setMouseDown(false);
                        setShowMore(false);
                    }}
                    onMouseDown={() => setMouseDown(true)}
                    onMouseUp={() => setMouseDown(false)}
                    onContextMenu={() => {
                        setMouseDown(false);
                    }}
                    onClick={() => {
                        insertAssetCallback(assetState);
                    }}
                    className={mergeClasses(
                        styles.assetTile,
                        mouseDown && styles.assetTileActive,
                        (inserting || mockHover) && styles.assetTileInserting
                    )}
                >
                    {/* Renders a preview of the asset - TO BE REPLACED with generic asset preview component */}
                    <AssetTilePreview
                        asset={assetState}
                        setAsset={setAssetState}
                    />
                    {inserting && (
                        <>
                            <div
                                className={mergeClasses(
                                    styles.previewPosition,
                                    styles.previewOverlay
                                )}
                            />
                            <Spinner
                                size="large"
                                className={styles.previewPosition}
                            />
                        </>
                    )}
                    <AssetLabelToolTip
                        labelText={assetState.name}
                        labelClassName={styles.assetDescription}
                        disabled={inserting}
                    />
                    {/* More Menu to appear on hover */}
                    {showMore && (
                        <AssetMoreMenu
                            disabled={inserting}
                            asset={assetState}
                            insertAsset={insertAssetCallback}
                            btnClassName={styles.assetMoreMenu}
                            isMenuOpen={setMockHover}
                        />
                    )}
                </div>
            </MenuTrigger>
            <AssetMenuPopover
                asset={assetState}
                insertAsset={insertAssetCallback}
                isMenuOpen={setMockHover}
            />
        </Menu>
    );
};

export default AssetTile;
