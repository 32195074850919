import React, { useEffect, useState } from 'react';
import AssetDisplay from 'assetNav/components/AssetDisplay';
import ItemList, {
    horizontalSpacingSizes,
    verticalSpacingSizes,
} from 'global/components/ItemList';
import { useTranslation } from 'react-i18next';
import AssetNavToolbar from 'assetNav/components/AssetNavToolbar';
import AssetNavBreadcrumbs from 'assetNav/components/AssetNavBreadcrumbs';
import { useParams } from 'react-router-dom';
import graphRequests from 'requests/endpoints/graph-endpoints';
import { useSelector } from 'react-redux';
import { selectLibraryById } from 'reduxStore/slices/teamSettingsSlice';
import { AnimatePresence, motion } from 'framer-motion';
import EmptyStateTitle from 'home/components/emptyStates/EmptyStateTitle';
import EmptyStateBody from 'home/components/emptyStates/EmptyStateBody';
import { Button } from '@fluentui/react-components';
import { useEmptyStateStyles } from 'home/hooks/emptyStateStylesHook';
import EmptyStateImage from 'home/components/emptyStates/EmptyStateImage';

/*
Description 
The asset navigation page, responsible for displaying and navigating the assets within a library.
Acts as a file navigation page
________________________________________________________________________________________________________________

Custom Components:

ItemList -> used for rendering generic lists
AssetNavBreadcrumbs -> renders the navigation breadcrumbs
AssetNavToolbar -> renders a dummy tool bar for filtering/sorting
AssetDisplay -> main display component for assets
________________________________________________________________________________________________________________

IMPORTANT NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const assetStates = {
    loading: 'loading',
    error: 'error',
    loaded: 'loaded',
};

const AssetNavPage = () => {
    //View Change Logic - not in 0.1 version
    const { folderId, libraryId } = useParams();
    const classes = useEmptyStateStyles();
    const library = useSelector(selectLibraryById(libraryId));
    const { t } = useTranslation();

    //Request logic for graph items
    const [assets, setAssets] = useState({
        state: assetStates.loading,
        data: null,
    });
    useEffect(() => {
        setAssets({
            state: assetStates.loading,
            data: null,
        });
        graphRequests.endpoints
            .getGraphItems({
                folderId: folderId || null,
                driveId: library?.location?.driveId || null,
                siteIf: library?.location?.siteId || null,
            })
            .then((res) => {
                setAssets({
                    state: assetStates.loaded,
                    data: res.data,
                });
            })
            .catch((e) => {
                console.log(e);
                setAssets({
                    state: assetStates.error,
                    data: null,
                });
            });
    }, [folderId, libraryId]);

    const handleButtonClick = async () => {
        try {
            console.log(library);
            if (!folderId && library.location.webUrl) {
                window.open(library.location.webUrl, '_blank')?.focus();
                return;
            }

            if (!folderId) return;

            const res = await graphRequests.endpoints.driveItemShareLink({
                id: folderId,
                driveId: libraryId,
            });
            window.open(res.data, '_blank')?.focus();
        } catch (e) {
            //failed to fetch URL
            console.log(e);
        }
    };

    return (
        <ItemList
            rowGapSize={verticalSpacingSizes.extraLarge}
            paddingSize={horizontalSpacingSizes.none}
        >
            <AssetNavBreadcrumbs />
            <AssetNavToolbar />

            <AnimatePresence mode="popLayout">
                {assets.state === assetStates.loaded && assets.data?.length && (
                    <motion.div
                        key={assets.data[0].id}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.25 }}
                        exit={{ opacity: 0 }}
                    >
                        <AssetDisplay assets={assets.data} />
                    </motion.div>
                )}
                {assets.state === assetStates.loaded &&
                    !assets.data?.length && (
                        <motion.div
                            key={'assetEmptyState'}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.25 }}
                            exit={{ opacity: 0 }}
                            className={classes.emptyStateWrapper}
                        >
                            <div className={classes.emptyStateContentDiv}>
                                <EmptyStateImage
                                    className={classes.tutorialImage}
                                />
                                <div className={classes.emptyStateText}>
                                    <EmptyStateTitle
                                        title={t('emptyFolder.title')}
                                    />
                                    <EmptyStateBody
                                        content={t('emptyFolder.body')}
                                    />
                                </div>
                                <Button
                                    appearance="primary"
                                    onClick={handleButtonClick}
                                >
                                    {t('buttons.openSP')}
                                </Button>
                            </div>
                        </motion.div>
                    )}
            </AnimatePresence>
        </ItemList>
    );
};

export default AssetNavPage;
