/*
Helper functions for requesting and caching data in route loaders
________________________________________________________________________________________________________________
*/

import {
    librariesThunk,
    teamsThunk,
    workspacesThunk,
} from 'reduxStore/slices/teamSettingsSlice';

export const requestAndCacheData = async (
    store,
    params = null,
    getTeams = false,
    getWorkspaces = false,
    getLibraries = false
) => {
    if (!store) throw new Error('Store missing');
    if (getTeams) {
        try {
            await store.dispatch(teamsThunk()).unwrap();
        } catch (e) {
            throw new Error('Failed to find team');
        }
    }
    if (getWorkspaces) {
        try {
            await store
                .dispatch(workspacesThunk({ teamId: params.teamId }))
                .unwrap();
        } catch (e) {
            throw new Error('Failed to find workspaces');
        }
    }
    if (getLibraries) {
        try {
            await store
                .dispatch(
                    librariesThunk({
                        workspaceId: params.workspaceId,
                    })
                )
                .unwrap();
        } catch (e) {
            throw new Error('Failed to find libraries');
        }
    }
};
