import {
    Button,
    Spinner,
    Subtitle1,
    Subtitle2,
} from '@fluentui/react-components';
import TutorialBody from 'firstRun/components/TutorialBody';
import TutorialImage from 'firstRun/components/TutorialImage';
import SplashImage from 'login/components/SplashImage';
import { useSplashStyles } from 'login/hooks/loginStylesHook';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    updateAutoLogin,
    userPreferencesThunk,
} from 'reduxStore/slices/userSettingsSlice';
import Alert from 'global/components/ModalComponent';

/*
This is the page the app opens onto on load. It has the button for SSO
________________________________________________________________________________________________________________
Custom Components:
TutorialImage -> Component that manages the image topper for tutorials
TutorialTitle -> Component that manages the formatting for the title of the page
TutorialBody -> Component that manages the formatting of the body/tutorial text of the page
________________________________________________________________________________________________________________
IMPORTANT NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const logInStates = {
    rest: 'rest',
    error: 'error',
    loggingIn: 'loggingIn',
    loading: 'loading',
};

const SplashScreen = () => {
    const { t } = useTranslation();
    const classes = useSplashStyles();
    const navigate = useNavigate();
    const [loginState, setLogInState] = useState(logInStates.loading);
    const dispatch = useDispatch();
    const autoLogin = useSelector((state) => state.userSettings.autoLogin);

    const closeAlert = () => setLogInState(loginState.rest);

    const login = useCallback(
        async (silentLogin = false) => {
            await dispatch(
                userPreferencesThunk({ silentOnly: silentLogin })
            ).unwrap();
            dispatch(updateAutoLogin(true));
            navigate('/UserPageOne', {
                state: { originPage: 'previous page' },
            });
        },
        [navigate, dispatch]
    );

    const handleButtonClick = async () => {
        try {
            setLogInState(logInStates.loggingIn);
            await login(false);
        } catch (e) {
            setLogInState(logInStates.error);
        }
    };

    useEffect(() => {
        if (autoLogin.value) {
            login(true).catch((e) => setLogInState(logInStates.rest));
        } else {
            setLogInState(logInStates.rest);
        }
    }, [autoLogin]);

    if (
        loginState === logInStates.loggingIn ||
        loginState === logInStates.loading
    ) {
        return (
            <div className={classes.spinnerContainer}>
                <Spinner size="extra-large" />
                <Subtitle2>
                    {loginState === logInStates.loggingIn
                        ? t('splashScreen.loggingIn')
                        : t('splashScreen.loading')}
                </Subtitle2>
            </div>
        );
    }

    return (
        <>
            <div>
                <div className={classes.splashContentDiv}>
                    <SplashImage />
                    <div className={classes.splashText}>
                        <Subtitle1>{t('splashScreen.title')}</Subtitle1>
                        <TutorialBody content={t('splashScreen.body')} />
                    </div>
                    <Button appearance="primary" onClick={handleButtonClick}>
                        {t('buttons.signIn')}
                    </Button>
                </div>
            </div>

            <Alert
                isOpen={loginState === logInStates.error}
                onPrimary={closeAlert}
                modalTitle={t('signInFailed.title')}
                modalText={t('signInFailed.body')}
                primaryButtonText={t('buttons.ok')}
            />
        </>
    );
};

export default SplashScreen;
