import { Subtitle1 } from '@fluentui/react-components';
import { sanitize } from 'dompurify';
import TaskPaneBody from 'firstRun/components/TutorialBody';
import TutorialImage from 'firstRun/components/TutorialImage';
import {
    useFooterState,
    useHeaderState,
} from 'headerFooter/hooks/headerFooterStateHook';
import { useSplashStyles } from 'login/hooks/loginStylesHook';
import React, { useMemo } from 'react';
import { useRouteError } from 'react-router-dom';
import customError from '../../../middle-tier/helpers/customError';

/*
If an error is uncaught, this page will render. 
In the front-end, errors should be handled in-situ. If they get missed, this is the page that should show up
with some generic error message, ideally with the ability to navigate back to the previous page, or restart the app
________________________________________________________________________________________________________________

Custom Components:

Name -> Description
________________________________________________________________________________________________________________

IMPORTANT NOTES
    -> Name
        Description
________________________________________________________________________________________________________________
*/

const ErrorElement = () => {
    const error = useRouteError();
    const styles = useSplashStyles();
    useHeaderState([{ type: 'errorPage' }]);
    useFooterState([{ type: 'errorPage' }]);

    const displayText = useMemo(() => {
        let returnText;
        try {
            //Check if it is an uncaught standard error
            if (error instanceof Error || error instanceof customError) {
                returnText = error.message;
            }

            //Check if it is a request error token
            if (
                error instanceof Object &&
                error.hasOwnProperty('status') &&
                error.hasOwnProperty('data') &&
                error.hasOwnProperty('error')
            ) {
                //Get display text based on the request error thrown
                returnText = error?.error?.text || '';
            }

            //If not parsed - return unknown error
            if (!returnText) returnText = 'Unknown Error';
        } catch (e) {
            //on failure to parse - return unknown error
            returnText = 'Unknown Error';
        }

        //Ensure display text from error message is sanitised before displaying
        returnText = sanitize(returnText);

        return returnText;
    }, [error]);

    return (
        <div>
            <div className={styles.splashContentDiv}>
                <TutorialImage />
                <div className={styles.splashText}>
                    <Subtitle1>{'Oops! You found an error'}</Subtitle1>
                    <TaskPaneBody content={displayText} />
                    <TaskPaneBody
                        content={
                            'Try reloading the app and if the error persists you can contact the BrightCarbon developers using the help menu in the bottom right of your screen.'
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default ErrorElement;
